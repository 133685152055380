import React, { Component } from "react";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";

import { compose } from "recompose";

import {useTranslation, withTranslation} from "react-i18next";

import { updateLanguageObject,resetLanguageObject } from "../../../actions/TranslationAction";

class TranslationToggle extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  _handleClick(e) {
    e.preventDefault();
    this.setState({isVisible: !this.state.isVisible });
  };

  _handleLangClick(e,val)
  {
      e.preventDefault();
      this.setState({isVisible: !this.state.isVisible });
      this.props.updateLanguageObject({currentLanguage:val});
  };

  renderDropdown() {
    return (
      React.createElement("ul", { className: "dropdown" },
      React.createElement("li", null, React.createElement("button", { className: "btn", type: "button", onClick: e => this._handleLangClick(e,"en"), }, "English")),
      React.createElement("li", null, React.createElement("button", { className: "btn", type: "button", onClick: e => this._handleLangClick(e,"fr"), }, "French")),
      React.createElement("li", null, React.createElement("button", { className: "btn", type: "button", onClick: e => this._handleLangClick(e,"de"), }, "German")),
      React.createElement("li", null, React.createElement("button", { className: "btn", type: "button", onClick: e => this._handleLangClick(e,"es"), }, "Spanish"))));
   };

  render() {
    return (
      React.createElement("div", { className: "dropdown" },
      React.createElement("button", { className: "btn btn-primary", type: "button", onClick: e => this._handleClick(e) },
      React.createElement("span", { className: "fa fa-language" })),
      this.state.isVisible ? this.renderDropdown() : null));
  }};



  const mapStateToProps = reducerObj => {
    const languageObject = reducerObj.translationReducers.langObject;
    return { languageObject  };
  };

  export default compose(
    connect(mapStateToProps, {
      updateLanguageObject,
      resetLanguageObject
    }),
  )(TranslationToggle);
