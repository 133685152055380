import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import ReactEcharts from "echarts-for-react";

import { updateEChartEventObject } from "../../../../actions/EChartAction/EChartEventAction";

const DonoughtChart = ( props ) => {

  function onChartClick(e)
   {
     if(e.componentType === "title")
     {
       props.updateEChartEventObject({titleClicked:props.chartEventObject.titleClicked === true ? false : true })
     }else if(e.componentType === "series")
     {
       props.updateEChartEventObject({chartClicked:props.chartEventObject.chartClicked === true ? false : true })

     }else if(e.componentType === "series11")
     {

     }else{

     }
   }

   function onChartLegendselectchanged()
    {
    
    }

   let _onEvents = {
   'click': onChartClick,
   'legendselectchanged': onChartLegendselectchanged
  }

  return (
       <React.Fragment>
         <ReactEcharts
           option={{
               title: {
                 text: props.chartTextObject.title,
                 textStyle: {
                    fontFamily:props.chartTextObject.fontFamily,
                    color: props.chartTextObject.titleColor
                  },
                 subtext: props.chartTextObject.subtitle,
                 subtextStyle: {
                    fontFamily:props.chartTextObject.fontFamily,
                    color: props.chartTextObject.subTitleColor
                  },
                 left: 'center',
                 triggerEvent: true
               },
              tooltip : {
                  trigger: 'item',
                  formatter: "{a} <br/>{b} : {c} ({d}%)"
              },
              grid: { containLabel: true },
              legend: {
                  show:props.chartLegendObject.display,
                  orient:props.chartLegendObject.orientation,
                  left:props.chartLegendObject.leftPosition,
                  top:props.chartLegendObject.topPosition,
                  data: props.chartDataObject.chart_labels,
                  textStyle:{
                      fontFamily:props.chartTextObject.fontFamily,
                      color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
                  },
                  triggerEvent: true
              },
              series : [
                  {
                      name: '',
                      type: 'pie',
                      radius: ['50%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                          show: false,
                          position: 'center'
                      },
                      emphasis: {
                          label: {
                              show: true,
                              fontSize: '30',
                              fontWeight: 'bold'
                          }
                      },
                      labelLine: {
                          show: false
                      },
                      data:props.chartDataObject.chart_array,
                  }
              ]
            }}
           style={{height: '400px', width: '100%'}}
           onEvents={_onEvents}
           className='echarts-for-echarts'
           />
      </React.Fragment>
    )
};


const mapStateToProps = reducerObj => {

  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const chartEventObject = reducerObj.eChartEventReducers.eventList;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,chartTextObject,chartLegendObject,chartEventObject};
};

export default compose(
  connect(mapStateToProps, {
    updateEChartEventObject
  }),
)(DonoughtChart);
