import React, { useState }  from "react";
import Popup from "reactjs-popup";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";

import { compose } from "recompose";
import "./modal.css";

function AlertModal(props) {

     let { showAlertPopup, closeAlertPopup ,fileSize} = props;
    
      async function handleSubmit(e)
      {
         e.preventDefault();
         props.onConfirmModalCloseAfterApi(e.target.value);
         closeAlertPopup();
      };


  return (
   <React.Fragment>
    <Popup open={showAlertPopup} onClose={closeAlertPopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Alert</p>
              {/*<button className="deleteBtn" onClick={props.closeAlertPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>*/}
            </header>
            <section className="modal-card-body popup-det-alrt">
              <div className="container">
                  <div className="row col-sm-12">
                      <div className="col-md-12 col-lg-12">
                        <div dangerouslySetInnerHTML={{__html: props.confirmPopupMessage}}>
                       </div>
                      </div>
                  </div>
                  <div className="row">
                    {props.fileSize ==="big"
                      ?   <div className="col-md-12 col-lg-12 Alert-btn">
                              <button type="button"  value ="big" className="btn btn-danger" onClick={(e) => handleSubmit(e)} >Please Select Another File</button>
                        </div>
                      :   <div className="col-md-12 col-lg-12 Alert-btn">
                              <button type="button"  value ="small" className="btn btn-success" onClick={(e) => handleSubmit(e)} >Proceed to Upload</button>
                        </div>
                    }
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



const mapStateToProps = reducerObj => {

  return {  };
};

export default compose(
  connect(mapStateToProps, {

  }),
)(AlertModal);
