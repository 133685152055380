import React, { useState , useEffect}  from "react";

import { connect } from "react-redux";

import { compose } from "recompose";

import { Link } from 'react-router-dom';

import { Auth } from "aws-amplify";

import { SketchPicker } from 'react-color';

import reactCSS from 'reactcss';

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import ToggleMenu from "../common/toggleMenu/toggle-menu";

import axios from 'axios';

import {useTranslation} from "react-i18next";

import { Loader } from "../common/Loader/loader";

import { FormValidator } from "../common/FormValidator/FormValidator";

import { store } from 'react-notifications-component';

import {API_SERVER ,ODOO_API_SERVER }  from '../constant';

import backImg   from "../../assets/images/back.png";

import { updateThemeObject , resetThemeObject } from "../../actions/ThemeAction";
import { updateLanguageObject,resetLanguageObject } from "../../actions/TranslationAction";

import "./profile.css";

const animatedComponents = makeAnimated();

function Profile(props) {

     const {t, i18n} = useTranslation('common');

     let [Loading, setLoading] = useState(false);
     let [expand, setExpanded] = useState(false);
     let [userEmail, setUserEmail] = useState(null);
     let [userOdooID, setUserOdooID] = useState(null);
     let [submit, setSubmit] = useState(false);

     let [countryList, setCountryList] = useState([]);
     let [stateList, setStateList] = useState([]);
     let [cityList, setCityList] = useState([]);

     let [selectedCountryIndex,setSelectedCountryIndex]  = useState("");
     let [selectedStateIndex,setSelectedStateIndex]   = useState("");
     let [selectedCityIndex,setSelectedCityIndex]  = useState("");

     let [displayThemeColorPicker,setDisplayThemeColorPicker] = useState(false);
     let [themeColor,setThemeColor] = useState(props.themeObject.buttonColor);

     const validator = new FormValidator([
       {
         field: 'name',
         method: 'isEmpty',
         validWhen: false,
         message: 'User Name is required.'
       }
     ]);

     let [state, setState] = useState({
         user : {
            userId:'',
            name:'',
            mobile:'',
            website:'',
            phone:'',
            phone:'',
            zip:'',
            address:'',
            country_id:'',
            country:'',
            state_id:'',
            state:'',
            city:'',
            street:'',
            vat:'',
            is_company:false,
            x_themecolor:'',
            x_userlanguage:'',
            validation: validator.valid()
          }
       }
     );

    function onCountryChange(val)
    {
        setSelectedCountryIndex(selectedCountryIndex=val);
        setStateList(stateList=[]);
        setSelectedStateIndex(selectedStateIndex=null);
        if(val != null){
           setLoading(true);
           getStateList(val.id);
           const { user } = state;
              setState({
                user: {
                  ...user,
                  country_id:val.id
              }
            })
        }
    };

    function onStateChange(val)
    {
      setSelectedStateIndex(selectedStateIndex=val);
      if(val != null){
        const { user } = state;
           setState({
             user: {
               ...user,
               state_id:val.id
           }
         })
      }
    };

    function onCityChange(val)
    {
      setSelectedCityIndex(selectedCityIndex=val);
    };

    function resetForm()
    {
      const { user } = state;
          setState({
            user: {
              ...user,
              name:'',
              mobile:'',
              website:'',
              validation: validator.valid()
          }
        })
       setSubmit(submit=false);
    };

    function handleChange(e)
      {
         e.preventDefault();
          const { name, value } = e.target;
          const { user } = state;
              setState({
                user: {
                  ...user,
                  [name]: value
              }
            })
      };

      function handleCheckbox(val)
        {
            const { user } = state;
               setState({
                 user: {
                   ...user,
                   is_company:val
               }
             })
        };

       let validation = validator.validate(state.user);

      async function handleSubmit(e)
      {
        e.preventDefault();
        validation = validator.validate(state.user);

        setSubmit(submit=true);

        if(validation.isValid)
        {
          setLoading(true);
           updateOdooProfile();
        }
      };

       const styles = reactCSS({
          'default': {
            color: {
              width: '21px',
              height: '21px',
              borderRadius: '36px'
            },
            swatch: {
              padding: '0px',
              background: 'rgb(255, 255, 255)',
              borderRadius: '12px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
              display: 'inline-block',
              cursor: 'pointer',
            },
            popover: {
              position: 'absolute',
              zIndex: '2',
            },
            cover: {
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
        });

        function handleColorPickerStyle(type)
        {
          if(type === "theme")
           {
             return reactCSS({
               'default': {
                 color: {
                   width: '21px',
                   height: '21px',
                   borderRadius: '36px',
                   background: props.themeObject.buttonColor,
                 }
               },
             });
           }else{
             return reactCSS({
               'default': {
                 color: {
                   width: '21px',
                   height: '21px',
                   borderRadius: '36px',
                   background: props.themeObject.buttonColor,
                 }
               },
             });
           }
        };

        function handleColorClick (e)
         {
           setDisplayThemeColorPicker( displayThemeColorPicker = !displayThemeColorPicker );
         };

         function handleColorClose(e)
         {
            setDisplayThemeColorPicker(displayThemeColorPicker = false)
         };

         function handleColorChange(color)
         {
           setThemeColor(themeColor = color.hex);
           props.updateThemeObject({buttonColor:color.hex})
         };

        function toggleSidebar()
        {

        };

        async function handleUpdate(e)
        {
          e.preventDefault();

        };

        async function updateOdooProfile()
        {
          axios.post(ODOO_API_SERVER+`update`,
             {
                "config" : "prestocharts-inc",
                "module" : "user",
                 "id" : state.user.userId,
                 "input": {"display_name":state.user.display_name,"email":state.user.email,
                           "website":state.user.website,"vat":state.user.vat,
                           "phone":state.user.phone,"zip":state.user.zip ,
                           "mobile":state.user.mobile,"street":state.user.street,
                           "is_company":state.user.is_company,"city":state.user.city,
                           "country_id":state.user.country_id,"state_id":state.user.state_id,
                           "x_themecolor" : props.themeObject.buttonColor ,"x_userlanguage":props.languageObject.currentLanguage
                         }
                }
             )
            .then(function (response) {
              if(response.status === 200)
              {
                store.addNotification({
                  title: "Done!",
                  message: "Odoo Profile Updated Successfully",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "zoomIn"],
                  animationOut: ["animated", "zoomOut"],
                  dismiss: {
                      duration: 3000,
                      onScreen: true ,
                      showIcon :true
                  }
                 });
              }
            })
            .catch(function (error) {

            })
            .then(function () {
              setLoading(false);
            });
        };

        async function getCountryList()
        {
          axios.post(ODOO_API_SERVER+`getlist`,
             {
               "config" : "prestocharts-inc",
                "module" : "Country",
                "field" : "id",
                "operator" : ">",
                "value" : 0 ,
                "input": {"fields": ["name","id"] }
                }
             )
            .then(function (response) {
              if(response.status === 200)
              {
                setCountryList(countryList= response.data.customer_data);
              }
            })
            .catch(function (error) {

            })
            .then(function () {
              setLoading(false);
            });
        };

        async function getStateList(countryID)
        {
          axios.post(ODOO_API_SERVER+`getlist`,
             {
               "config" : "prestocharts-inc",
                "module" : "State",
                "field" : "country_id",
                "operator" : "=",
                "value" :  countryID,
                "input": {"fields": ["name","id"] }
                }
             )
            .then(function (response) {
              if(response.status === 200)
              {
                setStateList(stateList= response.data.customer_data);
              }
              else
              {

              }
            })
            .catch(function (error) {

            })
            .then(function () {
              setLoading(false);
            });
        };

        async function getCityList(stateID)
        {
          axios.post(ODOO_API_SERVER+`getlist`,
            {
               "config" : "prestocharts-inc",
                "module" : "city",
                "field" : "id",
                "operator" : "=",
                "value" : stateID ,
                "input": {"fields": ["name","id"] }
                }
             )
            .then(function (response) {
              if(response.status === 200)
              {
                console.log(response.data.customer_data)
              }
              else
              {

              }
            })
            .catch(function (error) {

            })
            .then(function () {
              setLoading(false);
            });
        };

        async function getUserOdooProfileData()
        {
          axios.post(ODOO_API_SERVER+`getlist`,
              {
                "config" : "prestocharts-inc",
                "module" : "user",
                "field" : "email",
                "operator" : "=",
                "value" : userEmail ,
                "input": {"fields": ["name","display_name","email","website","phone",
                                     "mobile","display_name","zip","country_id","state_id",
                                     "city","city_id","contact_address_complete","self","state_id",
                                     "street","street2","vat","is_company","x_themecolor","x_userlanguage"
                                   ]
                         }
              }
             )
            .then(function (response) {
              if(response.status === 200)
              {
                let userData = response.data.customer_data[0];
                console.log(userData)
                const { user } = state;
                let stateVal = false;
                let countryVal = false;

                if(typeof userData.state_id != "boolean"){
                     stateVal = userData.state_id[0];
                     setSelectedStateIndex(selectedStateIndex={"id": userData.state_id[0],"name": userData.state_id[1]});
                }if(typeof userData.country_id != "boolean"){
                     countryVal = userData.country_id[0];
                      getStateList(countryVal);
                      setSelectedCountryIndex(selectedCountryIndex={"id": userData.country_id[0],"name": userData.country_id[1]});
                 }

                 if(typeof userData.x_themecolor != "boolean"){
                    props.updateThemeObject({buttonColor:userData.x_themecolor});
                 }if(typeof userData.x_userlanguage != "boolean"){
                   props.updateLanguageObject({currentLanguage:userData.x_userlanguage});
                 }

                  setState({
                    user: {
                      ...user,
                      userId:userData.id ? userData.id:"",
                      name: userData.display_name ? userData.display_name:"",
                      country:userData.city ? countryVal:"",
                      country_id:countryVal,
                      state:userData.state ? stateVal:"",
                      state_id: stateVal,
                      city: userData.city ? userData.city:"",
                      mobile:userData.mobile ? userData.mobile:"",
                      phone:userData.phone ? userData.phone:"",
                      zip:userData.zip ? userData.zip:"",
                      website:userData.website ? userData.website:"",
                      street:userData.street ? userData.street:"",
                      vat:userData.vat ? userData.vat:"",
                      is_company:userData.is_company,
                      x_themecolor:userData.x_themecolor ? userData.x_themecolor:"",
                      x_userlanguage:userData.x_userlanguage ? userData.x_userlanguage:"",
                  }
                })
              }
              else
              {

              }
            })
            .catch(function (error) {

            })
            .then(function () {


            });
        };

        async function getUserDetails()
        {
          const user =  await Auth.currentUserInfo();
          if(user != null){
            setUserEmail(userEmail= user.attributes.email);
            getCountryList();
            getUserOdooProfileData();
          }else{
            setLoading(false);
          }
        };

        useEffect(() => {
          setLoading(true);
          getUserDetails();
        },[]);

    return(
        <React.Fragment>
                <div className="page-content inset-profile">
                  <div className="themeCodePick">
                    <h3>{t('Input.ThemeColorPicker')}</h3>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e)}}>
                            <div style={ handleColorPickerStyle().color  } />
                          </div>
                          { displayThemeColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e)}} />
                            <SketchPicker color={ props.themeObject.buttonColor } onChange={ (e) => { handleColorChange(e)}} />
                          </div> : null }
                    </div>
                  </div>
                  <div className="userProfileForm">
                    <form id="Login">
                      <div className="row">
                          <div className="oneInp2 oneInp2checkbox col-6">
                             {t('Input.company')}
                              <input
                                type="checkbox"
                                name="check"
                                checked={state.user.is_company}
                                onChange={(e) => {
                                    handleCheckbox(e.target.checked);
                                  }}
                              />
                          </div>
                          <div className="oneInp2 col-6">
                            <label className="custom-field two inp-phone">
                              <input type="text" name="phone" value={state.user.phone} onChange={handleChange} placeholder="&nbsp;"/>
                              <span className="placeholder">{t('Input.phone')}</span>
                            </label>
                          </div>
                        </div>
                       <div className="row">
                          <div className="col-md-6 col-lg-6">
                              <div className="oneInp1">
                               <label className="custom-field two inp1">
                               <input type="text" name="name" value={state.user.name} onChange={handleChange} placeholder="&nbsp;"/>
                               <span className="placeholder">{t('Input.name')}</span>
                               </label>
                             </div>
                             <div className="oneInp1">
                               <label className="custom-field two inp2">
                                 <input type="text" name="street" value={state.user.street} onChange={handleChange} placeholder="&nbsp;"/>
                                 <span className="placeholder">{t('Input.street')}</span>
                             </label>
                             </div>
                             <div className="row">
                             <div className="oneInp2 col-6">
                               <div className="custom-field two inp3">
                                 <Select
                                   name="FILE"
                                   closeMenuOnSelect={true}
                                   components={animatedComponents}
                                   options={countryList}
                                   onChange={onCountryChange}
                                   value={selectedCountryIndex}
                                   isClearable={false}
                                   placeholder={t('Input.country')}
                                   getOptionLabel={(countryList) => countryList.name}
                                   getOptionValue={(countryList) => countryList.id}
                                   isMulti={false}
                                 />
                             </div>
                             </div>
                             <div className="oneInp2 col-6">
                               <div className="custom-field two inp-count">
                                 <Select
                                   name="FILE"
                                   closeMenuOnSelect={true}
                                   components={animatedComponents}
                                   options={stateList}
                                   onChange={onStateChange}
                                   value={selectedStateIndex}
                                   isClearable={false}
                                   placeholder={t('Input.state')}
                                   isMulti={false}
                                   getOptionLabel={(stateList) => stateList.name}
                                   getOptionValue={(stateList) => stateList.id}
                                   isDisabled={stateList.length === 0 ? true :false}
                                 />
                             </div>
                             </div>
                           </div>
                           <div className="row">
                           <div className="oneInp2 col-6">
                             <label className="custom-field two inp1">
                               <input type="text" name="city" value={state.user.city} onChange={handleChange} placeholder="&nbsp;"/>
                             <span className="placeholder">{t('Input.city')}</span>
                           </label>
                           </div>
                           <div className="oneInp2 col-6">
                             <label className="custom-field two inp-count">
                               <input type="text" name="zip" value={state.user.zip} onChange={handleChange} placeholder="&nbsp;"/>
                               <span className="placeholder">{t('Input.zip')}</span>
                            </label>
                           </div>
                          </div>
                          </div>
                          <div className="col-md-6 col-lg-6">
                             <div className="oneInp1 mobilepanel">
                               <label className="custom-field two inp-mobile">
                               <input type="text" name="mobile" value={state.user.mobile} onChange={handleChange} placeholder="&nbsp;"/>
                               <span className="placeholder">{t('Input.mobile')}</span>
                               </label>
                             </div>
                             <div className="oneInp1 emailpanel">
                               <label className="custom-field two inp-email">
                               <input type="email" name="user_email" disabled={true} value={userEmail} placeholder="&nbsp;"/>
                               <span className="placeholder">{t('Input.email')}</span>
                               </label>
                             </div>
                             <div className="oneInp1 emailpanel">
                               <label className="custom-field two inp-wl">
                               <input type="text" name="website" value={state.user.website} onChange={handleChange} placeholder="&nbsp;"/>
                               <span className="placeholder">{t('Input.websiteLink')}</span>
                               </label>
                             </div>
                             <div className="oneInp1 phonePanel">
                                 <label className="custom-field two inp-phone">
                                 <input type="text" name="vat" value={state.user.vat} onChange={handleChange} placeholder="&nbsp;"/>
                                 <span className="placeholder">Vat</span>
                                 </label>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 save-btn-row update-pro">
                            <button type="button" style={{backgroundColor:props.themeObject.buttonColor}} className="btn yellow-btn" onClick={handleSubmit}>Update</button>
                          </div>
                       </div>
                  </form>
                </div>
             {Loading  &&
                <Loader />
             }
          </div>
         </React.Fragment>
       )
};


const mapStateToProps = reducerObj => {
  const themeObject = reducerObj.themeReducers.themeObject;
    const languageObject = reducerObj.translationReducers.langObject;
  return { themeObject ,languageObject };
};

export default compose(
  connect(mapStateToProps, {
   updateThemeObject , resetThemeObject,
   updateLanguageObject,resetLanguageObject
  }),
)(Profile);
