import React, { useState ,useEffect }  from "react";

import Popup from "reactjs-popup";

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { Loader } from "../common/Loader/loader";

import { store } from 'react-notifications-component';


import {API_SERVER }  from '../constant';

import "./modal.css";

import DownloadModal from "./DownloadModal";



const animatedComponents = makeAnimated();

const SortParam = [{'label':"ASC",'value':"asc"},{'label':"DESC",'value':"desc"}];


function EChartModal(props) {
      const { t, i18n } = useTranslation('common');
     let { showReportPopup, closeReportPopup , reportPopUpData ,userEmail } = props;
     let [Loading, setLoading] = useState(false);

     let [fileIndexList, setFileIndexList] = useState([]);
     let [reportIndexList, setReportIndexList] = useState([]);

     let [selectedFileIndexItem,setSelectedFileIndexItem]  = useState(null);
     let [selectedReportIndexItem,setSelectedReportIndexItem]   = useState(null);

     let [selectedSortParam ,setSelectedSortParam] = useState({'label':"ASC",'value':"asc"});
     let [selectedJsonSortParam ,setSelectedJsonSortParam] = useState({'label':"ASC",'value':"asc"});

    let [showDownloadPopup, setShowDownloadPopup] = useState(false);
    let [zipLink, setZipLink] = useState(null);
    let [fileLink, setFileLink] = useState(null);

      const openDownloadPopup = () => {
        setShowDownloadPopup(showDownloadPopup=true);
      };

      const closeDownloadPopup = () => {
        setShowDownloadPopup(showDownloadPopup=false);
      };

     function onFileIndexChange(val)
     {
        setSelectedFileIndexItem(selectedFileIndexItem=val)
         if(selectedFileIndexItem)
         {
           setReportIndexList(reportIndexList=[]);
           setSelectedReportIndexItem(selectedReportIndexItem=null);

           let subFolderName  =  val.Key;
           let lastIndex = subFolderName.lastIndexOf('/');
           let totalLength = subFolderName.length;
           let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
           let directoryName = subFolderName_modified;
           //let sortOrder = selectedSortParam.value;
           setSelectedJsonSortParam(selectedJsonSortParam={'label':"ASC",'value':"asc"})
           getUploadedFileList(subFolderName,'asc');
         }else{
           setReportIndexList(reportIndexList=[]);
           setSelectedReportIndexItem(selectedReportIndexItem=null);
         }
     };

     function downloadFile()
     {
     	if(selectedReportIndexItem != null)
     	 {
          readSelectedFile(selectedFileIndexItem,selectedReportIndexItem)
     	 }
     }

    function sourceFile()
    {
    	if(selectedFileIndexItem != null)
    	 {
        //console.log("selectedFileIndexItem.zip_link",selectedFileIndexItem.zip_link)
          setZipLink(zipLink=selectedFileIndexItem.zip_link);
          setFileLink(fileLink=selectedFileIndexItem.file_link);
          setShowDownloadPopup(showDownloadPopup=true);
    	 }
    }

     function onReportIndexChange(val)
     {
       setSelectedReportIndexItem(selectedReportIndexItem=val);
       props.onReportModalCloseAfterApi(selectedFileIndexItem,selectedReportIndexItem);
        closeReportPopup();
     };

     function onSortParamChange(val)
     {
       setSelectedSortParam(selectedSortParam=val)
       let directoryName = '';
       let sortOrder = selectedSortParam.value;

       getUploadedFileList(directoryName,sortOrder)
     };

     function onJsonSortParamChange(val)
     {
       setSelectedJsonSortParam(selectedJsonSortParam=val)

       if(selectedFileIndexItem != null)
        {
          let subFolderName  =  selectedFileIndexItem.Key;
          let lastIndex = subFolderName.lastIndexOf('/');
          let totalLength = subFolderName.length;
          let subFolderName_modified = subFolderName.substr(lastIndex+1,totalLength);
          let directoryName = subFolderName_modified;
          let sortOrder = selectedJsonSortParam.value;

          getUploadedFileList(subFolderName,sortOrder)
        }
     };

     async function getUploadedFileList(directoryName,sortOrder)
     {
       setLoading(true);

       axios.post(API_SERVER +`s3-zip-file-read`,{'userEmail' :userEmail ,'directoryName':directoryName,'sortOrder':sortOrder})
         .then(function (response) {
           if(response.status === 200)
           {
              if(directoryName === ""){
                setFileIndexList(fileIndexList=[])
                setReportIndexList(reportIndexList=[])
                setSelectedFileIndexItem(selectedFileIndexItem=null)
                setSelectedReportIndexItem(selectedReportIndexItem=null)
                setFileIndexList(fileIndexList=response.data.data)
              }else{
                setReportIndexList(reportIndexList=[])
                setReportIndexList(reportIndexList=response.data.data)
                console.log("reportIndexList",reportIndexList);
                setSelectedReportIndexItem(selectedReportIndexItem=null)
              }
           }
           else
           {
             store.addNotification({
               title: "Error",
               message: "Error",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
           }
         })
         .catch(function (error) {
           store.addNotification({
             title: "Error",
             message: "Error",
             type: "danger",
             insert: "top",
             container: "top-right",
             animationIn: ["animated", "zoomIn"],
             animationOut: ["animated", "zoomOut"],
             dismiss: {
               duration: 3000,
               onScreen: true ,
               showIcon :true
             }
            });
         })
         .then(function () {
           setLoading(false);
         });
     };

    async function readSelectedFile(directoryDetails,fileDetails)
     {
      console.log("inside readSelectedFile")
        setLoading(true);
       axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmail ,'directoryName':directoryDetails.Key ,"fileName":fileDetails.value})
         .then(function (response) {
           if(response.status === 200)
           {
             const fileData = JSON.stringify(response.data);
             console.log("fileData",fileData)
              const blob = new Blob([fileData], {type: "text/plain"});
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.download = selectedReportIndexItem.label +".json";
              link.href = url;
              link.click();
           }
           else
           {

           }
         })
         .catch(function (error) {

         })
         .then(function () {
           setLoading(false);
         });
     };

     useEffect(() => {
        if(props.showReportPopup){
          console.log('opnned')
        }else{
          console.log('closed')
        }
     },[props.showReportPopup]);

  return (
   <React.Fragment>
    <Popup open={showReportPopup} onClose={closeReportPopup} nested>
      <DownloadModal
            showDownloadPopup={showDownloadPopup}
            closeDownloadPopup={closeDownloadPopup}
            zipLink={zipLink}
            fileLink={fileLink}
          />
       <div className="modal is-active custom-model-popup">
           <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{t('Modal.SelectSourceFileandRepo')}</p>
                <button className="deleteBtn" onClick={props.closeReportPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
               <div className="container">
                 <div className="upl-file-row">
                      <div className="row col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="col-md-6 col-lg-6 pl-0">
                            <div className="styled-select">
                                <Select
                                  name="FILE"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  options={fileIndexList.length > 0 ? fileIndexList:props.reportPopUpData}
                                  onChange={onFileIndexChange}
                                  value={selectedFileIndexItem}
                                  isClearable={false}
                                  placeholder={"Source File"}
                                  isMulti={false}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: '#CADBFF',
                                      primary: '#CADBFF',
                                    },
                                  })}
                                />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5">
                                <Select
                                  name="FILE"
                                  closeMenuOnSelect={true}
                                  options={SortParam}
                                  onChange={onSortParamChange}
                                  value={selectedSortParam}
                                  isClearable={false}
                                  placeholder={"Sort"}
                                  isMulti={false}
                                  theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#CADBFF',
                                        primary: '#CADBFF',
                                      },
                                    })}
                                />
                          </div>
                          <div className="col-md-1 col-lg-1 text-allign-r">
                                <button type="button" className="btn download-btn" onClick={sourceFile}>
                                  <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                                </button>
                          </div>
                      </div>
                     <div className="row col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="col-md-6 col-lg-6 pl-0">
                            <div className="styled-select">
                              <Select
                                name="FILE"
                                closeMenuOnSelect={true}
                                options={reportIndexList}
                                onChange={onReportIndexChange}
                                value={selectedReportIndexItem}
                                isClearable={false}
                                placeholder={"Report"}
                                isMulti={false}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#CADBFF',
                                    primary: '#CADBFF',
                                  },
                                })}
                              />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5">
                          <Select
                                name="FILE"
                                closeMenuOnSelect={true}
                                options={SortParam}
                                onChange={onJsonSortParamChange}
                                value={selectedJsonSortParam}
                                isClearable={false}
                                placeholder={"Sort"}
                                isMulti={false}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#CADBFF',
                                    primary: '#CADBFF',
                                  },
                                })}
                              />
                        </div>
                        <div className="col-md-1 col-lg-1">
                              <button type="button" className="btn download-btn" onClick={downloadFile}>
                                <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                              </button>
                        </div>
                     </div>
                  </div>
                </div>
            </section>
               </div>
      </div>
      {Loading  &&
       <Loader />
    }
    </Popup>
  </React.Fragment>
  );
}



export default EChartModal
