import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

import { configureStore } from './store/Store';
import { Provider } from 'react-redux';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_de from './translations/de/common.json';
import common_fr from './translations/fr/common.json';
import common_es from './translations/es/common.json';
import common_en from './translations/en/common.json';
import axios from 'axios';

axios.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  config.headers['Authorization'] = `Bearer ${session
    .getAccessToken()
    .getJwtToken()}`;
  return config;
});

const { store } = configureStore();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
    fr: {
      common: common_fr,
    },
    es: {
      common: common_es,
    },
  },
});

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
