export const UPDATE_ECHART_SETTINGS = "update-echart-settings";
export const RESET_ECHART_SETTINGS = "reset-echart-settings";

export const updateEChartSettingObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEChartSettingObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ECHART_SETTINGS,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ECHART_SETTINGS
  };
};
