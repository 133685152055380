import React, { useState }  from "react";
import Popup from "reactjs-popup";
import "./modal.css";


function ConfirmationAlertModal(props) {

  let { showConfirmPopup, closeConfirmPopup } = props;

  function handleConfirm(e)
   {
      e.preventDefault();
      props.onConfirmModalCloseAfterApi(e.target.value);
      closeConfirmPopup();
   };

  return (
   <React.Fragment>
    <Popup open={showConfirmPopup} onClose={closeConfirmPopup}>
       <div className="modal is-active helpmodal">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmation</p>
              <button className="deleteBtn" onClick={props.closeConfirmPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body popup-det-alrt">
              <div className="container">
               <div className="row col-sm-12">
                      <div className="col-md-12 col-lg-12">
                            <h5>{props.confirmPopupMessage}</h5>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 Alert-btn">
                          <button type="button"  value ="true" className="btn btn-danger" onClick={(e) => handleConfirm(e)} >Yes</button>
                          <button type="button"  value ="false" className="btn btn-secondary" onClick={(e) => handleConfirm(e)} >No</button>
                    </div>
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



export default ConfirmationAlertModal
