import React, { useState,useEffect  }  from "react";
import Popup from "reactjs-popup";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";
import {useTranslation} from "react-i18next";
import { compose } from "recompose";
import "./modal.css";
import { wrap } from "lodash";
import AWS from 'aws-sdk';

export default function ErrorMessageModal(props) {
  const {t, i18n} = useTranslation('common');
     let { showErrorMessagedPopup, closeErrorMessagePopup,selectedErrorFile,selectedErrorLabel,fileLink  } = props;
     console.log("selectedErrorFile",selectedErrorFile)
     const [active , setActive] = useState(false)
     
    // const customString = selectedErrorFile.toString().replaceAll('Row', "&nbsp;")
    
     async function handleSubmitOK(e)
      {
         e.preventDefault();

        closeErrorMessagePopup();
      };


      async function handleSubmitWrap(e)
      {
         e.preventDefault();
         const currentState = active
         setActive(!currentState);
       //  setTextWidth("1900px")
      };
      async function handleSubmit(e)
      {
         e.preventDefault();
        //  if(downloadType === "ZIP"){
        //      window.open(zipLink, "_blank")
        //  }else{
        //      window.open(fileLink, "_blank")
        //  }
        window.open(fileLink, "_blank")
      //  closeDownloadPopup();
      };
      // const errorFileContent = selectedErrorFile.map((item) => {
      //   return(
      //   <div className="video-column">{item}</div>
      //   )
      //   })

    //  const errorFileContent =  selectedErrorFile.split('\n').map(str => <p>{str}</p>);
    
    const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
    const [url, setUrl] = useState('');
    // const s3 = new AWS.S3({
    //   accessKeyId:  process.env.REACT_APP_AWS_ID,
    //   secretAccessKey:  process.env.REACT_APP_AWS_SECRET,
    //   region:  process.env.REACT_APP_AWS_REGION
    // });
    
    const downloadFromS3 = (bucketName, fileName) =>{
      console.log("bucket ",bucketName.BUCKET_NAME,fileName,fileLink)
      // Set your AWS credentials and region
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET,
        region: process.env.REACT_APP_AWS_REGION
      });
  
      // Set the bucket name and file name
    //  const bucketName = 'YOUR_BUCKET_NAME';
    //  const fileName = 'YOUR_FILE_NAME';
  
      // Create an S3 instance
      const s3 = new AWS.S3();
  
      // Set the download URL
      const downloadUrl = s3.getSignedUrl('getObject', {
        Bucket: bucketName,
        Key: fileName
      });
  
      // Create a link element and initiate the file download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  
    // const downloadFromS3 = (bucketName, fileKey) => {
    //   const params = { Bucket: bucketName, Key: fileKey };
    //   s3.getObject(params, (err, data) => {
    //     if (err) {
    //       console.log(err);
    //       return;
    //     }
    //     console.log("Download link",URL.createObjectURL(data.Body));
    //     setUrl(URL.createObjectURL(data.Body));
    //   });
    // }

  return (
   <React.Fragment>
    <Popup open={showErrorMessagedPopup} onClose={closeErrorMessagePopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Error: {selectedErrorLabel}</p>
              <button className="deleteBtn" onClick={props.closeErrorMessagePopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
              <div className="container">
                  <div className="row col-sm-12">
                      <div className="col-md-12 col-lg-12">
                        <h4><text id="error-content">{selectedErrorFile.toString().split('\n').map(str => <p style={{width : active ? "1900px": "100%",wordWrap:"break-word"}}>{str}</p>)}</text></h4>
                      </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-12 col-lg-12 Alert-btn">
                          <button type="button"  value ="true" className="btn btn-danger" onClick={(e) => handleSubmit(e)} >OK</button>
                          <button type="button"  value ="true" className="btn" onClick={(e) => handleSubmit(e)} >Wrap/Un-Wrap Text</button>
                          <button type="button"  value ="true" className="btn" onClick={(e) => handleSubmit(e)} >Download</button>
                    </div> */}
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
                
                    <button type="button"  value ="true" className="btn yellow-btn" onClick={(e) => handleSubmitOK(e)} >OK</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button"  value ="true" className="btn yellow-btn" onClick={(e) => handleSubmitWrap(e)} >Wrap/Un-Wrap Text</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button"  className="btn yellow-btn" onClick={(e) => downloadFromS3({BUCKET_NAME}, 'ankit.prestocharts@gmail.com/kpis-complete/ERROR_LMDERR_001-14csvoutput-20230127-047624.zip')}>  {t('Button.download')}</button>
                    {/* <button onClick={() => downloadFromS3({BUCKET_NAME}, {fileLink})}>Download File</button> */}
                    <div>Downloading file...</div>
      {url && <a href={url} download>Download Link</a>}
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}

