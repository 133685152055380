import { UPDATE_TOOLTIP , RESET_TOOLTIP } from "../actions/ToolTipAction";

export const TOOLTIP_INITIAL_STATE = {
  toolTipObject: {
    toolTip:false,
  }
};

export default (state = TOOLTIP_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TOOLTIP:
      return {
        ...state,
        toolTipObject: Object.assign({}, state.toolTipObject, action.payload)
      };
      case RESET_TOOLTIP:
        return TOOLTIP_INITIAL_STATE
    default:
      return state;
  }
};
