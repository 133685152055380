import { UPDATE_REPORT , RESET_REPORT } from "../actions/ReportAction";

export const REPORT_INITIAL_STATE = {
  reportObject: {
    label:'',
    value:'',
    key :''
  }
};

export default (state = REPORT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REPORT:
      return {
        ...state,
        reportObject: Object.assign({}, state.reportObject, action.payload)
      };
      case RESET_REPORT:
        return REPORT_INITIAL_STATE
    default:
      return state;
  }
};
