import { UPDATE_ECHART_TYPE , RESET_ECHART_TYPE} from "../../actions/EChartAction/EChartTypeAction";

export const ECHART_INITIAL_STATE = {
  chartType: {
    chart_type : 'Bar'
  }
};

export default (state = ECHART_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_TYPE:
      return {
        ...state,
        chartType: Object.assign({}, state.chartType, action.payload)
      };
      case RESET_ECHART_TYPE:
        return ECHART_INITIAL_STATE
    default:
      return state;
  }
};
