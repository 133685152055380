import React, { useState , useEffect , useRef }  from "react";

import { connect } from "react-redux";

import { compose } from "recompose";

import { Link } from 'react-router-dom';

import { Loader } from "../common/Loader/loader";

import { FormValidator } from "../common/FormValidator/FormValidator";

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import StripeCheckout from 'react-stripe-checkout';

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

import { store } from 'react-notifications-component';

import axios from 'axios';

import {API_SERVER ,ODOO_API_SERVER }  from '../constant';

import Paypal from './Paypal';

const animatedComponents = makeAnimated();

function Payment(props) {

  let [Loading, setLoading] = useState(false);
  let [expand, setExpanded] = useState(false);
  let [userEmail, setUserEmail] = useState(null);
  let [userOdooID, setUserOdooID] = useState(null);
  let [submit, setSubmit] = useState(false);

  let [currencyList, setCurrencyList] = useState([{'label':'USD','value':'USD'},{'label':'EUR','value':'EUR'},{'label':'CAD','value':'CAD'}]);
  let [paymentMethodList, setPaymentMethodList] = useState([{'label':'PAYPAL','value':'PAYPAL'},{'label':'STRIPE','value':'STRIPE'}]);

  let [selectedCurrencyIndex,setSelectedCurrencyIndex]  = useState("");
  let [selectedPaymentMethodIndex,setPaymentMethodIndex]   = useState("");

  const validator = new FormValidator([
    {
      field: 'amount',
      method: 'isEmpty',
      validWhen: false,
      message: 'Amount is required.'
    },
    {
      field: 'amount',
      method: 'isNumeric',
      validWhen: true,
      message: 'Only Number Allowed.'
    },
    {
      field: 'currency',
      method: 'isEmpty',
      validWhen: false,
      message: 'Currency is required.'
    },
    {
      field: 'paymentGateway',
      method: 'isEmpty',
      validWhen: false,
      message: 'PaymentGateway is required.'
    }
  ]);


  let [state, setState] = useState({
      user : {
         currency:'',
         amount:'',
         paymentGateway:'',
         validation: validator.valid()
       }
    }
  );

  function onCurrencyChange(val)
  {
    setSelectedCurrencyIndex(selectedCurrencyIndex=val);
      if(val != null){
        const { user } = state;
            setState({
              user: {
                ...user,
                currency: val.value
            }
          })
     }
  };

  function onPaymentMethodChange(val)
  {
    setPaymentMethodIndex(selectedPaymentMethodIndex=val);
      if(val != null){
        const { user } = state;
            setState({
              user: {
                ...user,
                paymentGateway: val.value
            }
          })
     }
  };

  function handleChange(e)
    {
       e.preventDefault();
        const { name, value } = e.target;
        const { user } = state;
            setState({
              user: {
                ...user,
                [name]: value
            }
          })
    };

    let validation = validator.validate(state.user);

    async function handleSubmit(e)
    {
      e.preventDefault();
      validation = validator.validate(state.user);
     console.log(validation)
      setSubmit(submit=true);

      if(validation.isValid)
      {
        setLoading(true);
        //updateOdooProfile();
      }
    };

    function createOrder(data, actions) {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                value: "2.00",
              },
            },
          ],
        });

    };

    function onApprove(data, actions) {
      console.log(data);
      return actions.order.capture();
   };

    function stripePaymentToken(token)
    {
       setLoading(true);
       doStripePayment(token.id,token.email);
    };

  function doStripePayment(token,email)
    {
      axios.post(API_SERVER+`do-stripe-payment`,
         {
           "token" : token,
           "email" : email,
           "amount" :this.state.paymentAmount
         }
         )
        .then(function (response) {
          if(response.status === 200)
          {
            store.addNotification({
              title: "Done!",
              message: "Payment Successfully",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
              }
             });
            this.hideLoader();
          }
        })
        .catch(function (error) {
            this.hideLoader();
        })
        .then(function () {

        });
    };

    return(
        <React.Fragment>
            <div className="page-content inset-profile">
                <div className="userProfileForm">
                  <form id="Login">
                     <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="oneInp1">
                             <label className="custom-field two inp1">
                             <input type="text" name="amount" value={state.user.amount} onChange={handleChange} placeholder="&nbsp;"/>
                             <span className="placeholder">Amount</span>
                             </label>
                             <p className="help is-danger">{submit && validation.amount.message}</p>
                           </div>
                           <div className="oneInp1">
                               <div className="custom-field two inp3">
                                 <Select
                                   name="currency"
                                   closeMenuOnSelect={true}
                                   components={animatedComponents}
                                   options={currencyList}
                                   onChange={onCurrencyChange}
                                   value={selectedCurrencyIndex}
                                   isClearable={false}
                                   placeholder={"Select Currency"}
                                   isMulti={false}
                                 />
                             </div>
                              <p className="help is-danger">{submit && validation.currency.message}</p>
                           </div>
                           <div className="oneInp1">
                             <div className="custom-field two inp3">
                               <Select
                                 name="paymentGateway"
                                 closeMenuOnSelect={true}
                                 components={animatedComponents}
                                 options={paymentMethodList}
                                 onChange={onPaymentMethodChange}
                                 value={selectedPaymentMethodIndex}
                                 placeholder={"Select PaymentGateway"}
                                 isClearable={false}
                                 isMulti={false}
                               />
                            </div>
                            <p className="help is-danger">{submit && validation.paymentGateway.message}</p>
                          </div>
                          <div className="col-lg-6 save-btn-row update-pro">
                             <button type="button" className="btn yellow-btn" onClick={handleSubmit}>Proceed</button>
                          </div>
                        </div>
                     </div>
                </form>
                <div className="col-md-12 col-lg-12">
                  <div className="oneInp1 phonePanel">
                    {
                      state.user.paymentGateway === "STRIPE"
                        ?
                         <StripeCheckout
                            stripeKey="pk_test_51JKmaYLowRGCy2ri6SnMLWxG9sj9NC0BYi0yc3gdChPhomzfbrtb1Qm1sxYGAaWXYU9b8YrBoGkM6k60KXKEuBIE00ewHiuDDy"
                            allowRememberMe={false}
                            amount={state.user.amount * 100}
                            token={stripePaymentToken}
                            name="Presocharts"
                            ComponentClass="div"
                            currency={state.user.currency}
                            email="admin@prestocharts.com"
                            panelLabel="Pay  {{amount}}"
                          />
                      : null
                     }
                    {
                     state.user.paymentGateway ==="PAYPAL"
                      ? <div id="pagamento_wrapper">
                          <PayPalScriptProvider
                            options={{
                              "client-id":"ATo_lFql0IXQZi3EQrOu_CH71JX-6wx3Gqr7mRNjPCvCk8ECXiz-KxHIl0POZ7TJGi0cJjuo0QgbYErn"
                               ,
                              "currency" :"EUR",
                              "debug":"true"
                            }}
                          >
                            <PayPalButtons
                              style={{ color: "blue", shape: "pill", label: "pay", height: 40 }}
                              createOrder={(data, actions) => createOrder(data, actions)}
                              onApprove={(data, actions) => onApprove(data, actions)}
                            />
                          </PayPalScriptProvider>
                        </div>
                     : null
                    }
                   </div>
                </div>
              </div>
               {Loading  &&
                  <Loader />
               }
            </div>
         </React.Fragment>
       )
};


const mapStateToProps = reducerObj => {

  return {  };
};

export default compose(
  connect(mapStateToProps, {

  }),
)(Payment);
