import { UPDATE_DASHBOARD_CHART_TYPE , RESET_DASHBOARD_CHART_TYPE} from "../../actions/DashboardAction/ChartTypeAction";

export const DASHBOARD_CHART_INITIAL_STATE = {
  chartType: {
    chart_type : 'Bar'
  }
};

export default (state = DASHBOARD_CHART_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_TYPE:
      return {
        ...state,
        chartType: Object.assign({}, state.chartType, action.payload)
      };
      case RESET_DASHBOARD_CHART_TYPE:
        return DASHBOARD_CHART_INITIAL_STATE
    default:
      return state;
  }
};
