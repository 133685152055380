export const UPDATE_ECHART_EVENT = "update-echart-event";
export const RESET_ECHART_EVENT = "reset-echart-event";

export const updateEChartEventObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEChartEventObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ECHART_EVENT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ECHART_EVENT
  };
};
