import { combineReducers } from "redux";

import IndexReducers from "./IndexReducers";
import ReportReducers from "./ReportReducers";
import TabReducers from "./TabReducers";
import ToolTipReducers from "./ToolTipReducers";
import TranslationReducers from "./TranslationReducers";
import ThemeReducers from "./ThemeReducers";

import CollectionReducers from "./CollectionReducers";

import GridDraggingReducer from "./GridDraggingReducers/GridDraggingReducer";
import GridCompactTypeReducers from "./GridDraggingReducers/GridCompactTypeReducers";
import GridLayoutsReducers from "./GridDraggingReducers/GridLayoutsReducers";
import GridChartDataReducers from "./GridDraggingReducers/GridChartDataReducers";
import GridBreakPointReducers from "./GridDraggingReducers/GridBreakPointReducers";

import ChartSettingsReducers  from "./ChartReducers/ChartSettingsReducers";
import ChartTypeReducers from "./ChartReducers/ChartTypeReducers";
import ChartDataReducers from "./ChartReducers/ChartDataReducers";
import ChartBaseReducers from "./ChartReducers/ChartBaseReducers";
import BarColorReducers from "./ChartReducers/BarColorReducers";
import ChartTextReducers from "./ChartReducers/ChartTextReducers";
import LineColorReducers from "./ChartReducers/LineColorReducers";
import ChartLegendReducers from "./ChartReducers/ChartLegendReducers";
import ChartEventReducers from "./ChartReducers/ChartEventReducers";

import EChartSettingsReducers  from "./EChartReducers/EChartSettingsReducers";
import EChartTypeReducers from "./EChartReducers/EChartTypeReducers";
import EChartDataReducers from "./EChartReducers/EChartDataReducers";
import EChartBaseReducers from "./EChartReducers/EChartBaseReducers";
import EBarColorReducers from "./EChartReducers/EBarColorReducers";
import EChartTextReducers from "./EChartReducers/EChartTextReducers";
import ELineColorReducers from "./EChartReducers/ELineColorReducers";
import EChartLegendReducers from "./EChartReducers/EChartLegendReducers";
import EChartEventReducers from "./EChartReducers/EChartEventReducers";

import DashBoardChartTypeReducers from "./DashboardReducers/ChartTypeReducers";
import DashBoardChartBaseReducers from "./DashboardReducers/ChartBaseReducers";
import DashBoardChartDataReducers from "./DashboardReducers/ChartDataReducers";
import DashBoardBarColorReducers from "./DashboardReducers/BarColorReducers";
import DashBoardChartTextReducers from "./DashboardReducers/ChartTextReducers";
import DashBoardLineColorReducers from "./DashboardReducers/LineColorReducers";
import DashBoardChartLegendReducers from "./DashboardReducers/ChartLegendReducers";
import DashBoardChartSettingsReducers  from "./DashboardReducers/ChartSettingsReducers";

import DashBoardTitleSettingsReducers  from "./DashboardReducers/ChartTitleReducers";

import PivotReducers from "./PivotReducers/PivotDataReducers";
import PivotSettingsReducers from "./PivotReducers/PivotSettingsReducers"
import PivotTextReducers from "./PivotReducers/PivotTextReducers"

export const reducers = combineReducers({
    indexReducers: IndexReducers,
    reportReducers: ReportReducers,
    tabReducers:TabReducers,
    toolTipReducers:ToolTipReducers,
    translationReducers:TranslationReducers,
    chartSettingsReducers:ChartSettingsReducers,
    eChartSettingsReducers:EChartSettingsReducers,

    chartTypeReducers: ChartTypeReducers,
    chartDataReducers: ChartDataReducers,
    chartBaseReducers: ChartBaseReducers,
    barColorReducers: BarColorReducers,
    chartTextReducers: ChartTextReducers,
    lineColorReducers: LineColorReducers,
    chartLegendReducers: ChartLegendReducers,
    chartEventReducers: ChartEventReducers,

    eChartTypeReducers: EChartTypeReducers,
    eChartDataReducers: EChartDataReducers,
    eChartBaseReducers: EChartBaseReducers,
    eBarColorReducers: EBarColorReducers,
    eChartTextReducers: EChartTextReducers,
    eLineColorReducers: ELineColorReducers,
    eChartLegendReducers: EChartLegendReducers,
    eChartEventReducers: EChartEventReducers,

    dashchartTypeReducers:DashBoardChartTypeReducers,
    dashbarColorReducers: DashBoardBarColorReducers,
    dashchartTextReducers: DashBoardChartTextReducers,
    dashlineColorReducers: DashBoardLineColorReducers,
    dashchartLegendReducers: DashBoardChartLegendReducers,
    dashchartDataReducers: DashBoardChartDataReducers,
    dashchartBaseReducers: DashBoardChartBaseReducers,
    DashchartSettingsReducers:DashBoardChartSettingsReducers,
    DashTitleSettingsReducers:DashBoardTitleSettingsReducers,


    PivotReducers:PivotReducers,
    PivotSettingsReducers:PivotSettingsReducers,
    PivotTextReducers: PivotTextReducers,

    collectionReducers:CollectionReducers,
    themeReducers:ThemeReducers,
    gridDraggingReducer:GridDraggingReducer,
    gridCompactTypeReducers:GridCompactTypeReducers,
    gridLayoutsReducers:GridLayoutsReducers,
    gridChartDataReducers:GridChartDataReducers,
    gridBreakPointReducers:GridBreakPointReducers

});
