import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import BarChart           from "./Bar/barChart"
import HorizontalBarChart from "./HorizontalBar/barChart"
import DonoughtChart      from "./Donought/donoughtChart"
import LineChart          from "./Line/lineChart"
import PieChart           from "./Pie/pieChart"


function chartInnerIndex(props) {

  function renderGraph_first()
  {
    let currentGraphType = props.chartSettingsObject.settingConfig_first.chart_type;

    if(currentGraphType === "Doughnut"){
      return <DonoughtChart chartInnerIndex = "first"/>;
    } else if (currentGraphType === "Pie"){
      return <PieChart chartInnerIndex = "first"/>;
    } else if(currentGraphType === "Line"){
      return <LineChart chartInnerIndex = "first"/>;
    }else if(currentGraphType === "HorizontalBar"){
       return <HorizontalBarChart chartInnerIndex = "first"/>;
    }else{
        return <BarChart chartInnerIndex = "first"/>;
    }
  };

  function renderGraph_second()
  {
    let currentGraphType = props.chartSettingsObject.settingConfig_second.chart_type;

    if(currentGraphType === "Doughnut"){
      return <DonoughtChart chartInnerIndex = "second"/>;
    } else if (currentGraphType === "Pie"){
      return <PieChart chartInnerIndex = "second"/>;
    } else if(currentGraphType === "Line"){
      return <LineChart chartInnerIndex = "second"/>;
    }else if(currentGraphType === "HorizontalBar"){
       return <HorizontalBarChart chartInnerIndex = "second"/>;
    }else{
       return <BarChart chartInnerIndex = "second"/>;
    }
  }

  function renderGraph_third()
  {
    let currentGraphType = props.chartSettingsObject.settingConfig_third.chart_type;

    if(currentGraphType === "Doughnut"){
      return <DonoughtChart chartInnerIndex = "third"/>;
    } else if (currentGraphType === "Pie"){
      return <PieChart chartInnerIndex = "third"/>;
    } else if(currentGraphType === "Line"){
      return <LineChart chartInnerIndex = "third"/>;
    }else if(currentGraphType === "HorizontalBar"){
       return <HorizontalBarChart chartInnerIndex = "third"/>;
    }else{
         return <BarChart chartInnerIndex = "third"/>;
    }
  };

  function render_this() {
    let component = null;

      switch(props.chartIndex) {
        case "first":
            component = renderGraph_first();
          break;
        case "second":
            component = renderGraph_second();
          break;
          case "third":
            component = renderGraph_third();
          break;
        default:
         component = null;
      }
      return component;
    }

  return (
   <React.Fragment>
        {render_this()}
   </React.Fragment>
  );
}


const mapStateToProps = reducerObj => {
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartSettingsObject  = reducerObj.DashchartSettingsReducers.chartSettingsObject;

  return { chartDataObject ,chartBaseObject , barColorObject,
    chartTextObject,chartLegendObject , lineColorObject,chartSettingsObject };
};

export default compose(
  connect(mapStateToProps, {
  }),
)(chartInnerIndex);
