import React, { useState , useEffect}  from "react";

import { Auth } from "aws-amplify";

import { Loader } from "../common/Loader/loader";

import { store } from 'react-notifications-component';

import {API_SERVER }  from '../constant';

export default function About(props) {

     let [Loading, setLoading] = useState(false);
     let [expand, setExpanded] = useState(false);
     let [userEmail, setUserEmail] = useState(null);


        function toggleSidebar()
        {
          setExpanded(expand=!expand);
        }

        async function getUserDetails()
        {
          const user =  await Auth.currentUserInfo();
          setUserEmail(userEmail= user.attributes.email );
          setLoading(false);
        }

        useEffect(() => {
          setLoading(true);
          getUserDetails();
        },[]);

    return(
        <React.Fragment>
          <div id="wrapper" className="menu">
              <div id="page-content-wrapper-tab">
                
              </div>
            {Loading  &&
                   <Loader />
                }
          </div>
         </React.Fragment>
       )
};
