import React, { useState }  from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { compose } from "recompose";
import { SketchPicker } from 'react-color';
import FontPicker from "font-picker-react";
import reactCSS from 'reactcss';
import {useTranslation} from "react-i18next";

import {FONT_API_KEY }  from '../../constant';
import { updateDashboardTitleObject,resetDashboardTitleObject } from "../../../actions/DashboardAction/ChartTitleAction";

function TextBase(props) {
  const {t, i18n} = useTranslation('common');
  let [chartTitle,setChartTitle] = useState(props.dashboardTitleObject.title);
  let [chartSubTitle,setChartSubTitle] = useState(props.dashboardTitleObject.subtitle);

  let [displayTitleColorPicker,setDisplayTitleColorPicker] = useState(false);
  let [titleColor,setTitleColor] = useState(props.dashboardTitleObject.titleColor);

  let [displaySubTitleColorPicker,setDisplaySubTitleColorPicker] = useState(false);
  let [subTitleColor,setSubTitleColor] =  useState(props.dashboardTitleObject.subTitleColor);

  let [activeFontFamily,setActiveFontFamily] = useState(props.dashboardTitleObject.fontFamily);

  const styles = reactCSS({
       'default': {
         color: {
           width: '21px',
           height: '21px',
           borderRadius: '36px'
         },
         swatch: {
           padding: '0px',
           background: 'rgb(255, 255, 255)',
           borderRadius: '12px',
           boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
           display: 'inline-block',
           cursor: 'pointer',
         },
         popover: {
           position: 'absolute',
           zIndex: '2',
         },
         cover: {
           position: 'fixed',
           top: '0px',
           right: '0px',
           bottom: '0px',
           left: '0px',
         },
       },
     });

     function handleColorPickerStyle(type)
     {
       if(type === "title")
        {
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background:props.dashboardTitleObject.titleColor
              }
            },
          });
        }else{
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: props.dashboardTitleObject.subTitleColor
              }
            },
          });
        }
     }

     function handleColorClick (e,type)
      {
         if(type === "title")
        {
          setDisplayTitleColorPicker( displayTitleColorPicker = !displayTitleColorPicker );

        }else {
          setDisplaySubTitleColorPicker( displaySubTitleColorPicker = !displaySubTitleColorPicker );
        }

      };

      function handleColorClose (e,type)
      {
        if(type === "title")
        {
          setDisplayTitleColorPicker(displayTitleColorPicker = false)

        }else {
          setDisplaySubTitleColorPicker(displaySubTitleColorPicker = false)
        }
      };

      function handleColorChange(color,type)
      {
        if(type === "title")
        {
            setTitleColor( titleColor = color.hex);
            props.updateDashboardTitleObject({titleColor:titleColor,subTitleColor:subTitleColor,title:chartTitle,subtitle:chartSubTitle,fontFamily:activeFontFamily});
        }else if(type === "subtitle"){
            setSubTitleColor( subTitleColor = color.hex);
            props.updateDashboardTitleObject({titleColor:titleColor,subTitleColor:subTitleColor,title:chartTitle,subtitle:chartSubTitle,fontFamily:activeFontFamily});
        }else{

        }
      };

  function handleChangeTitleChnage(e)
  {
    setChartTitle(chartTitle=e.target.value)
    props.updateDashboardTitleObject({titleColor:titleColor,subTitleColor:subTitleColor,title:chartTitle,subtitle:chartSubTitle,fontFamily:activeFontFamily});
  };

  function handleChangeSubtitleChnage(e)
  {
    setChartSubTitle(chartSubTitle=e.target.value)
    props.updateDashboardTitleObject({titleColor:titleColor,subTitleColor:subTitleColor,title:chartTitle,subtitle:chartSubTitle,fontFamily:activeFontFamily});
  };

  function handleChangeFontChange(nextFont)
  {
    setActiveFontFamily(activeFontFamily=nextFont.family);
    props.updateDashboardTitleObject({titleColor:titleColor,subTitleColor:subTitleColor,title:chartTitle,subtitle:chartSubTitle,fontFamily:activeFontFamily});
  };

  return (
   <React.Fragment>
     <ul className="nested" id="text">
        <li>
            <table>
              <tbody>
              <tr>
                  <td>
                    {t('Text.title')}
                  </td>
                  <td>
                    <input className="input"
                          type="text"
                          name="title"
                          value={chartTitle}
                          onChange={handleChangeTitleChnage}
                          />
                  </td>
              </tr>
              <tr>
                  <td>
                   {t('Text.titleColor')}
                  </td>
                  <td>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "title")}}>
                            <div style={ handleColorPickerStyle("title").color  } />
                          </div>
                          { displayTitleColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "title")}} />
                            <SketchPicker color={ props.dashboardTitleObject.titleColor } onChange={ (e) => { handleColorChange(e, "title")}} />
                          </div> : null }
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                  {t('Text.subtitle')}
                  </td>
                  <td>
                    <input className="input"
                          type="text"
                          name="subtitle"
                          value={chartSubTitle}
                          onChange={handleChangeSubtitleChnage}
                          />
                  </td>
              </tr>
              <tr>
                  <td>
                  {t('Text.subtitleColor')}
                  </td>
                  <td>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "subtitle")}}>
                            <div style={ handleColorPickerStyle("subtitle").color  } />
                          </div>
                          { displaySubTitleColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "subtitle")}} />
                            <SketchPicker color={ props.dashboardTitleObject.subTitleColor } onChange={ (e) => { handleColorChange(e, "subtitle")}} />
                          </div> : null }
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                     {t('Text.font')}
                  </td>
                  <td>
                    <FontPicker
              					apiKey={FONT_API_KEY}
              					activeFontFamily={props.dashboardTitleObject.fontFamily}
              					onChange={handleChangeFontChange}
              				/>
                  </td>
              </tr>
              </tbody>
            </table>
        </li>
      </ul>
   </React.Fragment>
  );
}




const mapStateToProps = reducerObj => {

  const dashboardTitleObject = reducerObj.DashTitleSettingsReducers.titleData;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;
  return { dashboardTitleObject,languageObject,toolTipObject };
};

export default compose(
  connect(mapStateToProps, {
    updateDashboardTitleObject
  }),
)(TextBase);
