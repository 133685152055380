import { UPDATE_DASHBOARD_CHART_TEXT , RESET_DASHBOARD_CHART_TEXT } from "../../actions/DashboardAction/ChartTextAction";

export const DASHBOARD_CHART_TEXT_INITIAL_STATE = {
  textData: {
    title : '',
    titleColor:'#0038AC',
    subtitle:'',
    subTitleColor :'#CADBFF',
    fontFamily:'Open Sans',

    title_first : '',
    titleColor_first:'#0038AC',
    subtitle_first:'',
    subTitleColor_first :'#CADBFF',
    fontFamily_first:'Open Sans',

    title_second : '',
    titleColor_second:'#0038AC',
    subtitle_second:'',
    subTitleColor_second :'#CADBFF',
    fontFamily_second:'Open Sans',

    title_third : '',
    titleColor_third:'#0038AC',
    subtitle_third:'',
    subTitleColor_third :'#CADBFF',
    fontFamily_third:'Open Sans'
  }
};

export default (state = DASHBOARD_CHART_TEXT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_TEXT:
      return {
        ...state,
        textData: Object.assign({}, state.textData, action.payload)
      };
      case RESET_DASHBOARD_CHART_TEXT:
        return DASHBOARD_CHART_TEXT_INITIAL_STATE
    default:
      return state;
  }
};
