import React, { useState }  from "react";
import Popup from "reactjs-popup";
import FileViewer from "./fileViewer";
import "./modal.css";


function HelpModal(props) {

     let { showHelpPopup, closeHelpPopup } = props;


  return (
   <React.Fragment>
    <Popup open={showHelpPopup} onClose={closeHelpPopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Help</p>
              <button className="deleteBtn" onClick={props.closeHelpPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
              <div className="container">
                <FileViewer />
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



export default HelpModal
