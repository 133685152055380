import { UPDATE_THEME , RESET_THEME } from "../actions/ThemeAction";

export const THEME_INITIAL_STATE = {
  themeObject: {
    buttonColor:'#00f',
  }
};

export default (state = THEME_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        ...state,
        themeObject: Object.assign({}, state.buttonColor, action.payload)
      };
      case RESET_THEME:
        return THEME_INITIAL_STATE
    default:
      return state;
  }
};
