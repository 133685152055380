import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import { updateELineColorObject } from "../../../../actions/EChartAction/ELineColorAction";

function LineColor(props) {

  let [displayFillColorPicker,setDisplayFillColorPicker] = useState(false);
  let [fillColor,setFillColor] = useState({r: props.lineColorObject.fillColor.r,g: props.lineColorObject.fillColor.g,b: props.lineColorObject.fillColor.b,a: props.lineColorObject.fillColor.a});

  let [displayStrokeColorPicker,setDisplayStrokeColorPicker] = useState(false);
  let [strokeColor,setStrokeColor] =  useState({r: props.lineColorObject.strokeColor.r,g: props.lineColorObject.strokeColor.g,b: props.lineColorObject.strokeColor.b,a: props.lineColorObject.strokeColor.a});

  let [displayPointStrokeColorPicker,setDisplayPointStrokeColorPicker] = useState(false);
  let [pointStrokeColor,setPointStrokeColor] =  useState({r: props.lineColorObject.pointStrokeColor.r,g: props.lineColorObject.pointStrokeColor.g,b: props.lineColorObject.pointStrokeColor.b,a: props.lineColorObject.pointStrokeColor.a});


  const styles = reactCSS({
       'default': {
         color: {
           width: '21px',
           height: '21px',
           borderRadius: '36px'
         },
         swatch: {
           padding: '0px',
           background: 'rgb(255, 255, 255)',
           borderRadius: '12px',
           boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
           display: 'inline-block',
           cursor: 'pointer',
         },
         popover: {
           position: 'absolute',
           zIndex: '2',
         },
         cover: {
           position: 'fixed',
           top: '0px',
           right: '0px',
           bottom: '0px',
           left: '0px',
         },
       },
     });

     function handleColorPickerStyle(type)
     {
       if(type === "fill")
        {
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: `rgba(${ props.lineColorObject.fillColor.r }, ${ props.lineColorObject.fillColor.g }, ${ props.lineColorObject.fillColor.b }, ${ props.lineColorObject.fillColor.a })`,
              }
            },
          });
        }else if(type === "strokes"){
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: `rgba(${ props.lineColorObject.strokeColor.r }, ${ props.lineColorObject.strokeColor.g }, ${ props.lineColorObject.strokeColor.b }, ${ props.lineColorObject.strokeColor.a })`,
              }
            },
          });
        }else{
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: `rgba(${ props.lineColorObject.pointStrokeColor.r }, ${ props.lineColorObject.pointStrokeColor.g }, ${ props.lineColorObject.pointStrokeColor.b }, ${ props.lineColorObject.pointStrokeColor.a })`,
              }
            },
          });
        }
     }

     function handleColorClick (e,type)
      {
         if(type === "fill")
        {
          setDisplayFillColorPicker( displayFillColorPicker = !displayFillColorPicker );
        }else if(type === "stroke"){
          setDisplayStrokeColorPicker( displayStrokeColorPicker = !displayStrokeColorPicker );
        }else{
          setDisplayPointStrokeColorPicker(displayPointStrokeColorPicker = !displayPointStrokeColorPicker);
        }

      };

      function handleColorClose (e,type)
      {
        if(type === "fill")
        {
          setDisplayFillColorPicker(displayFillColorPicker = false)
        }else if(type === "stroke") {
          setDisplayStrokeColorPicker(displayStrokeColorPicker = false)
        }else{
          setDisplayPointStrokeColorPicker(displayPointStrokeColorPicker = false)
        }
      };

      function handleColorChange(color,type)
      {
        if(type === "fill")
        {
            setFillColor( fillColor = color.rgb);
            props.updateELineColorObject({fillColor:color.rgb});
        }else if(type === "stroke"){
            setStrokeColor( strokeColor = color.rgb);
            props.updateELineColorObject({strokeColor:color.rgb});
        }else{
           setPointStrokeColor( pointStrokeColor = color.rgb);
           props.updateELineColorObject({pointStrokeColor:color.rgb});
        }
      };

  return (
   <React.Fragment>
    <ul className="nested" id="colors">
       <li>
           <table>
             <tbody>
             <tr>
                 <td>
                    Fill
                 </td>
                 <td>
                   <div className="circle fill-circle">
                   <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "fill")}}>
                           <div style={ handleColorPickerStyle("fill").color  } />
                         </div>
                         { displayFillColorPicker ? <div style={ styles.popover }>
                           <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "fill")}} />
                           <SketchPicker color={ props.lineColorObject.fillColor } onChange={ (e) => { handleColorChange(e, "fill")}} />
                         </div> : null }
                   </div>
                 </td>
             </tr>
             <tr>
                 <td>
                 Stroke
                 </td>
                 <td>
                   <div className="circle stock-circle">
                   <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "stroke")}}>
                           <div style={ handleColorPickerStyle("stroke").color } />
                         </div>
                         { displayStrokeColorPicker ? <div style={ styles.popover }>
                           <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "stroke")}} />
                           <SketchPicker color={ props.lineColorObject.strokeColor } onChange={ (e) => { handleColorChange(e, "stroke")}} />
                         </div> : null }
                   </div>
                 </td>
             </tr>
             <tr>
                 <td>
                 Point Stroke
                 </td>
                 <td>
                   <div className="circle stock-circle">
                   <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "pointStroke")}}>
                           <div style={ handleColorPickerStyle("pointStroke").color } />
                         </div>
                         { displayPointStrokeColorPicker ? <div style={ styles.popover }>
                           <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "pointStroke")}} />
                           <SketchPicker color={ props.lineColorObject.pointStrokeColor } onChange={ (e) => { handleColorChange(e, "pointStroke")}} />
                         </div> : null }
                   </div>
                 </td>
             </tr>
             </tbody>
           </table>
       </li>
     </ul>
   </React.Fragment>
  );
}




const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const lineColorObject = reducerObj.eLineColorReducers.LineColor;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,lineColorObject};
};

export default compose(
  connect(mapStateToProps, {
    updateELineColorObject
  }),
)(LineColor);
