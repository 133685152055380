import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

const PieChart = (props) => {
  function onChartClick(e) {
    if (e.componentType === 'title') {
    } else if (e.componentType === 'series') {
    } else if (e.componentType === 'series11') {
    } else {
    }
  }

  function onChartLegendselectchanged() {}

  let _onEvents = {
    click: onChartClick,
  };

  function pie_first() {
    console.log('pie first called hari om', props.chartDataObject);
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title_first,
            textStyle: {
              color: props.chartTextObject.titleColor_first,
            },
            subtext: props.chartTextObject.subtitle_first,
            subtextStyle: {
              color: props.chartTextObject.subTitleColor_first,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_first,
            orient: props.chartLegendObject.orientation_first,
            left: props.chartLegendObject.leftPosition_first,
            top: props.chartLegendObject.topPosition_first,
            data: props.chartDataObject.chart_labels_first,
            textStyle: {
              color: `rgba(${props.chartLegendObject.fontColor_first.r}, ${props.chartLegendObject.fontColor_first.g}, ${props.chartLegendObject.fontColor_first.b}, ${props.chartLegendObject.fontColor_first.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: props.chartDataObject.chart_data_first?.map((value, i) => ({
                name: props.chartDataObject.chart_labels_first[i],
                value,
              })),
              itemStyle: {
                emphasis: {
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0)',
                },
              },
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function pie_second() {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title_second,
            textStyle: {
              color: props.chartTextObject.titleColor_second,
            },
            subtext: props.chartTextObject.subtitle_second,
            subtextStyle: {
              color: props.chartTextObject.subTitleColor_second,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_second,
            orient: props.chartLegendObject.orientation_second,
            left: props.chartLegendObject.leftPosition_second,
            top: props.chartLegendObject.topPosition_second,
            data: props.chartDataObject.chart_labels_second,
            textStyle: {
              color: `rgba(${props.chartLegendObject.fontColor_second.r}, ${props.chartLegendObject.fontColor_second.g}, ${props.chartLegendObject.fontColor_second.b}, ${props.chartLegendObject.fontColor_second.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: props.chartDataObject.chart_data_second?.map(
                (value, i) => ({
                  name: props.chartDataObject.chart_labels_second[i],
                  value,
                })
              ),
              itemStyle: {
                emphasis: {
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0)',
                },
              },
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function pie_third() {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title,
            textStyle: {
              color: props.chartTextObject.titleColor,
            },
            subtext: props.chartTextObject.subtitle,
            subtextStyle: {
              color: props.chartTextObject.subTitleColor,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_third,
            orient: props.chartLegendObject.orientation_third,
            left: props.chartLegendObject.leftPosition_third,
            top: props.chartLegendObject.topPosition_third,
            data: props.chartDataObject.chart_labels_third,
            textStyle: {
              color: `rgba(${props.chartLegendObject.fontColor_third.r}, ${props.chartLegendObject.fontColor_third.g}, ${props.chartLegendObject.fontColor.b}, ${props.chartLegendObject.fontColor.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: props.chartDataObject.chart_data_third?.map((value, i) => ({
                name: props.chartDataObject.chart_labels_third[i],
                value,
              })),
              itemStyle: {
                emphasis: {
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0)',
                },
              },
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function render_this() {
    let component = null;

    switch (props.chartInnerIndex) {
      case 'first':
        component = pie_first();
        break;
      case 'second':
        component = pie_second();
        break;
      case 'third':
        component = pie_third();
        break;
      default:
        component = null;
    }
    return component;
  }

  return <React.Fragment>{render_this()}</React.Fragment>;
};

const mapStateToProps = (reducerObj) => {
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartSettingsObject =
    reducerObj.DashchartSettingsReducers.chartSettingsObject;

  return {
    chartDataObject,
    chartBaseObject,
    barColorObject,
    chartTextObject,
    chartLegendObject,
    lineColorObject,
    chartSettingsObject,
  };
};

export default compose(connect(mapStateToProps, {}))(PieChart);
