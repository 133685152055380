import { UPDATE_DASHBOARD_CHART_DATA , RESET_DASHBOARD_CHART_DATA} from "../../actions/DashboardAction/ChartDataAction";

export const DASHBOARD_CHART_DATA_INITIAL_STATE = {
  chartData: {
    chart_labels_first : [],
    chart_data_first : [],
    chart_colors_first : [],
    chart_array_first : [],
    chart_xaxis_label_first :'',
    chart_yaxis_label_first :'',
    index_object_first:'',
    report_object_first:'',

    chart_labels_second : [],
    chart_data_second : [],
    chart_colors_second : [],
    chart_array_second : [],
    chart_xaxis_label_second :'',
    chart_yaxis_label_second :'',
    index_object_second:'',
    report_object_second:'',

    chart_labels_third : [],
    chart_data_third : [],
    chart_colors_third : [],
    chart_array_third : [],
    chart_xaxis_label_third :'',
    chart_yaxis_label_third :'',
    index_object_third:'',
    report_object_third:'',

    chart_labels_four : [],
    chart_data_four : [],
    chart_colors_four : [],
    chart_array_four : [],
    chart_xaxis_label_four :'',
    chart_yaxis_label_four :'',
  }
};

export default (state = DASHBOARD_CHART_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_DATA:
      return {
        ...state,
        chartData: Object.assign({}, state.chartData, action.payload)
      };
      case RESET_DASHBOARD_CHART_DATA:
        return DASHBOARD_CHART_DATA_INITIAL_STATE
    default:
      return state;
  }
};
