import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import { updateEChartTypeObject } from "../../../actions/EChartAction/EChartTypeAction";
import { updateEChartBaseObject,resetEChartBaseObject } from "../../../actions/EChartAction/EChartBaseAction";
import { resetEBarColorObject } from "../../../actions/EChartAction/EBarColorAction";

function ChartBase(props) {

  let [displayBackGroundColorPicker,setDisplayBackGroundColorPicker] = useState(false);
  let [backGroundColor,setBackGroundColor] = useState(props.chartBaseObject.background_color);

  let [canvasHeight,setCanvasHeight] = useState(props.chartBaseObject.canvasHeight);
  let [canvasWidth,setCanvasWidth] = useState(props.chartBaseObject.canvasWidth);

  const styles = reactCSS({
       'default': {
         color: {
           width: '21px',
           height: '21px',
           borderRadius: '36px'
         },
         swatch: {
           padding: '0px',
           background: 'rgb(255, 255, 255)',
           borderRadius: '12px',
           boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
           display: 'inline-block',
           cursor: 'pointer',
         },
         popover: {
           position: 'absolute',
           zIndex: '2',
         },
         cover: {
           position: 'fixed',
           top: '0px',
           right: '0px',
           bottom: '0px',
           left: '0px',
         },
       },
     });

     function handleColorPickerStyle(type)
     {
        if(type === "background")
        {
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: props.chartBaseObject.background_color
              }
            },
          });
        }
     };

     function handleColorClick (e,type)
      {
        if(type === "background")
        {
          setDisplayBackGroundColorPicker( displayBackGroundColorPicker = !displayBackGroundColorPicker );
        }
      };

      function handleColorClose (e,type)
      {
        if(type === "background")
        {
          setDisplayBackGroundColorPicker(displayBackGroundColorPicker = false)
        }
      };

      function handleColorChange(color,type)
      {
        if(type === "background")
        {
          setBackGroundColor( backGroundColor = color.hex);
          props.updateEChartBaseObject({background_color:color.hex});
        }
      };

  function handleGraphTypeChange(e)
  {
    props.updateEChartTypeObject({chart_type:e.target.value});
    props.resetEChartBaseObject();
    props.resetEBarColorObject();
  };


  function handleCanvasWidthChange(e)
  {
    setCanvasWidth( canvasWidth = e.target.value);
    props.updateEChartBaseObject({canvasWidth:e.target.value});
  };

  function handleCanvasHeightChange(e)
  {
    setCanvasHeight( canvasHeight = e.target.value);
    props.updateEChartBaseObject({canvasHeight:e.target.value});
  };

  return (
   <React.Fragment>
       <ul className="nested" id="charts">
         <li>
             <table>
               <tbody>
               <tr className="ChartTypes">
                   <td>Chart Type</td>
                   <td>
                     <div className="relativePosition">
                         <select value={props.chartObject.chart_type} onChange={handleGraphTypeChange} id="ChartType">
                           <option value="Line">Line chart</option>
                           <option value="Bar">Bar chart</option>
                           <option value="HorizontalBar">Horizontal Bar chart</option>
                           <option value="Pie">Pie Area chart</option>
                           <option value="Doughnut">Doughnut chart</option>
                         </select>
                         <div className="Hideselect"></div>
                     </div>
                   </td>
                </tr>
                <tr>
                   <td>Width</td>
                   <td><input type="number" id="quantity" name="quantity" value={props.chartBaseObject.canvasWidth}  onChange={ handleCanvasWidthChange }  min="400" max="10000" /></td>
               </tr>
               <tr>
                   <td>Height</td>
                   <td><input type="number" id="quantity" name="quantity" value={props.chartBaseObject.canvasHeight} onChange={ handleCanvasHeightChange }   min="300" max="10000" /></td>
               </tr>
                <tr>
                   <td>BackColor</td>
                   <td>
                     <div className="circle33 backcolor-circle">
                     <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "background")}}>
                           <div style={ handleColorPickerStyle("background").color } />
                         </div>
                         { displayBackGroundColorPicker ? <div style={ styles.popover }>
                           <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "background")}} />
                           <SketchPicker color={props.chartBaseObject.background_color} onChange={ (e) => { handleColorChange(e, "background")}} />
                         </div> : null }
                     </div>
                   </td>
               </tr>
               </tbody>
             </table>
         </li>
     </ul>
   </React.Fragment>
  );
}




const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject};
};

export default compose(
  connect(mapStateToProps, {
    updateEChartTypeObject,
    resetEChartBaseObject,
    updateEChartBaseObject,
    resetEBarColorObject
  }),
)(ChartBase);
