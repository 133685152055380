import { UPDATE_LINE_COLOR , RESET_LINE_COLOR} from "../../actions/ChartAction/LineColorAction";

export const LINE_COLOR_INITIAL_STATE = {
  LineColor: {
    fillColor:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    strokeColor:{"r": 86,"g": 133,"b": 30,"a": 1},
    pointStrokeColor :{"r":75,"g": 192,"b":192,"a": 1},
    pointsColor:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    checkFillColor:false,
    pointRadius:3,
    pointStyle:"circle"
  }
};

export default (state = LINE_COLOR_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LINE_COLOR:
      return {
        ...state,
        LineColor: Object.assign({}, state.LineColor, action.payload)
      };
      case RESET_LINE_COLOR:
        return LINE_COLOR_INITIAL_STATE
    default:
      return state;
  }
};
