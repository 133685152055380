import { UPDATE_GRID_COMPACT , RESET_GRID_COMPACT} from "../../actions/GridDraggingAction/GridCompactTypeAction";

export const GRID_COMPACT_INITIAL_STATE = {
  compactType: {
    compact:"vertical"
  }
};

export default (state = GRID_COMPACT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_GRID_COMPACT:
      return {
        ...state,
        compactType: Object.assign({}, state.compactType, action.payload)
      };
      case RESET_GRID_COMPACT:
        return GRID_COMPACT_INITIAL_STATE
    default:
      return state;
  }
};
