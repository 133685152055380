export const UPDATE_BAR_COLOR = "update-bar-color";
export const RESET_BAR_COLOR = "reset-bar-color";

export const updateBarColorObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetBarColorObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_BAR_COLOR,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_BAR_COLOR
  };
};
