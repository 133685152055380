export const UPDATE_CHART_DATA = "update-chart-data";
export const RESET_CHART_DATA = "reset-chart-data";

export const updateChartDataObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetChartDataObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_CHART_DATA,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_CHART_DATA
  };
};
