import { UPDATE_ECHART_EVENT , RESET_ECHART_EVENT} from "../../actions/EChartAction/EChartEventAction";

export const ECHART_EVENT_INITIAL_STATE = {
  eventList: {
    legendClicked:false,
    titleClicked:false,
    chartClicked:false,
  }
};

export default (state = ECHART_EVENT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_EVENT:
      return {
        ...state,
        eventList: Object.assign({}, state.eventList, action.payload)
      };
      case RESET_ECHART_EVENT:
        return ECHART_EVENT_INITIAL_STATE
    default:
      return state;
  }
};
