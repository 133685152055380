export const UPDATE_COLLECTION_PARAMS = "update-collection-params";
export const RESET_COLLECTION_PARAMS = "reset-collection-params";

export const updateCollectionObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetCollectionObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_COLLECTION_PARAMS,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_COLLECTION_PARAMS
  };
};
