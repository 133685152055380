import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import ReactEcharts from "echarts-for-react";

import { updateEChartEventObject } from "../../../../actions/EChartAction/EChartEventAction";

const HorizontalBarChart = ( props ) => {

  function onChartClick(e)
   {
     if(e.componentType === "title")
     {
       props.updateEChartEventObject({titleClicked:props.chartEventObject.titleClicked === true ? false : true })
     }else if(e.componentType === "series")
     {
       props.updateEChartEventObject({chartClicked:props.chartEventObject.chartClicked === true ? false : true })

     }else if(e.componentType === "series11")
     {

     }else{

     }
   }

   function onChartLegendselectchanged()
    {
    }

   let _onEvents = {
   'click': onChartClick,
   'legendselectchanged': onChartLegendselectchanged
  }

  return (
       <React.Fragment>
         <ReactEcharts
             option={{
               title: {
                 text: props.chartTextObject.title,
                 textStyle: {
                    color: props.chartTextObject.titleColor,
                    fontFamily:props.chartTextObject.fontFamily,
                  },
                 subtext: props.chartTextObject.subtitle,
                 subtextStyle: {
                    color: props.chartTextObject.subTitleColor,
                    fontFamily:props.chartTextObject.fontFamily,
                  },
                 left: 'center',
                 triggerEvent: true
               },
               tooltip: {
                   trigger: 'axis',
                   axisPointer: {
                       type: 'shadow'
                   }
               },
               grid: { containLabel: true },
               toolbox: {
                   show: true,
                   left: 'right',
                   itemSize: 25,
                   top: 20,
                   feature: {
                     restore: {
                       title: 'reset',
                     },
                   }
                 },
               dataZoom: [{
                   type: 'inside',
                   throttle: 10
               }],
               legend: {
                   data: [ props.chartDataObject.chart_xaxis_label],
                   show:props.chartLegendObject.display,
                   orient:props.chartLegendObject.orientation,
                   left:props.chartLegendObject.leftPosition,
                   top:props.chartLegendObject.topPosition,
                   textStyle:{
                       fontFamily:props.chartTextObject.fontFamily,
                       color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
                   },
                   triggerEvent: true
               },
               xAxis: {
                 type: "value",
                 boundaryGap: [0, 0.01]
               },
               yAxis: {
                 type: "category",
                 data: props.chartDataObject.chart_labels
               },
               series: [{
                 name: props.chartDataObject.chart_xaxis_label,
                 data: props.chartDataObject.chart_data,
                 type: "bar",
                 itemStyle: {
                    color: `rgba(${ props.barColorObject.strokeColor.r }, ${ props.barColorObject.strokeColor.g }, ${ props.barColorObject.strokeColor.b }, ${ props.barColorObject.strokeColor.a })`
                  }
               }]
             }}
             style={{height: '400px', width: '100%'}}
              onEvents={_onEvents}
             className='echarts-for-echarts'
           />
      </React.Fragment>
    )
};


const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const lineColorObject = reducerObj.eLineColorReducers.LineColor;
  const chartEventObject = reducerObj.eChartEventReducers.eventList;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,chartLegendObject,chartTextObject,chartEventObject};
};

export default compose(
  connect(mapStateToProps, {
    updateEChartEventObject
  }),
)(HorizontalBarChart);
