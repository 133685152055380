export const UPDATE_ECHART_DATA = "update-echart-data";
export const RESET_ECHART_DATA = "reset-echart-data";

export const updateEChartDataObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEChartDataObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ECHART_DATA,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ECHART_DATA
  };
};
