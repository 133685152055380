import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import './MainPage.css';

function MainPage(props) {
  return (
    <React.Fragment>
      <div
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
      >
        <iframe
          src={process.env.REACT_APP_EDITOR_URL}
          width='100%'
          height='100%'
          title='editor'
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  return {};
};

export default compose(connect(mapStateToProps, {}))(MainPage);
