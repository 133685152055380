export const UPDATE_TAB = "update-tab";
export const RESET_TAB = "reset-tab";

export const updateTabObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetTabObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_TAB,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_TAB
  };
};
