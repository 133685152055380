export const UPDATE_GRID_COMPACT = "update-grid-compact";
export const RESET_GRID_COMPACT = "reset-grid-compact";

export const updateGridCompactObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetGridCompactObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_GRID_COMPACT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_GRID_COMPACT
  };
};
