import React, { useState }  from "react";
import Popup from "reactjs-popup";

import "./modal.css";


function DownloadModal(props) {

     let { showDownloadPopup, closeDownloadPopup ,zipLink,fileLink } = props;
     let [downloadType,setDownloadType] = useState("FILE");

     function handleDownloadTypeChange(e)
     {
        setDownloadType(downloadType=e.target.value);
     };

      async function handleSubmit(e)
      {
         e.preventDefault();
         if(downloadType === "ZIP"){
             window.open(zipLink, "_blank")
         }else{
             window.open(fileLink, "_blank")
         }
        closeDownloadPopup();
      };


  return (
   <React.Fragment>
    <Popup open={showDownloadPopup} onClose={closeDownloadPopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Download Report As</p>
              <button className="deleteBtn" onClick={props.closeDownloadPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
              <div className="container">
                  <div className="row col-sm-12 col-md-10 col-lg-8">
                      <div className="col-md-8 col-lg-7">
                          <div className="styled-select relativePosition">
                              <select value={downloadType} onChange={handleDownloadTypeChange} className="form-control" id="exampleFormControlSelect1">
                                 <option value="FILE">Download original file</option>
                                <option value="ZIP">Download all reporting files (ZIP)</option>
                               
                              </select>
                              <div className="Hideselect"></div>
                          </div>
                      </div>
                      <div className="col-md-4 col-lg-5">
                            <button type="button"  className="btn yellow-btn" onClick={(e) => handleSubmit(e)} >Download</button>
                      </div>
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



export default DownloadModal
