import React from 'react';

import "./footer.css";


function Footer() {


  return (
  		<React.Fragment>
  			<div className="bottom section-padding">
		        <div className="container">
		            <div className="row">
		                <div className="col-md-12 text-center">
		                    <div className="copyright">
		                        <p>© Copyright Presto Charts (Data Analysis Made Simple) 2021.</p>
		                    </div>
		                </div>
		            </div>
		        </div>
    		</div>
   		</React.Fragment>
       )
}

export default Footer;
