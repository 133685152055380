import { UPDATE_CHART_TEXT , RESET_CHART_TEXT } from "../../actions/ChartAction/ChartTextAction";

export const CHART_TEXT_INITIAL_STATE = {
  textData: {
    title : '',
    titleColor:'#0038AC',
    subtitle:'',
    subTitleColor :'#CADBFF',
    fontFamily:'Open Sans'
  }
};

export default (state = CHART_TEXT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_TEXT:
      return {
        ...state,
        textData: Object.assign({}, state.textData, action.payload)
      };
      case RESET_CHART_TEXT:
        return CHART_TEXT_INITIAL_STATE
    default:
      return state;
  }
};
