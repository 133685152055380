import { UPDATE_BREAK_POINT , RESET_BREAK_POINT} from "../../actions/GridDraggingAction/GridBreakPointAction";

export const BREAK_POINT_INITIAL_STATE = {
  breakPoint: {
    breakpoint:"lg"
  }
};

export default (state = BREAK_POINT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BREAK_POINT:
      return {
        ...state,
        breakPoint: Object.assign({}, state.breakPoint, action.payload)
      };
      case RESET_BREAK_POINT:
        return BREAK_POINT_INITIAL_STATE
    default:
      return state;
  }
};
