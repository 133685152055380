import { UPDATE_DASHBOARD_TITLE , RESET_DASHBOARD_TITLE } from "../../actions/DashboardAction/ChartTitleAction";

export const DASHBOARD_TITLE_INITIAL_STATE = {
  titleData: {
    title : '',
    titleColor:'#0038AC',
    subtitle:'',
    subTitleColor :'#CADBFF',
    fontFamily:'Open Sans',
  }
};

export default (state = DASHBOARD_TITLE_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_TITLE:
      return {
        ...state,
        titleData: Object.assign({}, state.textData, action.payload)
      };
      case RESET_DASHBOARD_TITLE:
        return DASHBOARD_TITLE_INITIAL_STATE
    default:
      return state;
  }
};
