import { UPDATE_CHART_DATA , RESET_CHART_DATA} from "../../actions/GridDraggingAction/GridChartDataAction";

import layoutConfig from "./layoutConfig";

export const CHART_DATA_INITIAL_STATE = {
  chartData: {
  }
};

export default (state = CHART_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_DATA:
      return {
        ...state,
        chartData: Object.assign({}, state.chartData, action.payload)
      };
      case RESET_CHART_DATA:
        return CHART_DATA_INITIAL_STATE
    default:
      return state;
  }
};
