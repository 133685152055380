import React, { useState ,useEffect}  from "react";
import Popup from "reactjs-popup";

import { store } from 'react-notifications-component';
import { connect } from "react-redux";
import axios from 'axios';
import {API_SERVER }  from '../constant';
import { PropTypes } from "prop-types";

import { compose } from "recompose";

import { FormValidator } from "../common/FormValidator/FormValidator";
import { Loader } from "../common/Loader/loader";
import ConfirmationAlertModal from "./ConfirmationAlertModal";

import { updateIndexObject } from "../../actions/IndexAction";
import { updateReportObject } from "../../actions/ReportAction";
import { updateEChartTypeObject,resetEChartTypeObject } from "../../actions/EChartAction/EChartTypeAction";
import { updateEChartDataObject,resetEChartDataObject } from "../../actions/EChartAction/EChartDataAction";
import { updateEChartBaseObject,resetEChartBaseObject } from "../../actions/EChartAction/EChartBaseAction";
import { updateEChartTextObject,resetEChartTextObject } from "../../actions/EChartAction/EChartTextAction";
import { updateEChartEventObject,resetEChartEventObject } from "../../actions/EChartAction/EChartEventAction";
import { updateEChartLegendObject,resetEChartLegendObject } from "../../actions/EChartAction/EChartLegendAction";
import { updateEBarColorObject,resetEBarColorObject } from "../../actions/EChartAction/EBarColorAction";
import { updateELineColorObject,resetELineColorObject } from "../../actions/EChartAction/ELineColorAction";

import { updateEChartSettingObject,resetEChartSettingObject } from "../../actions/EChartAction/EChartSettingsAction";

import "./modal.css";


function SaveAlertModal(props) {

     let { showSaveAlertPopup, closeSaveAlertPopup,userEmail } = props;
     let [submit, setSubmit] = useState(false);
     let [Loading, setLoading] = useState(false);
     let [showConfirmPopup ,setShowConfirmPopup]  = useState(false);
     console.log("props.indexObject",props.indexObject)

     const validator = new FormValidator([
       {
         field: 'name',
         method: 'isEmpty',
         validWhen: false,
         message: 'File Name is required.'
       }
     ]);

     let [state, setState] = useState({
         file : {
            name:'',
            validation: validator.valid()
          }
       }
     );

     const openConfirmPopup = () => {
       setShowConfirmPopup(showConfirmPopup=true);
     };

     const closeConfirmPopup = () => {
       setShowConfirmPopup(showConfirmPopup=false);
     };

     const onConfirmModalCloseAfterApi = (response) => {
         if(response === "true"){
           let chartSettings = {
                               'chart_version':'chartjs',
                               'chart_type':props.chartObject.chart_type,
                               'canvas_width':props.chartBaseObject.canvasWidth,
                               'canvas_height': props.chartBaseObject.canvasHeight,
                               'background_color': props.chartBaseObject.background_color,
                               'barFillColor':props.barColorObject.fillColor,
                               'barStrokeColor':props.barColorObject.strokeColor,
                               'title':props.chartTextObject.title,
                               'titleColor':props.chartTextObject.titleColor,
                               'subTitle':props.chartTextObject.subtitle,
                               'subTitleColor':props.chartTextObject.subTitleColor,
                               'fontFamily':props.chartTextObject.fontFamily,
                               'legendDisplay':props.chartLegendObject.display,
                               'legendPosition':props.chartLegendObject.position,
                               'legendFontColor':props.chartLegendObject.fontColor,
                               'legendLeftPosition':props.chartLegendObject.leftPosition,
                               'legendTopPosition':props.chartLegendObject.topPosition,
                               'legendOrientation':props.chartLegendObject.orientation,
                               'legendFontColor':props.chartLegendObject.fontColor,
                               'lineFillColor':props.lineColorObject.fillColor,
                               'lineStrokeColor':props.lineColorObject.strokeColor,
                               'linePointStrokeColor':props.lineColorObject.pointStrokeColor,
                               'linePointColor':props.lineColorObject.pointsColor,
                               'lineCheckFillColor':props.lineColorObject.checkFillColor,
                               'linePointRadius':props.lineColorObject.pointRadius,
                               'linePointStyle':props.lineColorObject.pointStyle,
                              };
           let chartName = state.file.name;
           savingChartReplaceSettings(userEmail,chartSettings,chartName);
         }else{
           console.log("don't replace");
           closeSaveAlertPopup();
         }
     };

     function handleChange(e)
      {
         e.preventDefault();
          const { name, value } = e.target;
          const { file } = state;
              setState({
                file: {
                  ...file,
                  [name]: value
              }
            })
      };

     let validation = validator.validate(state.file);

      async function handleSubmit(e)
      {
        e.preventDefault();
        validation = validator.validate(state.file);

        setSubmit(submit=true);

        if(validation.isValid)
        {
          let chartSettings = {
                                'chart_version':'chartjs',
                               'chart_type':props.chartObject.chart_type,
                               'canvas_width':props.chartBaseObject.canvasWidth,
                               'canvas_height': props.chartBaseObject.canvasHeight,
                               'background_color': props.chartBaseObject.background_color,
                               'barFillColor':props.barColorObject.fillColor,
                               'barStrokeColor':props.barColorObject.strokeColor,
                               'title':props.chartTextObject.title,
                               'titleColor':props.chartTextObject.titleColor,
                               'subTitle':props.chartTextObject.subtitle,
                               'subTitleColor':props.chartTextObject.subTitleColor,
                               'fontFamily':props.chartTextObject.fontFamily,
                               'legendDisplay':props.chartLegendObject.display,
                               'legendPosition':props.chartLegendObject.position,
                               'legendFontColor':props.chartLegendObject.fontColor,
                               'legendLeftPosition':props.chartLegendObject.leftPosition,
                               'legendTopPosition':props.chartLegendObject.topPosition,
                               'legendOrientation':props.chartLegendObject.orientation,
                               'legendFontColor':props.chartLegendObject.fontColor,
                               'lineFillColor':props.lineColorObject.fillColor,
                               'lineStrokeColor':props.lineColorObject.strokeColor,
                               'linePointStrokeColor':props.lineColorObject.pointStrokeColor,
                               'linePointColor':props.lineColorObject.pointsColor,
                               'lineCheckFillColor':props.lineColorObject.checkFillColor,
                               'linePointRadius':props.lineColorObject.pointRadius,
                               'linePointStyle':props.lineColorObject.pointStyle,
                             };
          let chartName = state.file.name;
           if(props.chartSettingsObject.settingApplied){
              savingChartReplaceSettings(userEmail,chartSettings,chartName);
           }else{
             savingChartSettings(userEmail,chartSettings,chartName)
           }
        }
      };


      async function savingChartSettings(userEmail,chartSettings,chartName)
      {
        console.log("props.indexObject",props.indexObject)
        setLoading(true);
        console.log('userEmail',userEmail ,'chartSettings',chartSettings,'chartName',chartName,'indexObject',props.indexObject,'reportObject',props.reportObject)
        axios.post(API_SERVER +`s3-save-chart-settings`,{'userEmail' :userEmail ,'chartSettings':chartSettings,'chartName':chartName,'indexObject':props.indexObject,'reportObject':props.reportObject})
          .then(function (response) {
            if(response.status === 200)
            {
              if(response.data.result === "forbid")
              {
                closeSaveAlertPopup();

                store.addNotification({
                   title: "Error",
                   message: response.data.message,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "zoomIn"],
                   animationOut: ["animated", "zoomOut"],
                   dismiss: {
                     duration: 3000,
                     onScreen: true ,
                     showIcon :true
                   }
                });
              }
              else if(response.data.result === "duplicate")
              {
                setShowConfirmPopup(showConfirmPopup=true);
              }
              else
              {
                store.addNotification({
                   title: "Done!",
                   message: response.data.message,
                   type: "success",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "zoomIn"],
                   animationOut: ["animated", "zoomOut"],
                   dismiss: {
                     duration: 3000,
                     onScreen: true ,
                     showIcon :true
                   }
                });
               props.updateEChartSettingObject({settingApplied:true,fileName:props.reportObject.value,settingConfig:chartSettings,fileLabel:chartName});
               props.onSaveAlertPopupCloseAfterApi("saved");
               closeSaveAlertPopup();
              }
            }
            else
            {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            }
          })
          .catch(function (error) {
            store.addNotification({
              title: "Error",
              message: "Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                duration: 3000,
                onScreen: true ,
                showIcon :true
              }
             });
          })
          .then(function () {
            setLoading(false);
          });
      };

      async function savingChartReplaceSettings(userEmail,chartSettings,chartName)
      {
        setLoading(true);
          axios.post(API_SERVER +`s3-save-chart-settings-old`,{'userEmail' :userEmail ,'chartSettings':chartSettings,'chartName':chartName,'indexObject':props.indexObject,'reportObject':props.reportObject})
          .then(function (response) {
            if(response.status === 200)
            {
              store.addNotification({
                 title: "Done!",
                 message: response.data.message,
                 type: "success",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "zoomIn"],
                 animationOut: ["animated", "zoomOut"],
                 dismiss: {
                   duration: 3000,
                   onScreen: true ,
                   showIcon :true
                 }
              });
              props.updateEChartSettingObject({settingApplied:true,fileName:props.reportObject.value,settingConfig:chartSettings,fileLabel:chartName});
            }
            else
            {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            }
          })
          .catch(function (error) {
            store.addNotification({
              title: "Error",
              message: "Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                duration: 3000,
                onScreen: true ,
                showIcon :true
              }
             });
          })
          .then(function () {
            setLoading(false);
            props.onSaveAlertPopupCloseAfterApi("saved");
            closeSaveAlertPopup();
          });
      };


       async function resetChartSettings()
        {
          props.resetEChartSettingObject();
          props.resetEChartTextObject();
          props.resetEChartBaseObject();
          props.resetEChartTypeObject();
          props.resetEChartLegendObject();
          props.resetELineColorObject();
          props.resetEBarColorObject();
          props.resetEChartEventObject();
          props.resetEChartDataObject();
        };


      useEffect(() => {
        
        if(props.showSaveAlertPopup)
        {
          let { file } = state;
              setState({
                file: {
                  ...file,
                  name: props.chartSettingsObject.settingApplied? props.chartSettingsObject.fileLabel: props.chartTextObject.title
              }
            })
        }
      },[props.showSaveAlertPopup]);

  return (
   <React.Fragment>
    <Popup open={showSaveAlertPopup} onClose={closeSaveAlertPopup} nested>
      <ConfirmationAlertModal
            showConfirmPopup={showConfirmPopup}
            closeConfirmPopup={closeConfirmPopup}
            onConfirmModalCloseAfterApi={onConfirmModalCloseAfterApi}
            confirmPopupMessage={"File with that name already exits..do you want to replace it ?"}
          />
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
                {props.chartSettingsObject.settingApplied ?
                    <p className="modal-card-title">Update Setting As</p>
                  :
                    <p className="modal-card-title">Save As</p>
                 }
              <button className="deleteBtn" onClick={props.closeSaveAlertPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body save-modl-popup">
              <div className="container">
                    <form id="Login">
                      <div className="row">
                          <div className="col-md-12 col-lg-12">
                              <input className="input"
                                  type="text"
                                  placeholder="Enter File Name"
                                  name="name"
                                  value={state.file.name}
                                  onChange={handleChange}
                                  disabled ={props.chartSettingsObject.settingApplied}
                                  />
                                <p className="help is-danger">{ submit ? validation.name.message : ''}</p>
                          </div>
                        <div className="col-md-12 col-lg-12 save-btn-row">
                          {props.chartSettingsObject.settingApplied ?
                            <button type="button" onClick={handleSubmit} className="btn yellow-btn">Update</button>
                            :
                            <button type="button" onClick={handleSubmit} className="btn yellow-btn">Save</button>
                           }
                        </div>
                      </div>
                    </form>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
            {Loading  &&
             <Loader />
          }
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}


const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartEventObject = reducerObj.eChartEventReducers.eventList;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const lineColorObject = reducerObj.eLineColorReducers.LineColor;
  const chartSettingsObject  = reducerObj.eChartSettingsReducers.chartSettingsObject;
  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,
    chartTextObject,chartEventObject,chartLegendObject , lineColorObject,chartSettingsObject,indexObject,reportObject };
};

export default compose(
  connect(mapStateToProps, {
    updateEChartTypeObject,resetEChartTypeObject,
    updateEChartDataObject,resetEChartDataObject,
    updateEChartBaseObject,resetEChartBaseObject,
    updateEChartTextObject,resetEChartTextObject ,
    updateEChartLegendObject,resetEChartLegendObject,
    updateELineColorObject,resetELineColorObject,
    updateEBarColorObject,resetEBarColorObject,
    updateEChartEventObject,resetEChartEventObject,
    updateEChartSettingObject,resetEChartSettingObject,
    updateIndexObject,updateReportObject
  }),
)(SaveAlertModal);
