export const UPDATE_INDEX = "update-index";
export const RESET_INDEX = "reset-index";

export const updateIndexObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetIndexObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_INDEX,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_INDEX
  };
};
