export const UPDATE_LINE_COLOR = "update-line-color";
export const RESET_LINE_COLOR = "reset-line-color";

export const updateLineColorObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetLineColorObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_LINE_COLOR,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_LINE_COLOR
  };
};
