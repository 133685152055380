import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { SketchPicker } from 'react-color';
import FontPicker from "font-picker-react";
import reactCSS from 'reactcss';

import {FONT_API_KEY }  from '../../constant';
import { updateEChartTextObject } from "../../../actions/EChartAction/EChartTextAction";

function TextBase(props) {

  let [chartTitle,setChartTitle] = useState(props.chartTextObject.title);
  let [chartSubTitle,setChartSubTitle] = useState(props.chartTextObject.subtitle);

  let [displayTitleColorPicker,setDisplayTitleColorPicker] = useState(false);
  let [titleColor,setTitleColor] = useState(props.chartTextObject.titleColor);

  let [displaySubTitleColorPicker,setDisplaySubTitleColorPicker] = useState(false);
  let [subTitleColor,setSubTitleColor] =  useState(props.chartTextObject.subTitleColor);

  let [activeFontFamily,setActiveFontFamily] = useState(props.chartTextObject.fontFamily);

  const styles = reactCSS({
       'default': {
         color: {
           width: '21px',
           height: '21px',
           borderRadius: '36px'
         },
         swatch: {
           padding: '0px',
           background: 'rgb(255, 255, 255)',
           borderRadius: '12px',
           boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
           display: 'inline-block',
           cursor: 'pointer',
         },
         popover: {
           position: 'absolute',
           zIndex: '2',
         },
         cover: {
           position: 'fixed',
           top: '0px',
           right: '0px',
           bottom: '0px',
           left: '0px',
         },
       },
     });

     function handleColorPickerStyle(type)
     {
       if(type === "title")
        {
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background:props.chartTextObject.titleColor
              }
            },
          });
        }else{
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: props.chartTextObject.subTitleColor
              }
            },
          });
        }
     }

     function handleColorClick (e,type)
      {
         if(type === "title")
        {
          setDisplayTitleColorPicker( displayTitleColorPicker = !displayTitleColorPicker );

        }else {
          setDisplaySubTitleColorPicker( displaySubTitleColorPicker = !displaySubTitleColorPicker );
        }

      };

      function handleColorClose (e,type)
      {
        if(type === "title")
        {
          setDisplayTitleColorPicker(displayTitleColorPicker = false)

        }else {
          setDisplaySubTitleColorPicker(displaySubTitleColorPicker = false)
        }
      };

      function handleColorChange(color,type)
      {
        if(type === "title")
        {
            setTitleColor( titleColor = color.hex);
            props.updateEChartTextObject({titleColor:color.hex});
        }else if(type === "subtitle"){
            setSubTitleColor( subTitleColor = color.hex);
            props.updateEChartTextObject({subTitleColor:color.hex});
        }else{

        }
      };

  function handleChangeTitleChnage(e)
  {
    setChartTitle(chartTitle=e.target.value)
    props.updateEChartTextObject({title : e.target.value})
  };

  function handleChangeSubtitleChnage(e)
  {
    setChartSubTitle(chartSubTitle=e.target.value)
    props.updateEChartTextObject({subtitle : e.target.value})
  };


  function handleChangeFontChange(nextFont)
  {
    setActiveFontFamily(activeFontFamily=nextFont.family);
    props.updateEChartTextObject({fontFamily:nextFont.family});
  };

  return (
   <React.Fragment>
     <ul className="nested" id="text">
        <li>
            <table>
              <tbody>
              <tr>
                  <td>
                     Title
                  </td>
                  <td>
                    <input className="input"
                          type="text"
                          name="title"
                          value={chartTitle}
                          onChange={handleChangeTitleChnage}
                          />
                  </td>
              </tr>
              <tr>
                  <td>
                     Title Color
                  </td>
                  <td>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "title")}}>
                            <div style={ handleColorPickerStyle("title").color  } />
                          </div>
                          { displayTitleColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "title")}} />
                            <SketchPicker color={ props.chartTextObject.titleColor } onChange={ (e) => { handleColorChange(e, "title")}} />
                          </div> : null }
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                  Subtitle
                  </td>
                  <td>
                    <input className="input"
                          type="text"
                          name="subtitle"
                          value={chartSubTitle}
                          onChange={handleChangeSubtitleChnage}
                          />
                  </td>
              </tr>
              <tr>
                  <td>
                  Subtitle Color
                  </td>
                  <td>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "subtitle")}}>
                            <div style={ handleColorPickerStyle("fill").color  } />
                          </div>
                          { displaySubTitleColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "subtitle")}} />
                            <SketchPicker color={ props.chartTextObject.subTitleColor } onChange={ (e) => { handleColorChange(e, "subtitle")}} />
                          </div> : null }
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                     Font
                  </td>
                  <td>
                    <FontPicker
              					apiKey={FONT_API_KEY}
              					activeFontFamily={props.chartTextObject.fontFamily}
              					onChange={handleChangeFontChange}
              				/>
                  </td>
              </tr>
              </tbody>
            </table>
        </li>
      </ul>
   </React.Fragment>
  );
}




const mapStateToProps = reducerObj => {
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,chartTextObject};
};

export default compose(
  connect(mapStateToProps, {
    updateEChartTextObject
  }),
)(TextBase);
