import React, { Component } from "react";
import file from "../../assets/help-file/View_Reports_Help.pdf";
import { setOptions, Document, Page , pdfjs} from "react-pdf";
const pdfjsVersion = "2.0.305";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { Document, Page } from "react-pdf/build/entry.noworker";

export default class FileViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <Document
           file={file}
          onLoadSuccess={this.onDocumentLoadSuccess}
          width={300}
          height={300}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    );
  }
}
