import { UPDATE_CHART_LEGEND , RESET_CHART_LEGEND } from "../../actions/ChartAction/ChartLegendAction";

export const CHART_LEGEND_INITIAL_STATE = {
  legendData: {
    display: true,
    position: "top",
    fullWidth: true,
    reverse: false,
    fontColor:{"r":34,"g": 25,"b": 77,"a": 100}
  }
};

export default (state = CHART_LEGEND_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_LEGEND:
      return {
        ...state,
        legendData: Object.assign({}, state.legendData, action.payload)
      };
      case RESET_CHART_LEGEND:
        return CHART_LEGEND_INITIAL_STATE
    default:
      return state;
  }
};
