import { UPDATE_COLLECTION_PARAMS , RESET_COLLECTION_PARAMS } from "../actions/CollectionAction";

export const COLLECTION_PARAMS_INITIAL_STATE = {
  collectionObject: {
    loading:false,
    zipPath:null,
    zipFileName:null,
    firstJsonFile:null,
    secondJsonFile:null,
    currentIndex:0,
    totalFileIndex:0,
    hasListEnded :false
  }
};

export default (state = COLLECTION_PARAMS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COLLECTION_PARAMS:
      return {
        ...state,
        collectionObject: Object.assign({}, state.collectionObject, action.payload)
      };
      case RESET_COLLECTION_PARAMS:
        return COLLECTION_PARAMS_INITIAL_STATE
    default:
      return state;
  }
};
