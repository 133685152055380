import { UPDATE_DASHBOARD_CHART_LEGEND , RESET_DASHBOARD_CHART_LEGEND } from "../../actions/DashboardAction/ChartLegendAction";

export const DASHBOARD_CHART_LEGEND_INITIAL_STATE = {
  legendData: {
    display: true,
    position: "bottom",
    fullWidth: true,
    reverse: false,
    fontColor:{"r":34,"g": 25,"b": 77,"a": 100},
    leftPosition:"center",
    topPosition:"bottom",
    orientation:"vertical",

    display_first: true,
    position_first: "bottom",
    fullWidth_first: true,
    reverse_first: false,
    fontColor_first:{"r":34,"g": 25,"b": 77,"a": 100},
    leftPosition_first:"center",
    topPosition_first:"bottom",
    orientation_first:"vertical",

    display_second: true,
    position_second: "bottom",
    fullWidth_second: true,
    reverse_second: false,
    fontColor_second:{"r":34,"g": 25,"b": 77,"a": 100},
    leftPosition_second:"center",
    topPosition_second:"bottom",
    orientation_second:"vertical",

    display_third: true,
    position_third: "bottom",
    fullWidth_third: true,
    reverse_third: false,
    fontColor_third:{"r":34,"g": 25,"b": 77,"a": 100},
    leftPosition_third:"center",
    topPosition_third:"bottom",
    orientation_third:"vertical",
  }
};

export default (state = DASHBOARD_CHART_LEGEND_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_LEGEND:
      return {
        ...state,
        legendData: Object.assign({}, state.legendData, action.payload)
      };
      case UPDATE_DASHBOARD_CHART_LEGEND:
        return DASHBOARD_CHART_LEGEND_INITIAL_STATE
    default:
      return state;
  }
};
