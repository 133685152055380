export const UPDATE_ECHART_TEXT = "update-echart-text";
export const RESET_ECHART_TEXT = "reset-echart-text";

export const updateEChartTextObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEChartTextObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ECHART_TEXT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ECHART_TEXT
  };
};
