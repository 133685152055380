import React, { useState }  from "react";
import Popup from "reactjs-popup";

import "./modal.css";


function PrintModal(props) {

     let { showPrintPopup, closePrintPopup } = props;
     let [downloadType,setDownloadType] = useState("PDF");

     function handleDownloadTypeChange(e)
     {
        setDownloadType(downloadType=e.target.value);
     };

      async function handleSubmit(e)
      {
         e.preventDefault();
        props.onPrintModalCloseAfterApi(downloadType);
        closePrintPopup();
      };


  return (
   <React.Fragment>
    <Popup open={showPrintPopup} onClose={closePrintPopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Download Report As</p>
              <button className="deleteBtn" onClick={props.closePrintPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
              <div className="container">
                  <div className="row col-sm-12 col-md-10 col-lg-8">
                      <div className="col-md-4 col-lg-5">
                          <div className="styled-select relativePosition">
                              <select value={downloadType} onChange={handleDownloadTypeChange} className="form-control" id="exampleFormControlSelect1">
                                <option value="PDF">PDF</option>
                                <option value="JPEG">JPEG</option>
                              </select>
                              <div className="Hideselect"></div>
                          </div>
                      </div>
                      <div className="col-md-4 col-lg-5">
                            <button type="button"  className="btn yellow-btn" onClick={(e) => handleSubmit(e)} >Download</button>
                      </div>
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



export default PrintModal
