import { UPDATE_CHART_TYPE , RESET_CHART_TYPE} from "../../actions/ChartAction/ChartTypeAction";

export const CHART_INITIAL_STATE = {
  chartType: {
    chart_type : 'Bar'
  }
};

export default (state = CHART_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_TYPE:
      return {
        ...state,
        chartType: Object.assign({}, state.chartType, action.payload)
      };
      case RESET_CHART_TYPE:
        return CHART_INITIAL_STATE
    default:
      return state;
  }
};
