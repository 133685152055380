import { UPDATE_DASHBOARD_BAR_COLOR , RESET_DASHBOARD_BAR_COLOR} from "../../actions/DashboardAction/BarColorAction";

export const DASHBOARD_BAR_COLOR_INITIAL_STATE = {
  barColor: {
    fillColor:{"r": 188,"g": 225,"b": 147,"a": 0.2},
    strokeColor:{"r": 86,"g": 133,"b": 30,"a": 1},

    fillColor_first:{"r": 188,"g": 225,"b": 147,"a": 0.2},
    strokeColor_first:{"r": 86,"g": 133,"b": 30,"a": 1},
    fillColor_second:{"r": 188,"g": 225,"b": 147,"a": 0.2},
    strokeColor_second:{"r": 86,"g": 133,"b": 30,"a": 1},
    fillColor_third:{"r": 188,"g": 225,"b": 147,"a": 0.2},
    strokeColor_third:{"r": 86,"g": 133,"b": 30,"a": 1},
  }
};

export default (state = DASHBOARD_BAR_COLOR_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_BAR_COLOR:
      return {
        ...state,
        barColor: Object.assign({}, state.barColor, action.payload)
      };
      case RESET_DASHBOARD_BAR_COLOR:
        return DASHBOARD_BAR_COLOR_INITIAL_STATE
    default:
      return state;
  }
};
