import { UPDATE_ECHART_LEGEND , RESET_ECHART_LEGEND } from "../../actions/EChartAction/EChartLegendAction";

export const ECHART_LEGEND_INITIAL_STATE = {
  legendData: {
    display: true,
    position: "bottom",
    fontColor:{"r":34,"g": 25,"b": 77,"a": 100},
    leftPosition:"center",
    topPosition:"bottom",
    orientation:"vertical",
  }
};

export default (state = ECHART_LEGEND_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_LEGEND:
      return {
        ...state,
        legendData: Object.assign({}, state.legendData, action.payload)
      };
      case RESET_ECHART_LEGEND:
        return ECHART_LEGEND_INITIAL_STATE
    default:
      return state;
  }
};
