/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:8babc2f0-8b4e-4187-90e5-86d88bb12c59",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_wLPbjlvCv",
    "aws_user_pools_web_client_id": "3r1b345riifgtn5a6ius8ovt04",
    "oauth": {}
};


export default awsmobile;
