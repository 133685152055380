export const UPDATE_THEME = "update-theme";
export const RESET_THEME = "reset-theme";

export const updateThemeObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetThemeObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_THEME,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_THEME
  };
};
