export const UPDATE_PIVOT_DATA = "update-pivot-data";
export const RESET_PIVOT_DATA = "reset-pivot-data";

export const updatePivotDataObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetPivotDataObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_PIVOT_DATA,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_PIVOT_DATA
  };
};
