export const UPDATE_TOOLTIP = "update-tool-tip";
export const RESET_TOOLTIP = "reset-tool-tip";

export const updateToolTipObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetToolTipObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_TOOLTIP,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_TOOLTIP
  };
};
