export const UPDATE_PIVOT_TEXT = "update-pivot-text";
export const RESET_PIVOT_TEXT = "reset-pivot-text";

export const updatePivotTextObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetPivotTextObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_PIVOT_TEXT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_PIVOT_TEXT
  };
};
