import React, { Component } from 'react';

import { Route, Switch, Router, Redirect } from 'react-router-dom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './App.css';

import { AmplifyAuthenticator, withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

import { Auth } from 'aws-amplify';

import '@aws-amplify/ui/dist/style.css';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { UserPrivateRoute } from './components/common/PrivateRoutes/PrivateRoutes';

import { history } from './components/common/history/history';

import GetDefaultChart from './components/GetChart/getDefaultChart';

import GetUserChart from './components/GetChart/getUserChart';

import HomeWithTab from './components/HomeWithTab/homeWithTab';

import MainPage from './components/MainTab/MainPage';

import Profile from './components/Profile/MainPage';

import Presentation from './components/PresentationTwo/MainPage';
import ReactGA from 'react-ga';
const TRACKING_ID = 'G-PWQV4NQEGN'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID); // end YOUR_OWN_TRACKING_ID
ReactGA.pageview('/');

export default class App extends Component {
    render() {
        return (
            <div className='App'>
                <ReactNotification />
                <Router history={history}>
                    <Switch>
                        <Route path={'/'} exact component={MainPage} />
                        <UserPrivateRoute
                            path={'/presentation'}
                            exact
                            component={Presentation}
                        />
                        <UserPrivateRoute
                            path={'/home'}
                            exact
                            component={HomeWithTab}
                        />
                        <UserPrivateRoute
                            path={'/profile'}
                            exact
                            component={Profile}
                        />
                        <UserPrivateRoute
                            path={'/get-default-chart/:email/:zip/:file'}
                            exact
                            component={GetDefaultChart}
                        />
                        <UserPrivateRoute
                            path={'/get-user-chart/:email/:zip/:file'}
                            exact
                            component={GetUserChart}
                        />
                        <Route path='*'>
                            <NoMatch />
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

function NoMatch() {
    return <Redirect to='/' />;
}
