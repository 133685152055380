import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import echarts from 'echarts';
import ReactEcharts from "echarts-for-react";


const BarChart = ( props ) => {

  function onChartClick(e)
   {
     if(e.componentType === "title")
     {

     }else if(e.componentType === "series")
     {


     }else if(e.componentType === "series11")
     {

     }else{

     }
   }

   function onChartLegendselectchanged()
    {
      
    }

   let _onEvents = {
   'click': onChartClick,
  }


    function bar_first()
    {
      return(
        <ReactEcharts
            option={{
              title: {
                text: props.chartTextObject.title_first,
                textStyle: {
                   color: props.chartTextObject.titleColor_first,
                   fontFamily:props.chartTextObject.fontFamily_first
                 },
                subtext: props.chartTextObject.subtitle_first,
                subtextStyle: {
                   color: props.chartTextObject.subTitleColor_first,
                   fontFamily:props.chartTextObject.fontFamily_first
                 },
                left: 'center',
                triggerEvent: true
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              grid: { containLabel: true },
              legend: {
                data: [props.chartDataObject.chart_xaxis_label_first],
                show:props.chartLegendObject.display_first,
                orient:props.chartLegendObject.orientation_first,
                left:props.chartLegendObject.leftPosition_first,
                top:props.chartLegendObject.topPosition_first,
                textStyle:{
                   fontFamily:props.chartTextObject.fontFamily_first,
                   color:`rgba(${ props.chartLegendObject.fontColor_first.r }, ${ props.chartLegendObject.fontColor_first.g }, ${ props.chartLegendObject.fontColor_first.b }, ${ props.chartLegendObject.fontColor_first.a })`,
                },
              },
              xAxis: {
                type: "category",
                data: props.chartDataObject.chart_labels_first
              },
              yAxis: {
                type: "value"
              },
              series: [{
                 name: props.chartDataObject.chart_xaxis_label_first,
                data: props.chartDataObject.chart_data_first,
                type: "bar",
                itemStyle: {
                   color: `rgba(${ props.barColorObject.strokeColor_first.r }, ${ props.barColorObject.strokeColor_first.g }, ${ props.barColorObject.strokeColor_first.b }, ${ props.barColorObject.strokeColor_first.a })`
                 }
              }]
            }}
            style={{height: '500px', width: '100%'}}
            onEvents={_onEvents}
            className='echarts-for-echarts'
          />
       )
    };

    function bar_second()
    {
      return(
        <ReactEcharts
            option={{
              title: {
                text: props.chartTextObject.title_second,
                textStyle: {
                   color: props.chartTextObject.titleColor_second,
                   fontFamily:props.chartTextObject.fontFamily_second
                 },
                subtext: props.chartTextObject.subtitle_second,
                subtextStyle: {
                   color: props.chartTextObject.subTitleColor_second,
                   fontFamily:props.chartTextObject.fontFamily_second
                 },
                left: 'center',
                triggerEvent: true
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              grid: { containLabel: true },
              legend: {
                data: [props.chartDataObject.chart_xaxis_label_second],
                show:props.chartLegendObject.display_second,
                orient:props.chartLegendObject.orientation_second,
                left:props.chartLegendObject.leftPosition_second,
                top:props.chartLegendObject.topPosition_second,
                textStyle:{
                   fontFamily:props.chartTextObject.fontFamily_second,
                   color:`rgba(${ props.chartLegendObject.fontColor_second.r }, ${ props.chartLegendObject.fontColor_second.g }, ${ props.chartLegendObject.fontColor_second.b }, ${ props.chartLegendObject.fontColor_second.a })`,
                },
              },
              xAxis: {
                type: "category",
                data: props.chartDataObject.chart_labels_second
              },
              yAxis: {
                type: "value"
              },
              series: [{
                 name: props.chartDataObject.chart_xaxis_label_second,
                data: props.chartDataObject.chart_data_second,
                type: "bar",
                itemStyle: {
                   color: `rgba(${ props.barColorObject.strokeColor_second.r }, ${ props.barColorObject.strokeColor_second.g }, ${ props.barColorObject.strokeColor_second.b }, ${ props.barColorObject.strokeColor_second.a })`
                 }
              }]
            }}
            style={{height: '500px', width: '100%'}}
            onEvents={_onEvents}
            className='echarts-for-echarts'
          />
        )
      };

    function bar_third()
    {
      return(
        <ReactEcharts
            option={{
              title: {
                text: props.chartTextObject.title_third,
                textStyle: {
                   color: props.chartTextObject.titleColor_third,
                   fontFamily:props.chartTextObject.fontFamily_third
                 },
                subtext: props.chartTextObject.subtitle_third,
                subtextStyle: {
                   color: props.chartTextObject.subTitleColor_third,
                   fontFamily:props.chartTextObject.fontFamily_third
                 },
                left: 'center',
                triggerEvent: true
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              grid: { containLabel: true },
              legend: {
                data: [props.chartDataObject.chart_xaxis_label_third],
                show:props.chartLegendObject.display_third,
                orient:props.chartLegendObject.orientation_third,
                left:props.chartLegendObject.leftPosition_third,
                top:props.chartLegendObject.topPosition_third,
                textStyle:{
                   fontFamily:props.chartTextObject.fontFamily_third,
                   color:`rgba(${ props.chartLegendObject.fontColor_third.r }, ${ props.chartLegendObject.fontColor_third.g }, ${ props.chartLegendObject.fontColor_third.b }, ${ props.chartLegendObject.fontColor_third.a })`,
                },
              },
              xAxis: {
                type: "category",
                data: props.chartDataObject.chart_labels_third
              },
              yAxis: {
                type: "value"
              },
              series: [{
                 name: props.chartDataObject.chart_xaxis_label_third,
                data: props.chartDataObject.chart_data_third,
                type: "bar",
                itemStyle: {
                   color: `rgba(${ props.barColorObject.strokeColor_third.r }, ${ props.barColorObject.strokeColor_third.g }, ${ props.barColorObject.strokeColor_third.b }, ${ props.barColorObject.strokeColor_third.a })`
                 }
              }]
            }}
            style={{height: '500px', width: '100%'}}
            onEvents={_onEvents}
            className='echarts-for-echarts'
          />
       )
    };


function render_this() {
  let component = null;

    switch(props.chartInnerIndex) {
      case "first":
          component = bar_first();
        break;
      case "second":
          component = bar_second();
        break;
        case "third":
          component = bar_third();
        break;
      default:
       component = null;
    }
    return component;
  }


return (
       <React.Fragment>
         { render_this() }
      </React.Fragment>
    )
};


const mapStateToProps = reducerObj => {
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartSettingsObject  = reducerObj.DashchartSettingsReducers.chartSettingsObject;

  return { chartDataObject ,chartBaseObject , barColorObject,
    chartTextObject,chartLegendObject , lineColorObject,chartSettingsObject };
};

export default compose(
  connect(mapStateToProps, {
  }),
)(BarChart);
