import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

const DonoughtChart = (props) => {
  function onChartClick(e) {
    if (e.componentType === 'title') {
    } else if (e.componentType === 'series') {
    } else if (e.componentType === 'series11') {
    } else {
    }
  }

  function onChartLegendselectchanged() {}

  let _onEvents = {
    click: onChartClick,
    legendselectchanged: onChartLegendselectchanged,
  };

  function donought_first() {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title_first,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_first,
              color: props.chartTextObject.titleColor_first,
            },
            subtext: props.chartTextObject.subtitle_first,
            subtextStyle: {
              fontFamily: props.chartTextObject.fontFamily_first,
              color: props.chartTextObject.subTitleColor_first,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_first,
            orient: props.chartLegendObject.orientation_first,
            left: props.chartLegendObject.leftPosition_first,
            top: props.chartLegendObject.topPosition_first,
            data: props.chartDataObject.chart_labels_first,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_first,
              color: `rgba(${props.chartLegendObject.fontColor_first.r}, ${props.chartLegendObject.fontColor_first.g}, ${props.chartLegendObject.fontColor_first.b}, ${props.chartLegendObject.fontColor_first.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: props.chartDataObject.chart_data_first?.map((value, i) => ({
                name: props.chartDataObject.chart_labels_first[i],
                value,
              })),
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function donought_second() {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title_second,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_second,
              color: props.chartTextObject.titleColor_second,
            },
            subtext: props.chartTextObject.subtitle_second,
            subtextStyle: {
              fontFamily: props.chartTextObject.fontFamily_second,
              color: props.chartTextObject.subTitleColor_second,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_second,
            orient: props.chartLegendObject.orientation_second,
            left: props.chartLegendObject.leftPosition_second,
            top: props.chartLegendObject.topPosition_second,
            data: props.chartDataObject.chart_labels_second,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_second,
              color: `rgba(${props.chartLegendObject.fontColor_second.r}, ${props.chartLegendObject.fontColor_second.g}, ${props.chartLegendObject.fontColor_second.b}, ${props.chartLegendObject.fontColor_second.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: props.chartDataObject.chart_data_second?.map(
                (value, i) => ({
                  name: props.chartDataObject.chart_labels_second[i],
                  value,
                })
              ),
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function donought_third() {
    return (
      <ReactEcharts
        option={{
          title: {
            text: props.chartTextObject.title_third,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_third,
              color: props.chartTextObject.titleColor_third,
            },
            subtext: props.chartTextObject.subtitle_third,
            subtextStyle: {
              fontFamily: props.chartTextObject.fontFamily_third,
              color: props.chartTextObject.subTitleColor_third,
            },
            left: 'center',
            triggerEvent: true,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          grid: { containLabel: true },
          legend: {
            show: props.chartLegendObject.display_third,
            orient: props.chartLegendObject.orientation_third,
            left: props.chartLegendObject.leftPosition_third,
            top: props.chartLegendObject.topPosition_third,
            data: props.chartDataObject.chart_labels_third,
            textStyle: {
              fontFamily: props.chartTextObject.fontFamily_third,
              color: `rgba(${props.chartLegendObject.fontColor_third.r}, ${props.chartLegendObject.fontColor_third.g}, ${props.chartLegendObject.fontColor_third.b}, ${props.chartLegendObject.fontColor_third.a})`,
            },
            triggerEvent: true,
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              data: props.chartDataObject.chart_data_third?.map((value, i) => ({
                name: props.chartDataObject.chart_labels_third[i],
                value,
              })),
            },
          ],
        }}
        style={{ height: '500px', width: '100%' }}
        onEvents={_onEvents}
        className='echarts-for-echarts'
      />
    );
  }

  function render_this() {
    let component = null;

    switch (props.chartInnerIndex) {
      case 'first':
        component = donought_first();
        break;
      case 'second':
        component = donought_second();
        break;
      case 'third':
        component = donought_third();
        break;
      default:
        component = null;
    }
    return component;
  }

  return <React.Fragment>{render_this()}</React.Fragment>;
};

const mapStateToProps = (reducerObj) => {
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartSettingsObject =
    reducerObj.DashchartSettingsReducers.chartSettingsObject;

  return {
    chartDataObject,
    chartBaseObject,
    barColorObject,
    chartTextObject,
    chartLegendObject,
    lineColorObject,
    chartSettingsObject,
  };
};

export default compose(connect(mapStateToProps, {}))(DonoughtChart);
