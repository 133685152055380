export const UPDATE_BREAK_POINT = "update-break-point";
export const RESET_BREAK_POINT = "reset-break-point";

export const updateBreakPointObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetBreakPointObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_BREAK_POINT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_BREAK_POINT
  };
};
