import { UPDATE_PIVOT_DATA , RESET_PIVOT_DATA} from "../../actions/PivotAction/PivotDataAction";

export const PIVOT_DATA_INITIAL_STATE = {
  pivotData: {
    pivot_labels : [],
    pivot_data : [],
    pivot_colors : [],
    pivot_array : [],
    pivot_xaxis_label :'',
    pivot_yaxis_label :'',
  }
};

export default (state = PIVOT_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PIVOT_DATA:
      return {
        ...state,
        pivotData: Object.assign({}, state.pivotData, action.payload)
      };
      case RESET_PIVOT_DATA:
        return PIVOT_DATA_INITIAL_STATE
    default:
      return state;
  }
};
