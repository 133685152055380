import { UPDATE_DASHBOARD_LINE_COLOR , RESET_DASHBOARD_LINE_COLOR} from "../../actions/DashboardAction/LineColorAction";

export const DASHBOARD_LINE_COLOR_INITIAL_STATE = {
  LineColor: {
    fillColor:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    strokeColor:{"r": 86,"g": 133,"b": 30,"a": 1},
    pointStrokeColor :{"r":75,"g": 192,"b":192,"a": 1},
    pointsColor:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    checkFillColor:false,
    pointRadius:3,
    pointStyle:"circle",

    fillColor_first:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    strokeColor_first:{"r": 86,"g": 133,"b": 30,"a": 1},
    pointStrokeColor_first :{"r":75,"g": 192,"b":192,"a": 1},
    pointsColor_first:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    checkFillColor_first:false,
    pointRadius_first:3,
    pointStyle_first:"circle",

    fillColor_second:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    strokeColor_second:{"r": 86,"g": 133,"b": 30,"a": 1},
    pointStrokeColor_second :{"r":75,"g": 192,"b":192,"a": 1},
    pointsColor_second:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    checkFillColor_second:false,
    pointRadius_second:3,
    pointStyle_second:"circle",

    fillColor_third:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    strokeColor_third:{"r": 86,"g": 133,"b": 30,"a": 1},
    pointStrokeColor_third :{"r":75,"g": 192,"b":192,"a": 1},
    pointsColor_third:{"r": 247,"g": 255,"b": 224,"a": 0.2},
    checkFillColor_third:false,
    pointRadius_third:3,
    pointStyle_third:"circle"
  }
};

export default (state = DASHBOARD_LINE_COLOR_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_LINE_COLOR:
      return {
        ...state,
        LineColor: Object.assign({}, state.LineColor, action.payload)
      };
      case RESET_DASHBOARD_LINE_COLOR:
        return DASHBOARD_LINE_COLOR_INITIAL_STATE
    default:
      return state;
  }
};
