export const UPDATE_LANGUAGE = "update-language";
export const RESET_LANGUAGE = "reset-language";

export const updateLanguageObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetLanguageObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_LANGUAGE,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_LANGUAGE
  };
};
