export const UPDATE_ELINE_COLOR = "update-eline-color";
export const RESET_ELINE_COLOR = "reset-eline-color";

export const updateELineColorObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetELineColorObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ELINE_COLOR,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ELINE_COLOR
  };
};
