import { UPDATE_CHART_EVENT , RESET_CHART_EVENT} from "../../actions/ChartAction/ChartEventAction";

export const CHART_EVENT_INITIAL_STATE = {
  eventList: {
    legendClicked:false,
    titleClicked:false,
  }
};

export default (state = CHART_EVENT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_EVENT:
      return {
        ...state,
        eventList: Object.assign({}, state.eventList, action.payload)
      };
      case RESET_CHART_EVENT:
        return CHART_EVENT_INITIAL_STATE
    default:
      return state;
  }
};
