import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import echarts from 'echarts';
import ReactEcharts from "echarts-for-react";

const BarChart = ( props ) => {

  function ShowBarChart(type)
  {
    return(
      <ReactEcharts
          option={{
            title: {
              text: props.chartTextObject.title_second,
              textStyle: {
                 color: props.chartTextObject.titleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              subtext: props.chartTextObject.subtitle_second,
              subtextStyle: {
                 color: props.chartTextObject.subTitleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: { containLabel: true },
            legend: {
              data: [props.chartDataObject.chart_xaxis_label_second],
              show:props.chartLegendObject.display,
              orient:props.chartLegendObject.orientation,
              left:props.chartLegendObject.leftPosition,
              top:props.chartLegendObject.topPosition,
              textStyle:{
                 fontFamily:props.chartTextObject.fontFamily,
                 color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
              },
            },
            xAxis: {
              type: "category",
              data: props.chartDataObject.chart_labels_second
            },
            yAxis: {
              type: "value"
            },
            series: [{
               name: props.chartDataObject.chart_xaxis_label_second,
              data: props.chartDataObject.chart_data_second,
              type: "bar",
              itemStyle: {
                 color: `rgba(${ props.barColorObject.strokeColor.r }, ${ props.barColorObject.strokeColor.g }, ${ props.barColorObject.strokeColor.b }, ${ props.barColorObject.strokeColor.a })`
               }
            }]
          }}
          style={{height: '500px', width: '100%'}}
          className='echarts-for-echarts'
        />
     );
  };

  function ShowHorizonalBarChart(type)
  {
    return(
      <ReactEcharts
          option={{
            title: {
              text: props.chartTextObject.title_second,
              textStyle: {
                 color: props.chartTextObject.titleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              subtext: props.chartTextObject.subtitle_second,
              subtextStyle: {
                 color: props.chartTextObject.subTitleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: { containLabel: true },
            legend: {
              data: [props.chartDataObject.chart_xaxis_label_second],
              show:props.chartLegendObject.display,
              orient:props.chartLegendObject.orientation,
              left:props.chartLegendObject.leftPosition,
              top:props.chartLegendObject.topPosition,
              textStyle:{
                 fontFamily:props.chartTextObject.fontFamily,
                 color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
              },
            },
            xAxis: {
              type: "value",
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: "category",
              data: props.chartDataObject.chart_labels_second
            },
            series: [{
              name: props.chartDataObject.chart_xaxis_label_second,
              data: props.chartDataObject.chart_data_second,
              type: "bar",
              itemStyle: {
                color: `rgba(${ props.barColorObject.strokeColor.r }, ${ props.barColorObject.strokeColor.g }, ${ props.barColorObject.strokeColor.b }, ${ props.barColorObject.strokeColor.a })`
              }
            }]
          }}
          style={{height: '500px', width: '100%'}}
          className='echarts-for-echarts'
        />
    )
  };

  function ShowPieChart(type)
  {
    return(
      <ReactEcharts
          option={{
            title: {
              text: props.chartTextObject.title_second,
              textStyle: {
                 color: props.chartTextObject.titleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              subtext: props.chartTextObject.subtitle_second,
              subtextStyle: {
                 color: props.chartTextObject.subTitleColor,
                 fontFamily:props.chartTextObject.fontFamily
               },
              left: 'center',
            },
            tooltip : {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            grid: { containLabel: true },
            legend: {
              data: [props.chartDataObject.chart_xaxis_label_second],
              show:props.chartLegendObject.display,
              orient:props.chartLegendObject.orientation,
              left:props.chartLegendObject.leftPosition,
              top:props.chartLegendObject.topPosition,
              textStyle:{
                 fontFamily:props.chartTextObject.fontFamily,
                 color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
              },
            },
            series : [
                {
                    name: '',
                    type: 'pie',
                    radius : '55%',
                    center: ['50%', '60%'],
                    data: props.chartDataObject.chart_array_second,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0)'

                        }
                    }
                }
            ]
          }}
          style={{height: '500px', width: '100%'}}
          className='echarts-for-echarts'
        />
      )
  };

  function ShowLineChart(type)
  {
      return(
        <ReactEcharts
            option={{
              title: {
                text: props.chartTextObject.title_second,
                textStyle: {
                   color: props.chartTextObject.titleColor,
                   fontFamily:props.chartTextObject.fontFamily
                 },
                subtext: props.chartTextObject.subtitle_second,
                subtextStyle: {
                   color: props.chartTextObject.subTitleColor,
                   fontFamily:props.chartTextObject.fontFamily
                 },
                left: 'center',
              },
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              grid: { containLabel: true },
              legend: {
                data: [props.chartDataObject.chart_xaxis_label_second],
                show:props.chartLegendObject.display,
                orient:props.chartLegendObject.orientation,
                left:props.chartLegendObject.leftPosition,
                top:props.chartLegendObject.topPosition,
                textStyle:{
                   fontFamily:props.chartTextObject.fontFamily,
                   color:`rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
                },
              },
              xAxis: {
                type: "value"
              },
              yAxis: {
                type: "category",
                data: props.chartDataObject.chart_labels_second
              },
              series: [{
                name: props.chartDataObject.chart_xaxis_label_second,
                data: props.chartDataObject.chart_data_second,
                type: "line",
                itemStyle: {
                 color: `rgba(${ props.barColorObject.strokeColor.r }, ${ props.barColorObject.strokeColor.g }, ${ props.barColorObject.strokeColor.b }, ${ props.barColorObject.strokeColor.a })`
                }
              }]
            }}
            style={{height: '500px', width: '100%'}}
            className='echarts-for-echarts'
          />
      )
  };

  function UserSettingGraph(type) {
     if(type.settingData === null){
        return null;
     }else{
       switch(type.settingData.chart_type)
       {
         case "Pie":
          return <ShowPieChart settingData={type.settingData}/>;
         break;
         case "HorizontalBar":
          return <ShowHorizonalBarChart settingData={type.settingData}/>;
         break;
         case "Line":
           return <ShowLineChart settingData={type.settingData}/>;
         break;
       default:
         return <ShowBarChart settingData={type.settingData}/>;
       }
     }
  };


return (
       <React.Fragment>
         <UserSettingGraph settingData={props.chartTypeObject} />
      </React.Fragment>
    )
};


const mapStateToProps = reducerObj => {
  const chartTypeObject = reducerObj.dashchartTypeReducers.chartType;
  const chartDataObject = reducerObj.dashchartDataReducers.chartData;
  const barColorObject = reducerObj.dashbarColorReducers.barColor;
  const chartTextObject = reducerObj.dashchartTextReducers.textData;
  const chartLegendObject = reducerObj.dashchartLegendReducers.legendData;
  const lineColorObject = reducerObj.dashlineColorReducers.LineColor;
  const chartBaseObject = reducerObj.dashchartBaseReducers.chartBaseType;

  return { chartDataObject  , barColorObject,
    chartTextObject,chartLegendObject , lineColorObject,chartTypeObject };
};

export default compose(
  connect(mapStateToProps, {
  }),
)(BarChart);
