import React, { useState , useEffect ,useRef}  from "react";

import axios from 'axios';

import { connect } from "react-redux";

import { compose } from "recompose";

import ReactTooltip from "react-tooltip";

import { Auth } from "aws-amplify";

import html2canvas from "html2canvas";

import { SketchPicker } from 'react-color';

import reactCSS from 'reactcss';

import ToggleMenu from "../common/toggleMenu/toggle-menu";

import { Loader } from "../common/Loader/loader";

import { store } from 'react-notifications-component';

import PrintModal from "./PrintModal";

import ReportModal from "./ReportModal";

import EChartModal from "./EChartModal";

import SaveAlertModal from "./SaveAlertModal";

import DeleteAlertModal from "./DeleteAlertModal";

import HelpModal from "./HelpModal";

import ConfirmationSettingAlertModal from "./ConfirmationSettingAlertModal";

import {API_SERVER }  from '../constant';

import { updateIndexObject } from "../../actions/IndexAction";
import { updateReportObject } from "../../actions/ReportAction";
import { updateEChartTypeObject,resetEChartTypeObject } from "../../actions/EChartAction/EChartTypeAction";
import { updateEChartDataObject,resetEChartDataObject } from "../../actions/EChartAction/EChartDataAction";
import { updateEChartBaseObject,resetEChartBaseObject } from "../../actions/EChartAction/EChartBaseAction";
import { updateEChartTextObject,resetEChartTextObject } from "../../actions/EChartAction/EChartTextAction";
import { updateEChartEventObject,resetEChartEventObject } from "../../actions/EChartAction/EChartEventAction";
import { updateEChartLegendObject,resetEChartLegendObject } from "../../actions/EChartAction/EChartLegendAction";
import { updateEBarColorObject,resetEBarColorObject } from "../../actions/EChartAction/EBarColorAction";
import { updateELineColorObject,resetELineColorObject } from "../../actions/EChartAction/ELineColorAction";

import { updateEChartSettingObject,resetEChartSettingObject } from "../../actions/EChartAction/EChartSettingsAction";

import Index from "./EChartComponents/index";
import ChartBase from "./EChartComponents/chartBase";
import ColorBase from "./EChartComponents/colorBase";
import TextBase  from "./EChartComponents/textBase";
import LegendBase  from "./EChartComponents/legendBase";

import saveSVG from "../../assets/images/save.svg";
import deleteSVG from "../../assets/images/delete.svg";
import reloadSVG from "../../assets/images/reload.png";

import settingIcon from "../../assets/images/setting.png";

const _ = require('lodash');

const pdfConverter = require("jspdf");

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function EChart(props) {

    let [expand, setExpanded] = useState(false);
    let [show, setShow] = useState(false);
    let [Loading, setLoading] = useState(false);

    let [userEmail, setUserEmail] = useState(null);
    let [chartHeader, setChartHeader] = useState(null);

    let [labels, setLabels] = useState([]);
    let [graphData,setGraphData]  = useState([]);
    let [colors, setColors] = useState([]);
    let [xAxisLabel, setXaxisLabel] = useState(null);
    let [yAxisLabel, setYaxisLabel] = useState(null);
    let [completeArray, setCompleteArray] = useState([]);

    let [downloadType,setDownloadType] = useState("PDF");

    let [showPrintPopup, setShowPrintPopup] = useState(false);
    let [printPopUpData, setPrintPopUpData] = useState(null);

    let [showReportPopup, setShowReportPopup] = useState(false);
    let [reportPopUpData, setReportPopUpData] = useState([]);

    let [showHelpPopup, setShowHelpPopup] = useState(false);

    let [showSaveAlertPopup, setShowSaveAlertPopup] = useState(false);
    let [saveAlertFileName, setSaveAlertFileName] = useState(null);

    let [showDeleteAlertPopup, setShowDeleteAlertPopup] = useState(false);
    let [deleteAlertFileName, setDeleteAlertFileName] = useState(null);

    let [showConfirmSettingPopup ,setShowConfirmSettingPopup]  = useState(false);

     let {legendClicked, titleClicked } = props.chartEventObject
     let prevAmount = usePrevious({legendClicked, titleClicked});


      function toggleSidebar()
      {
        //setExpanded(expand=!expand);
      };

      function toggleGraph()
      {
        //setShow(show=!show);
      };

      async function getChartDetails(data)
      {
          if(data === null)
          {
            setLabels(labels=[]);
            setGraphData(graphData=[]);

              setTimeout(() => {
                setLoading(false);
            }, 1000);
          }
          else
          {
            let label_array = [];
            let value_array = [];
            let color_array = [];
            let complete_array = [];

            if(data.length > 0)
             {
               await _.forEach(data, function(objectVal, index) {
                    let keys = Object.keys(objectVal);

                    let second_key = keys[1];
                    let first_key = keys[0];
                    color_array.push('#'+Math.floor(Math.random()*16777215).toString(16))

                  if((objectVal[first_key] === null) || (objectVal[second_key] === null))
                    {}
                  else
                  {
                       if(typeof objectVal[keys[1]] === "string")
                      {
                        value_array.push(objectVal[keys[0]]);
                        label_array.push(objectVal[keys[1]]);
                        setXaxisLabel(xAxisLabel=keys[1]);
                        setYaxisLabel(yAxisLabel=keys[0]);
                        complete_array.push({"value":objectVal[keys[0]] ,"name":objectVal[keys[1]]});
                      }
                      else
                      {
                        value_array.push(objectVal[keys[1]]);
                        label_array.push(objectVal[keys[0]]);
                        setXaxisLabel(xAxisLabel=keys[0]);
                        setYaxisLabel(yAxisLabel=keys[1]);
                        complete_array.push({"value":objectVal[keys[1]] ,"name":objectVal[keys[0]]});
                      }

                      if((typeof objectVal[keys[1]] === "number") && (typeof objectVal[keys[0]] === "number"))
                      {
                        complete_array.push({"x":objectVal[0] ,"y":objectVal[1]})
                      }
                  }
               });
             }

            setCompleteArray(completeArray=complete_array);
            setLabels(labels=label_array);
            setGraphData(graphData=value_array);
            setColors(colors=color_array);
            props.updateEChartDataObject({chart_labels:label_array,chart_data:value_array,
              chart_colors:color_array,chart_array:completeArray,chart_xaxis_label:xAxisLabel,chart_yaxis_label:yAxisLabel});

            setTimeout(() => {
                setLoading(false);
             }, 2000);
          }
      };

      async function handleExpandAction(elementName)
      {
        let toggler = document.getElementsByClassName("caret");
        let nested = document.getElementsByClassName("nested");

         await _.forEach(toggler, function(objectVal, index) {
               if(objectVal.id === elementName)
               {
                 objectVal.classList.add("caret-down");
               }
               else
               {
                 objectVal.classList.remove("caret-down");
               }
            });

          await _.forEach(nested, function(objectVal, index) {
                if(objectVal.id === elementName)
                {
                  objectVal.classList.add("active");
                }
                else
                {
                  objectVal.classList.remove("active");
                }
             });
          setLoading(false);
      };

      async function handleToggleAction(elementName)
       {
         let toggler = document.getElementsByClassName("caret");
         let nested = document.getElementsByClassName("nested");

           await _.forEach(toggler, function(objectVal, index) {
                 if(objectVal.id === elementName)
                 {
                   objectVal.classList.toggle("caret-down");
                 }
                 else
                 {
                   objectVal.classList.remove("caret-down");
                 }
              });

            await _.forEach(nested, function(objectVal, index) {
                  if(objectVal.id === elementName)
                  {
                    objectVal.classList.toggle("active");
                  }
                  else
                  {
                    objectVal.classList.remove("active");
                  }
               });
       };

        function handleOptionClick(event)
        {
          handleToggleAction(event.target.id)
        };

        function handleDownloadTypeChange(e)
        {
           setDownloadType(downloadType=e.target.value);
        }

        async function getUserDetails()
        {
          let userInfo =  await Auth.currentUserInfo();
          if(userInfo != null)
          {
            setUserEmail(userEmail= userInfo.attributes.email );
          }
          let redirectData = {"userEmail":props.match.params.email,"Key":props.match.params.email+"/kpis-zip/"+ props.match.params.zip,"value":props.match.params.file+".json","label":props.match.params.file};
          readSelectedFile(redirectData,redirectData,redirectData);
        }

        async function getUploadedFileList()
        {
          axios.post(API_SERVER +`s3-zip-file-read`,{'userEmail' :userEmail ,'directoryName':'','sortOrder':'asc'})
            .then(function (response) {
              if(response.status === 200)
              {
                setReportPopUpData(reportPopUpData=response.data.data)
              }
              else
              {
                store.addNotification({
                  title: "Error",
                  message: "Error",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "zoomIn"],
                  animationOut: ["animated", "zoomOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true ,
                    showIcon :true
                  }
                 });
              }
            })
            .catch(function (error) {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            })
            .then(function () {
              setLoading(false);
            });
        };

        async function readSelectedFileApplySettings(directoryDetails,fileDetails,chartSettings)
        {
          setLoading(true);

          axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmail ,'directoryName':directoryDetails.Key ,"fileName":fileDetails.key})
            .then(function (response) {
              if(response.status === 200)
              {
                props.updateEChartTypeObject({chart_type:chartSettings.chart_type});

                props.updateEChartTextObject({fontFamily:response.data.fontFamily,titleColor:chartSettings.titleColor,subTitleColor:chartSettings.subTitleColor,title :chartSettings.title,subtitle : chartSettings.subTitle});

                props.updateEChartBaseObject({background_color:chartSettings.background_color,canvas_width:chartSettings,canvasHeight:chartSettings.canvas_height});

                props.updateEChartLegendObject({fontColor:{"r": chartSettings.legendFontColor.r,"g":chartSettings.legendFontColor.g,"b":chartSettings.legendFontColor.b,"a":chartSettings.legendFontColor.a},display :chartSettings.legendDisplay,position : chartSettings.legendPosition});

                props.updateEBarColorObject({fillColor:{"r": chartSettings.barFillColor.r,"g":chartSettings.barFillColor.g,"b":chartSettings.barFillColor.b,"a":chartSettings.barFillColor.a},strokeColor:{"r": chartSettings.barStrokeColor.r,"g":chartSettings.barStrokeColor.g,"b":chartSettings.barStrokeColor.b,"a":chartSettings.barStrokeColor.a}});

                {/*props.updateELineColorObject({pointStyle:chartSettings.linePointStyle,fillColor:{"r": chartSettings.lineFillColor.r,"g":chartSettings.lineFillColor.g,"b":chartSettings.lineFillColor.b,"a":chartSettings.lineFillColor.a},
                                               strokeColor:{"r": chartSettings.lineStrokeColor.r,"g":chartSettings.lineStrokeColor.g,"b":chartSettings.lineStrokeColor.b,"a":chartSettings.lineStrokeColor.a},
                                               pointsColor:{"r": chartSettings.linePointColor.r,"g":chartSettings.linePointColor.g,"b":chartSettings.linePointColor.b,"a":chartSettings.linePointColor.a},
                                               pointStrokeColor:{"r": chartSettings.linePointStrokeColor.r,"g":chartSettings.linePointStrokeColor.g,"b":chartSettings.linePointStrokeColor.b,"a":chartSettings.linePointStrokeColor.a},
                                               checkFillColor :chartSettings.lineCheckFillColor ,linePointRadius:chartSettings.linePointRadius}) */}

                props.updateELineColorObject({fillColor:{"r": chartSettings.lineFillColor.r,"g":chartSettings.lineFillColor.g,"b":chartSettings.lineFillColor.b,"a":chartSettings.lineFillColor.a},
                             strokeColor:{"r": chartSettings.lineStrokeColor.r,"g":chartSettings.lineStrokeColor.g,"b":chartSettings.lineStrokeColor.b,"a":chartSettings.lineStrokeColor.a},
                             pointStrokeColor:{"r": chartSettings.linePointStrokeColor.r,"g":chartSettings.linePointStrokeColor.g,"b":chartSettings.linePointStrokeColor.b,"a":chartSettings.linePointStrokeColor.a},
                             });

                getChartDetails(response.data);
              }
              else
              {
                store.addNotification({
                  title: "Error",
                  message: "Error",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "zoomIn"],
                  animationOut: ["animated", "zoomOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true ,
                    showIcon :true
                  }
                 });
              }
            })
            .catch(function (error) {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            })
            .then(function () {
              setLoading(false);
              setShow(show=false);
            });
        };

       async function readSelectedFile(userEmail,directoryDetails,fileDetails)
       {
         axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmail ,'directoryName':directoryDetails.Key ,"fileName":fileDetails.value})
           .then(function (response) {
             if(response.status === 200)
             {
                getChartDetails(response.data);
                props.updateEChartTextObject({title:fileDetails.label});
                setChartHeader(chartHeader=fileDetails.label);
                checkPreSavedSettings();
             }
             else
             {
               store.addNotification({
                 title: "Error",
                 message: "Error",
                 type: "danger",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "zoomIn"],
                 animationOut: ["animated", "zoomOut"],
                 dismiss: {
                   duration: 3000,
                   onScreen: true ,
                   showIcon :true
                 }
                });
             }
           })
           .catch(function (error) {
             store.addNotification({
               title: "Error",
               message: "Error",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
           })
           .then(function () {

           });
       };


       async function checkPreSavedSettings()
      {

          store.addNotification({
            title: "Success",
            message: "Checking for pre saved settings",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "zoomIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
              duration: 3000,
              onScreen: true ,
              showIcon :true
            }
           });

        axios.post(API_SERVER +`s3-get-user-chart-default-setting`,{'userEmail' :userEmail})
          .then(function (response) {
            if(response.status === 200)
            {
                props.updateEChartTypeObject({chart_type:response.data.chart_type});

                props.updateEChartTextObject({fontFamily:response.data.fontFamily,titleColor:response.data.titleColor,subTitleColor:response.data.subTitleColor});

                props.updateEChartBaseObject({background_color:response.data.background_color,canvas_width:response.data,canvasHeight:response.data.canvas_height});

                props.updateEChartLegendObject({fontColor:{"r": response.data.legendFontColor.r,"g":response.data.legendFontColor.g,"b":response.data.legendFontColor.b,"a":response.data.legendFontColor.a},display :response.data.legendDisplay,position : response.data.legendPosition ,leftPosition:response.data.legendLeftPosition ,topPosition:response.data.legendTopPosition,orientation:response.data.legendOrientation});

                props.updateEBarColorObject({fillColor:{"r": response.data.barFillColor.r,"g":response.data.barFillColor.g,"b":response.data.barFillColor.b,"a":response.data.barFillColor.a},strokeColor:{"r": response.data.barStrokeColor.r,"g":response.data.barStrokeColor.g,"b":response.data.barStrokeColor.b,"a":response.data.barStrokeColor.a}});

                props.updateELineColorObject({fillColor:{"r": response.data.lineFillColor.r,"g":response.data.lineFillColor.g,"b":response.data.lineFillColor.b,"a":response.data.lineFillColor.a},
                             strokeColor:{"r": response.data.lineStrokeColor.r,"g":response.data.lineStrokeColor.g,"b":response.data.lineStrokeColor.b,"a":response.data.lineStrokeColor.a},
                             pointStrokeColor:{"r": response.data.linePointStrokeColor.r,"g":response.data.linePointStrokeColor.g,"b":response.data.linePointStrokeColor.b,"a":response.data.linePointStrokeColor.a},
                             });
            }
          })
          .catch(function (error) {

          })
          .then(function () {
            setLoading(false);
            setShow(show=false);
          });
      };


      /*** HANDLE POPUP ***/

      const openPrintPopup = () => {
        setShowPrintPopup(showPrintPopup=true);
      };

      const closePrintPopup = () => {
        setShowPrintPopup(showPrintPopup=false);
      };

      const onPrintModalCloseAfterApi = (event) => {
        setLoading(true);
        if(event === "PDF")
        {
            let input = document.getElementsByClassName("chart-img")[0];

            html2canvas(input).then(canvas => {

            let imgData = canvas.toDataURL('image/png');

              const pdf2 = new pdfConverter({
                orientation: 'landscape',
              });
              const imgProps= pdf2.getImageProperties(imgData);
              const pdfWidth = pdf2.internal.pageSize.getWidth();
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              pdf2.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
              pdf2.save(chartHeader +`.pdf`);
          });

        }
        else if(event === "JPEG")
        {
          let input = document.getElementsByClassName("chart-img")[0];

           html2canvas(input).then(canvas => {

            let canvasImage = canvas.toDataURL('image/png');

            let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response);
                    a.download = chartHeader +`.jpeg`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    a.remove()
                  };
                  xhr.open('GET', canvasImage); // This is to download the canvas Image
                  xhr.send();

          });
        }else
        {
          downloadSelectedJsonFile();
        }
      setLoading(false);
      };

      const openReportPopup = () => {
        setShowReportPopup(showReportPopup=true);
      };

      const closeReportPopup = () => {
        setShowReportPopup(showReportPopup=false);
      };


      const onReportModalCloseAfterApi = (directoryDetails,fileDetails,loadedSettings) => {
        if(directoryDetails != null){
            setLoading(true);
          if(loadedSettings === undefined){
               resetChartSettings();
               readSelectedFile(directoryDetails,fileDetails)
            }else{
               props.updateEChartSettingObject({settingApplied:true,fileName:fileDetails.value,settingConfig:loadedSettings,fileLabel:fileDetails.label});
              readSelectedFileApplySettings(directoryDetails,fileDetails,loadedSettings)
            }
        }else{
          setGraphData(graphData=[]);
        }
      };

      const openHelpPopup = () => {
        setShowHelpPopup(showHelpPopup=true);
      };

      const closeHelpPopup = () => {
        setShowHelpPopup(showHelpPopup=false);
      };

      const openSaveAlertPopup = () => {
        if(graphData.length > 0){
          setShowSaveAlertPopup(showSaveAlertPopup=true);
        }else{
          store.addNotification({
               title: "Error",
               message: "First Render Graph",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
        }
      };

      const closeSaveAlertPopup = () => {
        setShowSaveAlertPopup(showSaveAlertPopup=false);
      };

      const onSaveAlertPopupCloseAfterApi = (event) => {
         setShow(show=false);
      };

      const openDeleteAlertPopup = () => {
        setShowDeleteAlertPopup(showDeleteAlertPopup=true);
      };

      const closeDeleteAlertPopup = () => {
        setShowDeleteAlertPopup(showDeleteAlertPopup=false);
      };

      const onDeleteAlertPopupCloseAfterApi = (response) => {
          if(response === "true"){
             setShow(show=false);
             deleteSelectedSettings()
          };
      };

      const closeConfirmSettingPopup = () => {
        setShowConfirmSettingPopup(showConfirmSettingPopup=false);
      };

      const onConfirmModalCloseAfterApi = (response) => {
           if(response === "yes"){
              saveDefaultSettingsNow();
           }else if(response === "prestocharts"){
               applyDefaultSettingsNow();
            }else{

            }
        };

        async function resetChartSettings()
        {
          props.resetEChartSettingObject();
          props.resetEChartTextObject();
          props.resetEChartBaseObject();
          props.resetEChartTypeObject();
          props.resetEChartLegendObject();
          props.resetELineColorObject();
          props.resetEBarColorObject();
          props.resetEChartEventObject();
          props.resetEChartDataObject();
          setChartHeader(chartHeader=null);
          setLabels(labels=[]);
          setGraphData(graphData=[]);
          setColors(colors=[]);
          setXaxisLabel(xAxisLabel=null);
          setYaxisLabel(yAxisLabel=null);
          setCompleteArray(completeArray=[])
        };

        async function downloadSelectedJsonFile()
        {
           setLoading(true);
          axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmail ,'directoryName':props.indexObject.key ,"fileName":props.reportObject.key})
            .then(function (response) {
              if(response.status === 200)
              {
                const fileData = JSON.stringify(response.data);
                 const blob = new Blob([fileData], {type: "text/plain"});
                 const url = URL.createObjectURL(blob);
                 const link = document.createElement('a');
                 link.download = props.reportObject.value;
                 link.href = url;
                 link.click();
              }
              else
              {

              }
            })
            .catch(function (error) {

            })
            .then(function () {
              setLoading(false);
            });
        };


         async function deleteSelectedSettings()
         {
           setLoading(true);
           axios.post(API_SERVER +`s3-delete-saved-chart-settings`,{'userEmail' :userEmail ,'indexObject':props.indexObject,'reportObject':props.reportObject,'chartSettings': props.chartSettingsObject.settingConfig})
             .then(function (response) {
               if(response.status === 200)
               {
                  resetChartSettings();

                 store.addNotification({
                    title: "Done!",
                    message: response.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "zoomIn"],
                    animationOut: ["animated", "zoomOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true ,
                      showIcon :true
                    }
                 });
               }
               else
               {
                 store.addNotification({
                   title: "Error",
                   message: "Error",
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "zoomIn"],
                   animationOut: ["animated", "zoomOut"],
                   dismiss: {
                     duration: 3000,
                     onScreen: true ,
                     showIcon :true
                   }
                  });
               }
             })
             .catch(function (error) {
               store.addNotification({
                 title: "Error",
                 message: "Error",
                 type: "danger",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "zoomIn"],
                 animationOut: ["animated", "zoomOut"],
                 dismiss: {
                   duration: 3000,
                   onScreen: true ,
                   showIcon :true
                 }
                });
             })
             .then(function () {
               setLoading(false);
             });
         };


         async function applyDefaultSettings()
      {
        setShowConfirmSettingPopup(showConfirmSettingPopup=true);
      };

      async function applyDefaultSettingsNow()
      {
        setLoading(true);

        store.addNotification({
          title: "Done",
          message: "Please wait while we configure default settings",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "zoomIn"],
          animationOut: ["animated", "zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: true ,
            showIcon :true
          }
         });

        axios.post(API_SERVER +`s3-get-chart-default-setting`,{'userEmail' :userEmail })
          .then(function (response) {
            if(response.status === 200)
            {
                props.updateEChartTextObject({fontFamily:response.data.fontFamily,titleColor:response.data.titleColor,subTitleColor:response.data.subTitleColor});

                props.updateEChartBaseObject({background_color:response.data.background_color,canvas_width:response.data,canvasHeight:response.data.canvas_height});

                props.updateEChartLegendObject({fontColor:{"r": response.data.legendFontColor.r,"g":response.data.legendFontColor.g,"b":response.data.legendFontColor.b,"a":response.data.legendFontColor.a},display :response.data.legendDisplay,position : response.data.legendPosition});

                props.updateEBarColorObject({fillColor:{"r": response.data.barFillColor.r,"g":response.data.barFillColor.g,"b":response.data.barFillColor.b,"a":response.data.barFillColor.a},strokeColor:{"r": response.data.barStrokeColor.r,"g":response.data.barStrokeColor.g,"b":response.data.barStrokeColor.b,"a":response.data.barStrokeColor.a}});

                props.updateELineColorObject({fillColor:{"r": response.data.lineFillColor.r,"g":response.data.lineFillColor.g,"b":response.data.lineFillColor.b,"a":response.data.lineFillColor.a},
                             strokeColor:{"r": response.data.lineStrokeColor.r,"g":response.data.lineStrokeColor.g,"b":response.data.lineStrokeColor.b,"a":response.data.lineStrokeColor.a},
                             pointStrokeColor:{"r": response.data.linePointStrokeColor.r,"g":response.data.linePointStrokeColor.g,"b":response.data.linePointStrokeColor.b,"a":response.data.linePointStrokeColor.a},
                             });

               updateUserDefaultSettings(response.data);
            }
            else
            {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            }
          })
          .catch(function (error) {
            store.addNotification({
              title: "Error",
              message: "Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                duration: 3000,
                onScreen: true ,
                showIcon :true
              }
             });
          })
          .then(function () {
            setLoading(false);
          });
      };

      async function updateUserDefaultSettings(chartSettings)
      {
        setLoading(true);

       axios.post(API_SERVER +`s3-save-new-chart-default-setting`,{'userEmail' :userEmail,'chartSettings':chartSettings })
         .then(function (response) {
           if(response.status === 200)
           {

           }
           else
           {
             store.addNotification({
               title: "Error",
               message: "Error",
               type: "danger",
               insert: "top",
               container: "top-right",
               animationIn: ["animated", "zoomIn"],
               animationOut: ["animated", "zoomOut"],
               dismiss: {
                 duration: 3000,
                 onScreen: true ,
                 showIcon :true
               }
              });
           }
         })
         .catch(function (error) {
           store.addNotification({
             title: "Error",
             message: "Error",
             type: "danger",
             insert: "top",
             container: "top-right",
             animationIn: ["animated", "zoomIn"],
             animationOut: ["animated", "zoomOut"],
             dismiss: {
               duration: 3000,
               onScreen: true ,
               showIcon :true
             }
            });
         })
         .then(function () {
           setLoading(false);
         });
      };

      async function saveDefaultSettingsNow()
      {
        setLoading(true);

        store.addNotification({
          title: "Done",
          message: "Please wait while we saving current settings",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "zoomIn"],
          animationOut: ["animated", "zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: true ,
            showIcon :true
          }
         });

         let chartSettings = {
                              'canvas_width':props.chartBaseObject.canvasWidth,
                              'canvas_height': props.chartBaseObject.canvasHeight,
                              'chart_type':props.chartObject.chart_type,
                              'background_color': props.chartBaseObject.background_color,
                              'barFillColor':props.barColorObject.fillColor,
                              'barStrokeColor':props.barColorObject.strokeColor,
                              'titleColor':props.chartTextObject.titleColor,
                              'subTitleColor':props.chartTextObject.subTitleColor,
                              'fontFamily':props.chartTextObject.fontFamily,
                              'legendDisplay':props.chartLegendObject.display,
                              'legendPosition':props.chartLegendObject.position,
                              'legendLeftPosition':props.chartLegendObject.leftPosition,
                              'legendTopPosition':props.chartLegendObject.topPosition,
                              'legendOrientation':props.chartLegendObject.orientation,
                              'legendFontColor':props.chartLegendObject.fontColor,
                              'lineFillColor':props.lineColorObject.fillColor,
                              'lineStrokeColor':props.lineColorObject.strokeColor,
                              'linePointStrokeColor':props.lineColorObject.pointStrokeColor,
                              'linePointColor':props.lineColorObject.pointsColor,
                              'lineCheckFillColor':props.lineColorObject.checkFillColor,
                              'linePointRadius':props.lineColorObject.pointRadius,
                              'linePointStyle':props.lineColorObject.pointStyle,
                            };

        axios.post(API_SERVER +`s3-save-new-chart-default-setting`,{'userEmail' :userEmail,'chartSettings':chartSettings })
          .then(function (response) {
            if(response.status === 200)
            {

            }
            else
            {
              store.addNotification({
                title: "Error",
                message: "Error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "zoomIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true ,
                  showIcon :true
                }
               });
            }
          })
          .catch(function (error) {
            store.addNotification({
              title: "Error",
              message: "Error",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "zoomIn"],
              animationOut: ["animated", "zoomOut"],
              dismiss: {
                duration: 3000,
                onScreen: true ,
                showIcon :true
              }
             });
          })
          .then(function () {
            setLoading(false);
          });
      };

        useEffect(() => {
          setLoading(true);
          getUserDetails();
        },[]);

       useEffect(() => {
          if(prevAmount === undefined)
          {}else{
            setShow(show=true);
            setLoading(true);
            if(prevAmount.legendClicked !== legendClicked)
            {
              setTimeout(() => {
                handleExpandAction("legend");
              }, 1000);
            }
            if(prevAmount.titleClicked !== titleClicked)
            {
              setShow(show=true);
              setTimeout(() => {
                handleExpandAction("text");
              }, 1000);
            }
        }
     }, [legendClicked, titleClicked])

    return(
        <React.Fragment>
        <PrintModal
            showPrintPopup={showPrintPopup}
            closePrintPopup={closePrintPopup}
            onPrintModalCloseAfterApi={onPrintModalCloseAfterApi}
            printPopUpData = {printPopUpData}
          />
        <HelpModal
              showHelpPopup={showHelpPopup}
              closeHelpPopup={closeHelpPopup}
            />
            <ReportModal
                showReportPopup={showReportPopup}
                closeReportPopup={closeReportPopup}
                onReportModalCloseAfterApi={onReportModalCloseAfterApi}
                reportPopUpData = {reportPopUpData}
                userEmail ={userEmail}
              />
            <SaveAlertModal
                showSaveAlertPopup={showSaveAlertPopup}
                closeSaveAlertPopup={closeSaveAlertPopup}
                onSaveAlertPopupCloseAfterApi={onSaveAlertPopupCloseAfterApi}
                userEmail ={userEmail}
              />
          <DeleteAlertModal
                showDeleteAlertPopup={showDeleteAlertPopup}
                closeDeleteAlertPopup={closeDeleteAlertPopup}
                onDeleteAlertPopupCloseAfterApi={onDeleteAlertPopupCloseAfterApi}
                chartHeader  ={chartHeader}
              />
            <ConfirmationSettingAlertModal
                  showConfirmSettingPopup={showConfirmSettingPopup}
                  closeConfirmSettingPopup={closeConfirmSettingPopup}
                  onConfirmModalCloseAfterApi={onConfirmModalCloseAfterApi}
                  confirmPopupMessage={"Are you sure that you like to use save current chart settings as default ?"}
                />
          <div id="wrapper" className={expand ? "menu-active" : "menu"}>
              <ToggleMenu toggleSidebar={toggleSidebar.bind(this)} expanded={expand}/>
              <div id="page-content-wrapper">
                <div className="page-content inset" data-spy="scroll" data-target="#spy">
                    <div className="container-full">
                       <div className="boxWrapper">
                            <div className="page-content-row">
                                <div className="row">
                                    <div className={!show ? "col-md-12 rightspac" : "col-md-9 rightspac"}>
                                              <div className="lftHandCursor" data-tip data-for="selectReportTip" onClick={(e) => openReportPopup()}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="63" viewBox="0 0 514.186 577.554">
                                                        <g id="cursor" transform="matrix(0.259, 0.966, -0.966, 0.259, 457.744, -22.176)">
                                                          <g id="Group_2" data-name="Group 2">
                                                            <g id="Group_1" data-name="Group 1">
                                                              <path id="Path_1" data-name="Path 1" d="M461.63,251.914l-15.038-34.207c-.89-2.026-1.879-4.035-2.938-5.968A52.313,52.313,0,0,0,376.889,189l-5.8,2.52-7.4-16.845,0-.007-5.456-12.41A43.12,43.12,0,0,0,301.236,140.2l-19.228,8.33A35.988,35.988,0,0,0,269.094,137c-10.521-5.445-23.178-4.987-37.621,1.363l-13.759,6.048L199.9,103.9c-7.25-16.493-20.706-27.81-36.916-31.05-15.239-3.046-30.755,1.265-41.51,11.53a51.818,51.818,0,0,0-11.822,58.9L177.122,297.71a79.493,79.493,0,0,0-17.643-1.519c-15.691.387-29.8,5.377-39.247,13.79a10.007,10.007,0,0,0-1.737,1.428c-9.494,9.777-14.174,21.6-13.537,34.2.8,15.743,9.79,31.268,26.006,44.9.082.069.165.137.25.2L269.9,499.858a10,10,0,0,0,10.209,1.3l198.672-87.337a10,10,0,0,0,5.956-8.531l1.081-17.312A294,294,0,0,0,461.63,251.914Zm4.223,134.814-.7,11.231-187.6,82.472L143.715,375.1c-11.612-9.8-18.28-20.623-18.78-30.5-.324-6.4,1.87-12.364,6.526-17.748a10.133,10.133,0,0,0,.964-.873c11.971-12.329,41.824-13.181,57.414-1.748l34.447,33.084a10,10,0,0,0,13.855-14.425L204.625,310.7,127.977,135.246c-6.717-15.28-.627-28.821,7.313-36.4,5.953-5.682,15.066-8.129,23.782-6.386,9.87,1.973,17.87,8.893,22.527,19.486l58.06,132.072a10,10,0,0,0,18.309-8.049l-32.2-73.249,13.76-6.049c8.636-3.795,15.493-4.438,20.379-1.909,3.458,1.79,6.373,5.338,8.663,10.546l34.24,77.888a10,10,0,0,0,18.309-8.049l-30.194-68.685,18.32-7.937c.039-.017.077-.033.115-.051a23.121,23.121,0,0,1,30.567,11.832l40.687,92.565a10,10,0,0,0,18.309-8.049l-19.779-44.992,5.722-2.488a32.129,32.129,0,0,1,41.244,13.994c.783,1.431,1.514,2.916,2.173,4.416l15.039,34.208A273.945,273.945,0,0,1,465.853,386.728Z"/>
                                                              <path id="Path_2" data-name="Path 2" d="M426.561,384.075,416.7,388.4a10,10,0,1,0,8.033,18.315l9.865-4.327a10,10,0,0,0-8.033-18.315Z"/>
                                                              <path id="Path_3" data-name="Path 3" d="M386.77,401.751,281.417,447.959a10,10,0,1,0,8.033,18.316L394.8,420.066a10,10,0,0,0-8.033-18.315Z"/>
                                                              <path id="Path_4" data-name="Path 4" d="M127.017,45.2A10,10,0,1,0,146.6,41.153l-6.85-33.174a10,10,0,1,0-19.586,4.044Z"/>
                                                              <path id="Path_5" data-name="Path 5" d="M41.035,46.913A10,10,0,0,0,37.8,33.146l-6.907-4.277a10,10,0,1,0-10.531,17l6.907,4.277a10,10,0,0,0,13.767-3.236Z"/>
                                                              <path id="Path_6" data-name="Path 6" d="M60.454,72.245,81.786,86.276a10,10,0,1,0,10.991-16.71L71.444,55.535a10,10,0,0,0-10.99,16.71Z"/>
                                                              <path id="Path_7" data-name="Path 7" d="M86.622,135.491a10,10,0,0,0-11.816-7.771l-33.174,6.85a10,10,0,0,0,2.012,19.795,10.109,10.109,0,0,0,2.033-.208l33.174-6.85A10,10,0,0,0,86.622,135.491Z"/>
                                                              <path id="Path_8" data-name="Path 8" d="M111.775,186.982a10,10,0,0,0-13.85,2.86l-18.614,28.3a10,10,0,1,0,16.71,10.99l18.614-28.3A10,10,0,0,0,111.775,186.982Z"/>
                                                              <path id="Path_9" data-name="Path 9" d="M221.355,105.18a10,10,0,0,0,9.783,7.979,10.116,10.116,0,0,0,2.033-.208l22.116-4.567A10,10,0,1,0,251.242,88.8l-22.116,4.567A10,10,0,0,0,221.355,105.18Z"/>
                                                              <path id="Path_10" data-name="Path 10" d="M293.34,100.315a10.109,10.109,0,0,0,2.033-.208l6.912-1.427a10,10,0,1,0-4.045-19.587l-6.912,1.427a10,10,0,0,0,2.012,19.795Z"/>
                                                              <path id="Path_11" data-name="Path 11" d="M190.907,61.982a10,10,0,0,0,13.85-2.86l18.614-28.3a10,10,0,0,0-16.71-10.99l-18.614,28.3A10,10,0,0,0,190.907,61.982Z"/>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </svg>

                                             </div>
                                     <div className="hide_show_chart" data-tip data-for="formatReportTip" onClick = { toggleGraph }>{ show ? <p><i className="fa fa-times-circle"></i></p>: <p><img width="30" src={settingIcon}/></p>}</div>
                                        <div className="left-side-box">
                                        <div className="chart-img" style={{backgroundColor: props.chartBaseObject.background_color,height:props.chartBaseObject.canvasHeight+"px", width:props.chartBaseObject.canvasWidth+"px" ,position: 'relative'}}>
                                                {props.chartDataObject.chart_data.length >0 ? <Index/> : <p>Please Select Some Dataset</p>}
                                            </div>
                                        </div>
                                            <div className="leftHelpBottom" data-tip data-for="helpTip" onClick={(e) => openHelpPopup()}>
                                         <svg height="112pt" viewBox="0 0 512 512" width="112pt" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"/>
                                            <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/>
                                            <path d="m256 314.667969c-8.832031 0-16-7.167969-16-16v-21.546875c0-20.308594 12.886719-38.507813 32.042969-45.269532 25.492187-8.980468 42.625-36.140624 42.625-55.851562 0-32.363281-26.304688-58.667969-58.667969-58.667969s-58.667969 26.304688-58.667969 58.667969c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16c0-49.984375 40.664063-90.667969 90.667969-90.667969s90.667969 40.683594 90.667969 90.667969c0 35.585938-28.097657 73.367188-63.980469 86.039062-6.398438 2.238282-10.6875 8.316407-10.6875 15.101563v21.527344c0 8.832031-7.167969 16-16 16zm0 0"/>
                                         </svg>
                                    </div>

                                      <div className="save-as-row saveasrht">
                                        { props.chartDataObject.chart_data.length > 0 ?
                                              <div className="row col-sm-12 col-md-10 col-lg-8">
                                                    <div className="col-md-4 col-lg-3 ">
                                                          <button type="button" data-tip data-for="savechartTip" className="btn download-btn" onClick={(e) => openPrintPopup()} >
                                                             <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                                                          </button>
                                                    </div>
                                              </div>
                                             : <div className="row col-sm-12 col-md-10 col-lg-8">
                                                     <div className="col-md-4 col-lg-3 saveasrht">
                                                           <button type="button" data-tip data-for="savechartTip" className="btn download-btn">
                                                              <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                                                           </button>
                                                     </div>
                                             </div>
                                            }
                                    </div>
                                    </div>
                                    <div  className="col-md-3  leftspac">
                                      { show ?
                                        <div className="right-side-box sidebar">
                                             <ul id="myUL" className="right-sidebar">
                                                 <li>
                                                   <span className="caret" id="charts" onClick = {handleOptionClick}>Chart</span>
                                                     <ChartBase/>
                                                  </li>
                                                  <li>
                                                    <span className="caret"  id="colors" onClick={handleOptionClick}>Colors</span>
                                                     <ColorBase/>
                                                  </li>
                                                  <li>
                                                    <span className="caret"  id="text" onClick={handleOptionClick}>Text</span>
                                                    <TextBase/>
                                                  </li>
                                                  <li>
                                                    <span className="caret"  id="legend" onClick={handleOptionClick}>Legend</span>
                                                    <LegendBase/>
                                                  </li>
                                               </ul>
                                                 <div className="saveDel">
                                                   <ReactTooltip id="savechartsettingTip"  place="top" delayHide={500 } effect="solid">
                                                       Save Report
                                                    </ReactTooltip>

                                                    <ReactTooltip id="deletechartsettingTip"  place="top" delayHide={500 } effect="solid">
                                                       Delete Report
                                                    </ReactTooltip>

                                                    <ReactTooltip id="setdefaultsettingtip"  place="top" delayHide={500 } effect="solid">
                                                       Save as Default Setting
                                                    </ReactTooltip>

                                                  { props.chartDataObject.chart_data.length > 0 ?
                                                       <span data-tip data-for="savechartsettingTip" onClick={openSaveAlertPopup}><img src={saveSVG}/></span>
                                                       :<span></span>
                                                   }
                                                  { props.chartSettingsObject.settingApplied && props.chartDataObject.chart_data.length > 0 ?
                                                     <span data-tip data-for="deletechartsettingTip" onClick={openDeleteAlertPopup}><img src={deleteSVG}/></span>
                                                      :<span></span>
                                                  }
                                                  { props.chartDataObject.chart_data.length > 0 ?

                                                    <span data-tip data-for="setdefaultsettingtip" onClick={(e) => applyDefaultSettings()}><img src={reloadSVG}/></span>
                                                    :<span></span>
                                                 }
                                                </div>
                                          </div>
                                          : <div></div>
                                         }
                                      </div>
                                    </div>
                                 </div>
                                </div>
                          </div>
                           </div>
                </div>
                {Loading  &&
                   <Loader />
                }
                <ReactTooltip id="selectReportTip" place="top" delayHide={500 } effect="solid">
                  Select Report
                </ReactTooltip>

                <ReactTooltip id="formatReportTip" place="top" delayHide={500 } effect="solid">
                   Format Report
                </ReactTooltip>

                <ReactTooltip id="helpTip" place="top" delayHide={500 } effect="solid">
                   Help
                </ReactTooltip>

                <ReactTooltip id="savechartTip"  place="top" delayHide={500 } effect="solid">
                   Download
                </ReactTooltip>
            </div>
         </React.Fragment>
       )
};


const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  const chartDataObject = reducerObj.eChartDataReducers.chartData;
  const chartBaseObject = reducerObj.eChartBaseReducers.chartBaseType;
  const barColorObject = reducerObj.eBarColorReducers.barColor;
  const chartTextObject = reducerObj.eChartTextReducers.textData;
  const chartEventObject = reducerObj.eChartEventReducers.eventList;
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  const lineColorObject = reducerObj.eLineColorReducers.LineColor;
  const chartSettingsObject  = reducerObj.eChartSettingsReducers.chartSettingsObject;
  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  return { chartObject , chartDataObject , chartBaseObject , barColorObject,
    chartTextObject,chartEventObject,chartLegendObject , lineColorObject,chartSettingsObject,indexObject,reportObject };
};

export default compose(
  connect(mapStateToProps, {
    updateEChartTypeObject,resetEChartTypeObject,
    updateEChartDataObject,resetEChartDataObject,
    updateEChartBaseObject,resetEChartBaseObject,
    updateEChartTextObject,resetEChartTextObject ,
    updateEChartLegendObject,resetEChartLegendObject,
    updateELineColorObject,resetELineColorObject,
    updateEBarColorObject,resetEBarColorObject,
    updateEChartEventObject,resetEChartEventObject,
    updateEChartSettingObject,resetEChartSettingObject,
    updateIndexObject,updateReportObject
  }),
)(EChart);
