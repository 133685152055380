import { UPDATE_LAYOUTS , RESET_LAYOUTS} from "../../actions/GridDraggingAction/GridLayoutsAction";

import layoutConfig from "./layoutConfig";

export const LAYOUTS_INITIAL_STATE = {
  layoutData: {
    lg : [] ,
    md : [] ,
    sm:  [],
    xs:  [],
  }
};

export default (state = LAYOUTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LAYOUTS:
      return {
        ...state,
        layoutData: Object.assign({}, state.layoutData, action.payload)
      };
      case RESET_LAYOUTS:
        return LAYOUTS_INITIAL_STATE
    default:
      return state;
  }
};
