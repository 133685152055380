export const UPDATE_EBAR_COLOR = "update-ebar-color";
export const RESET_EBAR_COLOR = "reset-ebar-color";

export const updateEBarColorObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEBarColorObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_EBAR_COLOR,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_EBAR_COLOR
  };
};
