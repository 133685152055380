import React ,{ useState , useEffect} from 'react';
import { connect } from "react-redux";

import { PropTypes } from "prop-types";

import { compose } from "recompose";

import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import { updatePivotDataObject,resetPivotDataObject } from "../../actions/PivotAction/PivotDataAction";
import { updatePivotSettingObject,resetPivotSettingObject } from "../../actions/PivotAction/PivotSettingsAction";

import testjson from "./mps.json";

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);


const PivotWrapper = ( props ) => {
  console.log("pivotChartData",props.chartSettingsObject.pivotChartData)
  let [pivotState, setPivotState] = useState();
  let [colors, setColors] = useState(['#00000','#00000','#00000']);
  
 // let {chartSettingsObject} = props;
//console.log("pivotChartSettings",chartSettingsObject.pivotChartData)
  function _onEvents(e)
  {
   setPivotState(pivotState= e);
  };
 
   useEffect(() => {
      console.log("props.pivotObject.pivot_data>>",props.pivotObject.pivot_data)
      //setPivotState(JSON.parse(localStorage.getItem('pivotLocalStorage')));      
     // setPivotState({...pivotState,pivotState:chartSettingsObject.pivotChartData,aggregatorName: "Count"});
       //  setPivotState(pivotState= null);
      // setPivotState(pivotState= JSON.parse(localStorage.getItem('pivotLocalStorage')));
         props.updatePivotSettingObject({pivotChartData:pivotState});
     },[props.pivotObject.pivot_data,pivotState]);
     let localStorageVal ="";
     if(localStorage.getItem('pivotLocalStorage')){
       localStorageVal = JSON.parse(localStorage.getItem('pivotLocalStorage'))
     }
   //  console.log("Local storage",localStorageVal.rows)
console.log("Wrapper ",TableRenderers,PlotlyRenderers, pivotState,localStorageVal)
    return (
      
         <React.Fragment>
          {(localStorageVal)?
          <PivotTableUI
              data={props.pivotObject.pivot_data}
              onChange={s => _onEvents(s)}
                            
             {...colors}
              renderers={Object.assign({}, TableRenderers,PlotlyRenderers)}
              aggregatorName={localStorageVal.aggregatorName}
              rows={localStorageVal.rows}
              rendererName={localStorageVal.rendererName}
             // colOrder={[sortIcons:"key_a_to_zero"]}
              //sortIcons=["colOrder":"key_a_to_zero"]
              //rowOrder="key_a_to_zero"
              sorters={[]}
              valueFilter={[]}
              cols={[]}
              vals={[]}
              tableOptions={[]}
              unusedOrientationCutoff="85"
              menuLimit="500"
              
              {...pivotState}
          />
          :
          <PivotTableUI
              data={props.pivotObject.pivot_data}
              onChange={s => _onEvents(s)}             
              renderers={Object.assign({}, TableRenderers,PlotlyRenderers)}           

              {...pivotState}
          />
          }
          {/* {console.log("Wrapper>>> ",TableRenderers,PlotlyRenderers, pivotState)} */}
      </React.Fragment>
    );
};

const mapStateToProps = reducerObj => {
  const pivotObject = reducerObj.PivotReducers.pivotData;
  const chartSettingsObject =
    reducerObj.PivotSettingsReducers.chartSettingsObject;
 
  console.log("mapStateToProps",pivotObject,chartSettingsObject)
  return { pivotObject,chartSettingsObject };
};

export default compose(
  connect(mapStateToProps, {
   updatePivotDataObject,resetPivotDataObject,updatePivotSettingObject,resetPivotSettingObject
  }),
)(PivotWrapper);
