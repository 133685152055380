export const UPDATE_CHART_EVENT = "update-chart-event";
export const RESET_CHART_EVENT = "reset-chart-event";

export const updateChartEventObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetChartEventObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_CHART_EVENT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_CHART_EVENT
  };
};
