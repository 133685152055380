import { UPDATE_ECHART_BASE,RESET_ECHART_BASE } from "../../actions/EChartAction/EChartBaseAction";

export const ECHART_BASE_INITIAL_STATE = {
  chartBaseType: {
    background_color :'#ffff',
    canvasWidth :900,
    canvasHeight:450
  }
};

export default (state = ECHART_BASE_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_BASE:
      return {
        ...state,
        chartBaseType: Object.assign({}, state.chartBaseType, action.payload)
      };
    case RESET_ECHART_BASE:
      return ECHART_BASE_INITIAL_STATE
    default:
      return state;
  }
};
