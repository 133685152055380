import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import BarChart           from "./Bar/barChart"
import HorizontalBarChart from "./HorizontalBar/barChart"
import DonoughtChart      from "./Donought/donoughtChart"
import LineChart          from "./Line/lineChart"
import PieChart           from "./Pie/pieChart"

function Index(props) {

  function renderGraph()
  {
    let currentGraphType = props.chartObject.chart_type;

    if(currentGraphType === "Doughnut"){
      return <DonoughtChart/>;
    } else if (currentGraphType === "Pie"){
      return <PieChart/>;
    } else if(currentGraphType === "Line"){
      return <LineChart/>;
    }else if(currentGraphType === "HorizontalBar"){
       return <HorizontalBarChart/>;
    }else{
        return <BarChart/>;
    }
  }

  return (
   <React.Fragment>
        { renderGraph() }
   </React.Fragment>
  );
}


const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  return { chartObject};
};

export default compose(
  connect(mapStateToProps, {
  }),
)(Index);
