import { UPDATE_ECHART_TEXT , RESET_ECHART_TEXT } from "../../actions/EChartAction/EChartTextAction";

export const ECHART_TEXT_INITIAL_STATE = {
  textData: {
    title : '',
    titleColor:'#0038AC',
    subtitle:'',
    subTitleColor :'#CADBFF',
    fontFamily:'Open Sans'
  }
};

export default (state = ECHART_TEXT_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_TEXT:
      return {
        ...state,
        textData: Object.assign({}, state.textData, action.payload)
      };
      case RESET_ECHART_TEXT:
        return ECHART_TEXT_INITIAL_STATE
    default:
      return state;
  }
};
