export const UPDATE_PIVOT_SETTINGS = "update-pivot-settings";
export const RESET_PIVOT_SETTINGS = "reset-pivot-settings";

export const updatePivotSettingObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetPivotSettingObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_PIVOT_SETTINGS,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_PIVOT_SETTINGS
  };
};
