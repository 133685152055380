import { UPDATE_INDEX , RESET_INDEX } from "../actions/IndexAction";

export const INDEX_INITIAL_STATE = {
  indexObject: {
    label:'',
    value:'',
    key :''
  }
};

export default (state = INDEX_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_INDEX:
      return {
        ...state,
        indexObject: Object.assign({}, state.indexObject, action.payload)
      };
      case RESET_INDEX:
        return INDEX_INITIAL_STATE
    default:
      return state;
  }
};
