export const UPDATE_REPORT = "update-report";
export const RESET_REPORT = "reset-report";

export const updateReportObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetReportObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_REPORT,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_REPORT
  };
};
