import { UPDATE_CHART_DATA , RESET_CHART_DATA} from "../../actions/ChartAction/ChartDataAction";

export const CHART_DATA_INITIAL_STATE = {
  chartData: {
    chart_labels : [],
    chart_data : [],
    chart_colors : [],
    chart_array : [],
    chart_xaxis_label :'',
    chart_yaxis_label :'',
  }
};

export default (state = CHART_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_DATA:
      return {
        ...state,
        chartData: Object.assign({}, state.chartData, action.payload)
      };
      case RESET_CHART_DATA:
        return CHART_DATA_INITIAL_STATE
    default:
      return state;
  }
};
