import { UPDATE_DASHBOARD_CHART_BASE,RESET_DASHBOARD_CHART_BASE } from "../../actions/DashboardAction/ChartBaseAction";

export const DASHBOARD_CHART_BASE_INITIAL_STATE = {
  chartBaseType: {
    background_color :'#ffff',
    background_color_first :'#ffff',
    background_color_second :'#ffff',
    background_color_third :'#ffff',
  }
};

export default (state = DASHBOARD_CHART_BASE_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_BASE:
      return {
        ...state,
        chartBaseType: Object.assign({}, state.chartBaseType, action.payload)
      };
    case RESET_DASHBOARD_CHART_BASE:
      return DASHBOARD_CHART_BASE_INITIAL_STATE
    default:
      return state;
  }
};
