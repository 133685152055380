export const UPDATE_DASHBOARD_TITLE = "update-dashboard-title";
export const RESET_DASHBOARD_TITLE = "reset-dashboard-title";

export const updateDashboardTitleObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetDashboardTitleObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_DASHBOARD_TITLE,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_DASHBOARD_TITLE
  };
};
