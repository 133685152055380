import { UPDATE_LANGUAGE , RESET_LANGUAGE } from "../actions/TranslationAction";

export const LANGUAGE_INITIAL_STATE = {
  langObject: {
    currentLanguage:'en',
  }
};

export default (state = LANGUAGE_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        langObject: Object.assign({}, state.langObject, action.payload)
      };
      case RESET_LANGUAGE:
        return LANGUAGE_INITIAL_STATE
    default:
      return state;
  }
};
