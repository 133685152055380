import { UPDATE_CHART_BASE,RESET_CHART_BASE } from "../../actions/ChartAction/ChartBaseAction";

export const CHART_BASE_INITIAL_STATE = {
  chartBaseType: {
    background_color :'#ffff',
    canvasWidth :900,
    canvasHeight:450
  }
};

export default (state = CHART_BASE_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_BASE:
      return {
        ...state,
        chartBaseType: Object.assign({}, state.chartBaseType, action.payload)
      };
    case RESET_CHART_BASE:
      return CHART_BASE_INITIAL_STATE
    default:
      return state;
  }
};
