import React, { useState }  from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { compose } from "recompose";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import { updateEChartLegendObject } from "../../../actions/EChartAction/EChartLegendAction";

function LegendBase(props) {

  let [chartDisplay,setChartDisplay] = useState(props.chartLegendObject.display);
  let [chartLeftPosition,setChartLeftPostion] = useState(props.chartLegendObject.leftPosition);
  let [chartTopPosition,setChartTopPostion] = useState(props.chartLegendObject.topPosition);
  let [chartOrientation,setChartOrientation] = useState(props.chartLegendObject.topPosition);

  let [displayFontColorPicker,setDisplayFontColorPicker] = useState(false);
  let [fontColor,setFontColor] = useState(props.chartLegendObject.fontColor);

  const styles = reactCSS({
       'default': {
         color: {
           width: '21px',
           height: '21px',
           borderRadius: '36px'
         },
         swatch: {
           padding: '0px',
           background: 'rgb(255, 255, 255)',
           borderRadius: '12px',
           boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
           display: 'inline-block',
           cursor: 'pointer',
         },
         popover: {
           position: 'absolute',
           zIndex: '2',
         },
         cover: {
           position: 'fixed',
           top: '0px',
           right: '0px',
           bottom: '0px',
           left: '0px',
         },
       },
     });

     function handleColorPickerStyle(type)
     {
       if(type === "legend")
        {
          return reactCSS({
            'default': {
              color: {
                width: '21px',
                height: '21px',
                borderRadius: '36px',
                background: `rgba(${ props.chartLegendObject.fontColor.r }, ${ props.chartLegendObject.fontColor.g }, ${ props.chartLegendObject.fontColor.b }, ${ props.chartLegendObject.fontColor.a })`,
              }
            },
          });
        }
     }

     function handleColorClick (e,type)
      {
        if(type === "legend")
         {
          setDisplayFontColorPicker( displayFontColorPicker = !displayFontColorPicker );
         }
      };

      function handleColorClose (e,type)
      {
        if(type === "legend")
         {
          setDisplayFontColorPicker(displayFontColorPicker = false)
        }
      };

      function handleColorChange(color,type)
      {
        if(type === "legend")
         {
          setFontColor(fontColor = color.rgb);
          props.updateEChartLegendObject({fontColor:color.rgb});
        }
      };

    function handleLegendDisplayChange(e)
    {
      setChartDisplay(chartDisplay=e.target.checked)
      props.updateEChartLegendObject({display : e.target.checked})
    };

    function handleLegendLeftPositionChange(e)
    {
      setChartLeftPostion(chartLeftPosition=e.target.value)
      props.updateEChartLegendObject({leftPosition : e.target.value})
    };

    function handleLegendTopPositionChange(e)
    {
      setChartTopPostion(chartTopPosition=e.target.value)
      props.updateEChartLegendObject({topPosition : e.target.value})
    };

    function handleLegendOrientationChange(e)
    {
      setChartOrientation(chartOrientation=e.target.value)
      props.updateEChartLegendObject({orientation : e.target.value})
    };


  return (
   <React.Fragment>
     <ul className="nested" id="legend">
        <li>
            <table>
              <tbody>
              <tr>
                  <td>
                     Display
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="displaylegend"
                      value={props.chartLegendObject.display}
                      checked={props.chartLegendObject.display}
                      onChange={handleLegendDisplayChange}
                      />
                  </td>
              </tr>
              <tr>
                  <td>
                     Orientations
                  </td>
                  <td>
                    <div className="relativePosition">
                        <select value={props.chartLegendObject.orientation} onChange={handleLegendOrientationChange} id="ChartType">
                          <option value="horizontal">Horizontal</option>
                          <option value="vertical">Vertical</option>
                        </select>
                        <div className="Hideselect"></div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                     Left
                  </td>
                  <td>
                    <div className="relativePosition">
                        <select value={props.chartLegendObject.leftPosition} onChange={handleLegendLeftPositionChange} id="ChartType">
                          <option value="left">Left</option>
                          <option value="center">Center</option>
                          <option value="right">Right</option>
                        </select>
                        <div className="Hideselect"></div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                     Top
                  </td>
                  <td>
                    <div className="relativePosition">
                        <select value={props.chartLegendObject.topPosition} onChange={handleLegendTopPositionChange} id="ChartType">
                          <option value="top">Top</option>
                          <option value="middle">Middle</option>
                          <option value="bottom">Bottom</option>
                        </select>
                        <div className="Hideselect"></div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                     Font Color
                  </td>
                  <td>
                    <div className="circle fill-circle">
                    <div style={ styles.swatch } onClick={(e) => { handleColorClick(e, "legend")}}>
                            <div style={ handleColorPickerStyle("legend").color } />
                          </div>
                          { displayFontColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={(e) => { handleColorClose(e, "legend")}} />
                            <SketchPicker color={ props.chartLegendObject.fontColor } onChange={ (e) => { handleColorChange(e, "legend")}} />
                          </div> : null }
                    </div>
                  </td>
              </tr>
              </tbody>
            </table>
        </li>
      </ul>
   </React.Fragment>
  );
}




const mapStateToProps = reducerObj => {
  const chartLegendObject = reducerObj.eChartLegendReducers.legendData;
  return {chartLegendObject};
};

export default compose(
  connect(mapStateToProps, {
    updateEChartLegendObject
  }),
)(LegendBase);
