export const UPDATE_DASHBOARD_CHART_BASE = "update-dashboard-chart-base";
export const RESET_DASHBOARD_CHART_BASE = "reset-dashboard-chart-base";

export const updateChartBaseObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetChartBaseObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_DASHBOARD_CHART_BASE,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_DASHBOARD_CHART_BASE
  };
};
