export const UPDATE_DASHBOARD_CHART_SETTINGS = "update-dashboard-chart-settings";
export const RESET_DASHBOARD_CHART_SETTINGS = "reset-dashboard-chart-settings";

export const updateChartSettingObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetChartSettingObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_DASHBOARD_CHART_SETTINGS,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_DASHBOARD_CHART_SETTINGS
  };
};
