import React, { useEffect, useRef, useState } from "react";

import Popup from "reactjs-popup";

import axios from "axios";

import { Auth } from "aws-amplify";

import { FormValidator } from "../FormValidator/FormValidator";

import { Loader } from "../Loader/loader";

import { store } from "react-notifications-component";

import { history } from "../history/history";

import leftLogoImg from "../../../assets/images/left-logo.png";

import animGraphGif from "../../../assets/images/ufw.png";

import { API_SERVER, ODOO_API_SERVER } from "../../constant";

import "./SignUpModal.css";

function SignUpModal(props) {
  let { showSignUpPopup, closeSignUpPopup } = props;
  let [submit, setSubmit] = useState(false);
  let [Loading, setLoading] = useState(false);
  let [valid, setValid] = useState("");
  let [formValue, setFormValue] = useState("signin");

  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Email is required.",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Password is required.",
    },
  ]);

  let [state, setState] = useState({
    user: {
      email: "",
      password: "",
      validation: validator.valid(),
    },
    verification: {
      verification: "",
    },
    resetPassword: {
      code: "",
      new_password: "",
    },
  });

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    const { user } = state;
    setState({
      user: {
        ...user,
        [name]: value,
      },
      verification: {
        ...user,
        [name]: value,
      },
      resetPassword: {
        ...user,
        [name]: value,
      },
    });
  }

  let validation = validator.validate(state.user);

  async function handleSubmitSignIn(e) {
    e.preventDefault();
    validation = validator.validate(state.user);

    setSubmit((submit = true));

    if (validation.isValid) {
      signIn(state.user.email, state.user.password);
    }
  }

  async function registerUserOnOdoo(email, name) {
    axios
      .post(ODOO_API_SERVER + `create`, {
        config: "prestocharts-inc",
        module: "user",
        input: {
          name: name,
          email: email,
          x_themecolor: "#00f",
          x_userlanguage: "en",
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          store.addNotification({
            title: "Done!",
            message: "Odoo Profile Created Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "zoomIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        } else {
        }
      })
      .catch(function (error) {})
      .then(function () {});
  }

  async function signIn(email, password) {
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      localStorage.setItem("loginStatus", "true");
      store.addNotification({
        title: "Done!",
        message: "Login Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          showIcon: true,
        },
      });
      setLoading(false);
      history.push("/home");
    } catch (error) {
      setLoading(false);
      setValid(error.message);
      if (error.message == "User is not confirmed.") {
        store.addNotification({
          title: "Done!",
          message: "User is not Verified. Please verify your account",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "zoomIn"],
          animationOut: ["animated", "zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        setState({
          user: {
            email,
            password: "",
          },
          verification: {
            verification: "",
          },
        });
        setFormValue((formValue = "confirmSignUp"));
      }
    }
  }

  async function handleSubmitSignUp(e) {
    e.preventDefault();
    validation = validator.validate(state.user);

    setSubmit((submit = true));

    if (validation.isValid) {
      signUp(state.user.email, state.user.password);
    }
  }

  let [userEmail, setUserEmail] = useState(null);

  async function signUp(email, password) {
    setUserEmail(email);
    await Auth.signOut({ global: true });
    setLoading(true);
    try {
      const { user } = await Auth.signUp(email, password);
      store.addNotification({
        title: "Done!",
        message:
          "Registration Successfully! Verfication Code sent to your email address.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
      setState({
        user: {
          email,
          password: "",
        },
        verification: {
          verification: "",
        },
      });
      setFormValue((formValue = "confirmSignUp"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      store.addNotification({
        title: "Done!",
        message: "error signing up:" + error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
    }
  }

  async function applyDefaultSettingsNow() {
    setLoading(true);

    store.addNotification({
      title: "Done",
      message: "Please wait while we configure default settings",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "zoomIn"],
      animationOut: ["animated", "zoomOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
        showIcon: true,
      },
    });

    axios
      .post(API_SERVER + `s3-get-chart-default-setting`, {
        userEmail: userEmail,
      })
      .then(function (response) {
        if (response.status === 200) {
          updateUserDefaultSettings(response.data);
        } else {
          store.addNotification({
            title: "Error",
            message: "Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "zoomIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
          updateUserDefaultSettings({
            userEmail: userEmail,
            chartSettings: {
              canvas_width: 900,
              canvas_height: 450,
              chart_type: "Bar",
              background_color: "#ffff",
              barFillColor: {
                r: 188,
                g: 225,
                b: 147,
                a: 0.2,
              },
              barStrokeColor: {
                r: 86,
                g: 133,
                b: 30,
                a: 1,
              },
              titleColor: "#0038AC",
              subTitleColor: "#CADBFF",
              fontFamily: "Open Sans",
              legendDisplay: true,
              legendPosition: "bottom",
              legendLeftPosition: "center",
              legendTopPosition: "bottom",
              legendOrientation: "vertical",
              legendFontColor: {
                r: 34,
                g: 25,
                b: 77,
                a: 100,
              },
              lineFillColor: {
                r: 247,
                g: 255,
                b: 224,
                a: 0.2,
              },
              lineStrokeColor: {
                r: 86,
                g: 133,
                b: 30,
                a: 1,
              },
              linePointStrokeColor: {
                r: 75,
                g: 192,
                b: 192,
                a: 1,
              },
              linePointColor: {
                r: 247,
                g: 255,
                b: 224,
                a: 0.2,
              },
              lineCheckFillColor: false,
              linePointRadius: 3,
              linePointStyle: "circle",
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: "Error",
          message: "Error",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "zoomIn"],
          animationOut: ["animated", "zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        console.log("in catch");
        updateUserDefaultSettings({
          userEmail: userEmail,
          chartSettings: {
            userEmail: "ashish8@mailinator.com",
            chartSettings: {
              canvas_width: 900,
              canvas_height: 450,
              chart_type: "Bar",
              background_color: "#ffff",
              barFillColor: {
                r: 188,
                g: 225,
                b: 147,
                a: 0.2,
              },
              barStrokeColor: {
                r: 86,
                g: 133,
                b: 30,
                a: 1,
              },
              titleColor: "#0038AC",
              subTitleColor: "#CADBFF",
              fontFamily: "Open Sans",
              legendDisplay: true,
              legendPosition: "bottom",
              legendLeftPosition: "center",
              legendTopPosition: "bottom",
              legendOrientation: "vertical",
              legendFontColor: {
                r: 34,
                g: 25,
                b: 77,
                a: 100,
              },
              lineFillColor: {
                r: 247,
                g: 255,
                b: 224,
                a: 0.2,
              },
              lineStrokeColor: {
                r: 86,
                g: 133,
                b: 30,
                a: 1,
              },
              linePointStrokeColor: {
                r: 75,
                g: 192,
                b: 192,
                a: 1,
              },
              linePointColor: {
                r: 247,
                g: 255,
                b: 224,
                a: 0.2,
              },
              lineCheckFillColor: false,
              linePointRadius: 3,
              linePointStyle: "circle",
            },
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
  }

  async function updateUserDefaultSettings(chartSettings) {
    setLoading(true);
    console.log("updateUserDefaultSettings called------");
    axios
      .post(API_SERVER + `s3-save-new-chart-default-setting`, {
        userEmail: userEmail,
        chartSettings: chartSettings,
      })
      .then(function (response) {
        if (response.status === 200) {
        } else {
          store.addNotification({
            title: "Error",
            message: "Error",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "zoomIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: "Error",
          message: "Error",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "zoomIn"],
          animationOut: ["animated", "zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
  }

  async function confirmSignUp() {
    setLoading(true);
    try {
      await Auth.confirmSignUp(
        state.user.email,
        state.verification.verification
      );
      store.addNotification({
        title: "Done!",
        message: "Verification Successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
      registerUserOnOdoo(state.user.email, state.user.email);
      applyDefaultSettingsNow();
      setState({
        user: {
          email: "",
          password: "",
        },
        verification: {
          verification: "",
        },
      });
      setFormValue((formValue = "signin"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      store.addNotification({
        title: "Done!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
    }
  }

  const confirmResetPasswordForm = useRef(null);

  useEffect(() => {
    if (formValue === "confirmResetPassword") {
      confirmResetPasswordForm.current &&
        confirmResetPasswordForm.current.reset();
    }
  }, [formValue]);

  // Send confirmation code to user's email
  async function resetPassword(e) {
    e.preventDefault();
    try {
      await Auth.forgotPassword(state.user.email);
      store.addNotification({
        title: "Done!",
        message: "Please check email! Code send to your email ",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
      setFormValue((formValue = "confirmResetPassword"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      store.addNotification({
        title: "Done!",
        message: "error" + error,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
    }
  }

  async function confirmResetPassword(e) {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(
        state.user.email,
        state.resetPassword.code,
        state.resetPassword.new_password
      );
      store.addNotification({
        title: "Done!",
        message: "Update Password Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
      setState({
        user: {
          email: "",
          password: "",
        },
        resetPassword: {
          code: "",
          new_password: "",
        },
      });
      setFormValue((formValue = "signin"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      store.addNotification({
        title: "Done!",
        message: "error in reset password:" + error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "zoomIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
        },
      });
    }
  }

  async function changeFormState(e, value) {
    setFormValue((formValue = value));
  }

  return (
    <React.Fragment>
      <Popup open={showSignUpPopup} onClose={closeSignUpPopup}>
        <div className="modal is-active helpmodal">
          {formValue === "signup" ? (
            <div className="modelBodyOverlay">
              <div className="header-main">
                <button
                  className="deleteBtn"
                  onClick={props.closeSignUpPopup}
                  aria-label="close"
                >
                  <i className="fa fa-times-circle"></i>
                </button>
                <div className="main-icon">
                  <p className="logomain">
                    <img src={leftLogoImg} alt="" />
                  </p>
                  <span className="fa">
                    <img src={animGraphGif} alt="" className="img-responsive" />
                  </span>
                  <h3>Sign Up</h3>
                </div>
                <div className="header-left-bottom">
                  <form id="Loginfrm">
                    <div className="icon1">
                      <span className="fa fa-user"></span>
                      <input
                        className="input"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={state.user.email}
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.email.message : ""}
                      </p>
                    </div>
                    <div className="icon1">
                      <span className="fa fa-lock"></span>
                      <input
                        className="input"
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        value={state.user.password}
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.password.message : ""}
                      </p>
                    </div>

                    <div className="bottom">
                      <button className="btn" onClick={handleSubmitSignUp}>
                        REGISTER
                      </button>
                    </div>
                    <div className="links">
                      <span>
                        <button
                          type="button"
                          className="link-button"
                          onClick={(e) => changeFormState(e, "signin")}
                        >
                          Already Registered? Sign In
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : formValue === "signin" ? (
            <div className="modelBodyOverlay">
              <div className="header-main">
                <button
                  className="deleteBtn"
                  onClick={props.closeSignUpPopup}
                  aria-label="close"
                >
                  <i className="fa fa-times-circle"></i>
                </button>
                <div className="main-icon">
                  <p className="logomain">
                    <img src={leftLogoImg} alt="" />
                  </p>
                  <span className="fa">
                    <img src={animGraphGif} alt="" className="img-responsive" />
                  </span>
                  <h3>Sign In</h3>
                </div>
                <div className="header-left-bottom">
                  <form id="Loginfrm" onSubmit={handleSubmitSignIn}>
                    <div className="icon1">
                      <span className="fa fa-user"></span>
                      <input
                        className="input"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={state.user.email}
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.email.message : ""}
                      </p>
                    </div>
                    <div className="icon1">
                      <span className="fa fa-lock"></span>
                      <input
                        className="input"
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        value={state.user.password}
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.password.message : ""}
                      </p>
                    </div>
                    <div className="bottom">
                      <button className="btn" type="submit">
                        Log In
                      </button>
                    </div>
                  </form>
                  <span className="error-message">{valid}</span>
                  <div className="links">
                    <span>
                      <button
                        type="button"
                        className="link-button"
                        onClick={(e) => changeFormState(e, "resetPassword")}
                      >
                        Forgot Password?
                      </button>
                    </span>
                    <span className="right">
                      <button
                        type="button"
                        className="link-button"
                        onClick={(e) => changeFormState(e, "signup")}
                      >
                        New User? Register
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : formValue === "confirmSignUp" ? (
            <div className="modelBodyOverlay">
              <div className="header-main">
                <button
                  className="deleteBtn"
                  onClick={props.closeSignUpPopup}
                  aria-label="close"
                >
                  <i className="fa fa-times-circle"></i>
                </button>
                <div className="main-icon">
                  <p className="logomain">
                    <img src={leftLogoImg} alt="" />
                  </p>
                  <span className="fa">
                    <img src={animGraphGif} alt="" className="img-responsive" />
                  </span>
                  <h3>Confirm Sign up</h3>
                </div>
                <div className="header-left-bottom">
                  <form id="Loginfrm">
                    <div className="icon1">
                      <span className="fa fa-user"></span>
                      <input
                        className="input"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={state.user.email}
                        readOnly
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.email.message : ""}
                      </p>
                    </div>
                    <div className="icon1">
                      <span className="fa fa-check-circle"></span>
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter Verification Code"
                        name="verification"
                        value={state.verification.verification}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="bottom">
                      <button
                        type="button"
                        className="btn"
                        onClick={confirmSignUp}
                      >
                        Confirm Verification
                      </button>
                    </div>
                    <div className="links">
                      <span>
                        <button
                          type="button"
                          className="link-button"
                          onClick={(e) => changeFormState(e, "signin")}
                        >
                          Already Registered? Sign In
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : formValue === "resetPassword" ? (
            <div className="modelBodyOverlay">
              <div className="header-main">
                <button
                  className="deleteBtn"
                  onClick={props.closeSignUpPopup}
                  aria-label="close"
                >
                  <i className="fa fa-times-circle"></i>
                </button>
                <div className="main-icon">
                  <p className="logomain">
                    <img src={leftLogoImg} alt="" />
                  </p>
                  <span className="fa">
                    <img src={animGraphGif} alt="" className="img-responsive" />
                  </span>
                  <h3>Reset Password</h3>
                </div>
                <div className="header-left-bottom">
                  <form id="Loginfrm" onSubmit={resetPassword}>
                    <div className="icon1">
                      <span className="fa fa-user"></span>
                      <input
                        className="input"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={state.user.email}
                        onChange={handleChange}
                      />
                      <p className="help is-danger">
                        {submit ? validation.email.message : ""}
                      </p>
                    </div>
                    <div className="bottom">
                      <button className="btn" type="submit">
                        Send Code
                      </button>
                    </div>
                    <div className="links">
                      <span>
                        <button
                          type="button"
                          className="link-button"
                          onClick={(e) => changeFormState(e, "signin")}
                        >
                          Back to Sign In
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="modelBodyOverlay">
              <div className="header-main">
                <button
                  className="deleteBtn"
                  onClick={props.closeSignUpPopup}
                  aria-label="close"
                >
                  <i className="fa fa-times-circle"></i>
                </button>
                <div className="main-icon">
                  <p className="logomain">
                    <img src={leftLogoImg} alt="" />
                  </p>
                  <span className="fa">
                    <img src={animGraphGif} alt="" className="img-responsive" />
                  </span>
                  <h3>Reset password</h3>
                </div>
                <div className="header-left-bottom">
                  <form
                    onSubmit={confirmResetPassword}
                    ref={confirmResetPasswordForm}
                  >
                    <div className="icon1">
                      <span className="fa fa-check-circle"></span>
                      <input
                        className="input"
                        type="text"
                        placeholder="Code"
                        name="code"
                        value={state.resetPassword.code}
                        autoComplete="new-password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="icon1">
                      <span className="fa fa-unlock-alt"></span>
                      <input
                        className="input"
                        type="password"
                        placeholder="New Password"
                        name="new_password"
                        value={state.resetPassword.new_password}
                        autoComplete="new-password"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="bottom">
                      <button className="btn" type="submit">
                        Submit
                      </button>
                    </div>
                    <div className="links">
                      <span>
                        <button
                          type="button"
                          className="link-button"
                          onClick={(e) => changeFormState(e, "signin")}
                        >
                          Back to Sign In
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {Loading && <Loader />}
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default SignUpModal;
