import React, { useState, useEffect, Fragment } from 'react';

import Popup from 'reactjs-popup';

import Select, { components } from 'react-select';

import makeAnimated from 'react-select/animated';

import ReactTooltip from 'react-tooltip';

import axios from 'axios';

import { PropTypes } from 'prop-types';

import { compose } from 'recompose';

import { connect } from 'react-redux';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import { API_SERVER } from '../constant';

import { useTranslation } from 'react-i18next';

import Highlighter from 'react-highlight-words';

import { updateIndexObject } from '../../actions/IndexAction';

import { updateReportObject } from '../../actions/ReportAction';

import './modal.css';

import DownloadModal from './DownloadModal';

import ErrorMessageModal from './ErrorMessageModal';

import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'

import JSZip from 'jszip';

const fs = require('fs');

const animatedComponents = makeAnimated();

const _ = require('lodash');

function ReportModal(props) {
  const { t, i18n } = useTranslation('common');
  let { showReportPopup, closeReportPopup, reportPopUpData, userEmail } = props;
  let [Loading, setLoading] = useState(false);
  let [isReportSelected, setisReportSelected] = useState(false);

  let [fileIndexList, setFileIndexList] = useState([]);
  let [userSettingList, setUserSettingList] = useState([]);

  let [reportIndexList, setReportIndexList] = useState([]);
  let [categoryIndexList, setCategoryIndexList] = useState([]);
  let [filterReportJson, setFilterReportJson] = useState([]);
  let [filterUserReportJson, setFilterUserReportJson] = useState([]);
  let [categoryIndexFilteredList, setCategoryIndexFilteredList] = useState([]);

  let [selectedFileIndexItem, setSelectedFileIndexItem] = useState(null);
  let [selectedReportIndexItem, setSelectedReportIndexItem] = useState(null);
  let [selectedCategoryIndexItem, setSelectedCategoryIndexItem] =
    useState(null);
  let [reportSearchPattern, setReportSearchPattern] = useState(null);

  let [selectedSortParam, setSelectedSortParam] = useState('asc');
  let [selectedJsonSortParam, setSelectedJsonSortParam] = useState('asc');
  let [selectedCategorySortParam, setSelectedCategorySortParam] =
    useState('asc');

  let [showDownloadPopup, setShowDownloadPopup] = useState(false);
  let [zipLink, setZipLink] = useState(null);
  let [fileLink, setFileLink] = useState(null);

  let [showErrorMessagedPopup, setShowErrorMessagedPopup] = useState(false);
  let [selectedErrorFile, setSelectedErrorFile] = useState([]);
  let [selectedErrorLabel, setSelectedErrorLabel] = useState([]);
  let [searchReportText, setSearchReportText] = useState('');
  let [searchReportTextArray, setSearchReportTextArray] = useState([]);

  let [searchReportType, setSearchReportType] = useState(null);
  let [searchReportTypeResult, setSearchReportTypeResult] = useState([]);
  const [fileData, setFileData] = useState({});
  
  const [showErrorFile, setShowErrorFile] = useState(false)

  const openErrorMessagePopup = () => {
    setShowErrorMessagedPopup((showErrorMessagedPopup = true));
  };

  const closeErrorMessagePopup = () => {
    setShowErrorMessagedPopup((showErrorMessagedPopup = false));
  };

  const openDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = true));
  };

  const closeDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = false));
  };



  function onFileIndexChange(val) {
    console.log("onFileIndexChange",val)
    console.log("file_link URL>>",val.file_link)
    
    const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
    const REGION = process.env.REACT_APP_AWS_REGION;
   // let url = `https://` + BUCKET_NAME + `.s3-` + REGION + `.amazonaws.com/`+props.userEmail.toLowerCase() + '/kpis-complete/009file-2023117-153130.txt';
  // let url = "https://prestocharts-e/ankit.prestocharts@gmail.com/kpis-complete/ERROR_LMDERR_001-14csvoutput-20230127-047624.zip" 
   //console.log("kpis-complete url",url)

 //   fetch(url).then((r)=>{r.text().then(d=>console.log("File Content",d))})
   // let fileContent = fs.readFileSync("/kpis-complete/009file-2023117-153130.txt");
   //console.log("File Content",fileContent)

    //commented for dynamic value
      let fileDetails =val.label+'.txt';
       let userEmailID = props.userEmail.toLowerCase()
       let directoryDetails= val.Key;
       const splitString = val.label.split("-");
       
    //  let directoryDetails= 'ankit.prestocharts@gmail.com/error-new-zip/ERROR_LMDERR_001-err001csvoutput-20230130-23453.zip';
    //  let fileDetails ='ERROR_LMDERR_001-err001csvoutput-20230130-23453.txt';
    //  let userEmailID = "ankit.prestocharts@gmail.com"
       // setLoading(true);
       axios.post(API_SERVER +`s3-zip-file-get-content`,{'userEmail' :userEmailID ,'directoryName':directoryDetails ,"fileName":fileDetails})
         .then(function (response) {
           if(response.status === 200)
           {
             //const fileData = JSON.stringify(response.data);
             const fileData = response.data;
             console.log("fileData",fileData)
             let result = val.label.match(/ERROR/g);
              if(val.label.match(/ERROR/g)){
               setShowErrorMessagedPopup((showErrorMessagedPopup = true));
                setSelectedErrorFile((selectedErrorFile = fileData));
                setSelectedErrorLabel((selectedErrorLabel = t("ErrorFile."+splitString[0])+"["+splitString[0]+"]"));
                setCategoryIndexList((categoryIndexList = []));
                setFileLink((fileLink = selectedFileIndexItem.zip_link.replaceAll('kpis-zip', "error-new-zip")));
              }
              
             
              const blob = new Blob([fileData], {type: "text/plain"});
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.download = selectedReportIndexItem.label +".txt";
              link.href = url;
              link.click();
           }
           else
           {

           }
         })
         .catch(function (error) {

         })
         .then(function () {
         //  setLoading(false);
         });
    //setShowErrorMessagedPopup((showErrorMessagedPopup = true));
    //setSelectedErrorFile((selectedErrorFile = "Select Error File...."));
    setSelectedFileIndexItem((selectedFileIndexItem = val));
    setSearchReportText((searchReportText = ''));
    setCategoryIndexList((categoryIndexList = []));
    setCategoryIndexFilteredList((categoryIndexFilteredList = []));
    setisReportSelected((isReportSelected = false));
    if (selectedFileIndexItem) {
      setReportIndexList((reportIndexList = []));
      setUserSettingList((userSettingList = val.setting_file_list));
      setSelectedReportIndexItem((selectedReportIndexItem = null));
      setSelectedCategoryIndexItem((selectedCategoryIndexItem = null));
      let newCategoryIndexList = [];
      let newUserIndexList = [];

      let promises3 = val.setting_file_list.map(async (item, index) => {
        if (index >= 0) {
          let subFolderName = item;
          let lastIndex = subFolderName.lastIndexOf('/');
          let totalLength = subFolderName.length;
          let subFolderName_modified = subFolderName.substr(
            lastIndex + 1,
            totalLength
          );
          let output =
            subFolderName_modified.substr(
              0,
              subFolderName_modified.lastIndexOf('.')
            ) || subFolderName_modified;

          let userItem = {
            value: item,
            label: output,
            key: 'USER_REPORT',
            type: 'blue_color',
          };
          newUserIndexList.push(userItem);
        }
      });

      let promises2 = val.zip_file_list.map(async (item, index) => {
        if (index >= 0) {
          let categoryItem = {
            value: item,
            label: item,
            key: 'SELECTED_REPORT',
            type: 'white_color',
          };
          newCategoryIndexList.push(categoryItem);
        }
      });

      let oldCategoryIndexList = categoryIndexList;
      let modifiedUserReportIndexList =
        newUserIndexList.concat(newCategoryIndexList);
      let modifiedCategoryIndexList = oldCategoryIndexList.concat(
        modifiedUserReportIndexList
      );

      setCategoryIndexList((categoryIndexList = modifiedCategoryIndexList));
      setCategoryIndexFilteredList(
        (categoryIndexFilteredList = modifiedCategoryIndexList)
      );
      setisReportSelected((isReportSelected = true));
    } else {
      setReportIndexList((reportIndexList = []));
      setSelectedReportIndexItem((selectedReportIndexItem = null));
    }
  }

  function sourceFile() {
    if (selectedFileIndexItem != null) {
      setZipLink((zipLink = selectedFileIndexItem.zip_link));
      setFileLink((fileLink = selectedFileIndexItem.file_link));
      setShowDownloadPopup((showDownloadPopup = true));
    }
  }

  function onSortParamChange(val) {
    setSelectedSortParam((selectedSortParam = val));
    //setCategoryIndexList(categoryIndexList=[]);
    let directoryName = '';
    let sortOrder = val;

    getUploadedFileList(directoryName, sortOrder, 'indexsorting');
  }

  async function getUploadedFileList(directoryName, sortOrder, operation) {
    setLoading(true);
    console.log("getUploadedFileList",directoryName, sortOrder, operation)
    await axios
      .post(API_SERVER + `s3-file-read-complete-folder`, {
        userEmail: userEmail,
        sortOrder: 'asc',
        directoryName:''
      })
      .then(function (response) {
        console.log(response, '=test');
        if (response.status === 200) {
          console.log("getUploadedFileList",response.data.data)
          //setReportPopUpData((reportPopUpData = response.data.data));
    axios
      .post(API_SERVER + `s3-zip-file-list-with-file-count-new-content`, {
        userEmail: userEmail,
        sortOrder: sortOrder,
        directoryName:'',
        completeFiles: response.data.data,
        showErrorFileStatus: showErrorFile

      })
      .then(function (response1) {
        if (response1.status === 200) {
         
          console.log("response>>",response1,operation)
          console.log("response",response1.data.data.report_list_data)
          console.log("response",response1.data.data)
          if (operation === 'onRefocus') {
           
            setReportIndexList((reportIndexList = []));
            setReportIndexList(
              (reportIndexList = response1.data.data)
            );
            //setCategoryIndexList(categoryIndexList=response.data.data.category_list_data);
          } else if (operation === 'categorysorting') {
            setSelectedReportIndexItem((selectedReportIndexItem = null));
            setReportIndexList((reportIndexList = []));
            setReportIndexList(
              (reportIndexList = response1.data.data)
            );
          } else {
           console.log("Inside else")
            if (directoryName === '') {
              setFileIndexList((fileIndexList = []));
              setReportIndexList((reportIndexList = []));
              setSelectedFileIndexItem((selectedFileIndexItem = null));
              setisReportSelected((isReportSelected = false));
              setSelectedReportIndexItem((selectedReportIndexItem = null));
              setFileIndexList((fileIndexList = response1.data.data));
            } else {
              setReportIndexList((reportIndexList = []));
              setReportIndexList(
                (reportIndexList = response1.data.data)
              );
              //setCategoryIndexList(categoryIndexList=response.data.data.category_list_data);
              setSelectedReportIndexItem((selectedReportIndexItem = null));
            }
          }
        } else {
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error',
          message: 'Error',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });

    }
  })
  }

  function checkBackground(param) {
    switch (param) {
      case 'reportCategory':
        return '#CAE4FF';
      case 'report':
        return 'undefined';
      default:
        return '#CAE4FF';
    }
  }

  function handleMatchedChart(e) {
    e.preventDefault();
    if (searchReportText === '') {
      let subFolderName = selectedFileIndexItem.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;
      let jsonFileList = selectedFileIndexItem.zip_file_list;
      let categoryType = 'ALL';
      let selectedCategoryIndexItem = {
        value: 'ALL',
        label: 'ALL',
        key: 'ALL',
      };

      props.onReportModalCloseAfterApi(
        subFolderName,
        jsonFileList,
        directoryName,
        categoryType,
        userSettingList,
        selectedCategoryIndexItem.key,
        selectedCategoryIndexItem.value
      );
      closeReportPopup();
    } else {
      let subFolderName = selectedFileIndexItem.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;
      let jsonFileList = filterReportJson;
      let categoryType = 'ALL';
      let selectedCategoryIndexItem = {
        value: 'SELECTED_REPORT',
        label: 'SELECTED_REPORT',
        key: 'SELECTED_REPORT',
      };

      props.onReportModalCloseAfterApi(
        subFolderName,
        jsonFileList,
        directoryName,
        categoryType,
        filterUserReportJson,
        selectedCategoryIndexItem.key,
        selectedCategoryIndexItem.value
      );
      closeReportPopup();
    }
  }

  function handleAllUserChart(e) {
    e.preventDefault();
    let subFolderName = selectedFileIndexItem.Key;
    let lastIndex = subFolderName.lastIndexOf('/');
    let totalLength = subFolderName.length;
    let subFolderName_modified = subFolderName.substr(
      lastIndex + 1,
      totalLength
    );
    let directoryName = subFolderName_modified;
    let jsonFileList = selectedFileIndexItem.zip_file_list;
    let categoryType = 'ALL';
    let selectedCategoryIndexItem = {
      value: 'USER_REPORTS',
      label: 'USER_REPORTS',
      key: 'USER_REPORTS',
    };

    props.onReportModalCloseAfterApi(
      subFolderName,
      jsonFileList,
      directoryName,
      categoryType,
      userSettingList,
      selectedCategoryIndexItem.key,
      selectedCategoryIndexItem.value
    );
    closeReportPopup();
  }

  function isCharacterPresent(string, character) {
    return string.includes(character);
  }

  function getCharacterLocation(string, character) {
    let location = string.indexOf(character);
    let keyword = string.substr(0, location);
    let remainString = string.substr(location + 1, string.length);

    return { keyword_1: keyword, keyword_2: remainString };
  }

  function handleInputChange(event) {
    let oldList = categoryIndexList;
    setSearchReportText((searchReportText = event.target.value));

    if (categoryIndexFilteredList === '') {
      setCategoryIndexFilteredList((categoryIndexFilteredList = oldList));
    } else {
      let filterReportJson = [];
      let filterUserReportJson = [];
      let typedString = searchReportText.toLowerCase();

      if (
        isCharacterPresent(typedString, '+') &&
        !isCharacterPresent(typedString, '*')
      ) {
        let typedStringArray_AND = [];
        let res = typedString.split('+');
        let promises = res.map(async (val, index) => {
          if (val != '') {
            typedStringArray_AND.push(val);
          }
        });
        setSearchReportType((searchReportType = 'addition_only'));
        setSearchReportTypeResult(
          (searchReportTypeResult = typedStringArray_AND)
        );
      } else if (
        isCharacterPresent(typedString, '*') &&
        !isCharacterPresent(typedString, '+')
      ) {
        let typedStringArray_OR = [];
        let res = typedString.split('*');
        let promises = res.map(async (val, index) => {
          if (val != '') {
            typedStringArray_OR.push(val);
          }
        });
        setSearchReportType((searchReportType = 'multiply_only'));
        setSearchReportTypeResult(
          (searchReportTypeResult = typedStringArray_OR)
        );
      } else if (
        !isCharacterPresent(typedString, '+') &&
        !isCharacterPresent(typedString, '*')
      ) {
        let typedStringArray = [];
        typedStringArray.push(typedString);
        setSearchReportType((searchReportType = 'plain'));
        setSearchReportTypeResult((searchReportTypeResult = typedStringArray));
      } else {
        let typedStringArray = [];
        if (isCharacterPresent(typedString, '*')) {
          let res = typedString.split('*');
          let promises = res.map(async (val, index) => {
            if (val != '') {
              let resIn = val.split('+');
              typedStringArray.push(resIn);
            }
          });
        }

        setSearchReportType((searchReportType = 'mix'));
        setSearchReportTypeResult((searchReportTypeResult = typedStringArray));
      }

      const newArr = oldList.filter((item) => {
        let listItem = item.label.toLowerCase();
        let listItemType = item.type;

        if (searchReportType === 'addition_only') {
          let isPresent = true;

          let promises = searchReportTypeResult.map(async (val, index) => {
            let currentVal = listItem.indexOf(val) !== -1 ? true : false;
            let currentVal2 = currentVal && isPresent;
            isPresent = currentVal2;
          });

          if (isPresent) {
            if (listItemType === 'blue_color') {
              filterUserReportJson.push(item.value);
            } else {
              filterReportJson.push(item.label);
            }
          }
          return isPresent;
        } else if (searchReportType === 'multiply_only') {
          let isPresent = false;

          let promises = searchReportTypeResult.map(async (val, index) => {
            let currentVal = listItem.indexOf(val) !== -1 ? true : false;
            let currentVal2 = currentVal || isPresent;
            isPresent = currentVal2;
          });

          if (isPresent) {
            if (listItemType === 'blue_color') {
              filterUserReportJson.push(item.value);
            } else {
              filterReportJson.push(item.label);
            }
          }
          return isPresent;
        } else if (searchReportType === 'plain') {
          if (
            item.label.toLowerCase().indexOf(searchReportTypeResult[0]) !== -1
          ) {
            if (item.type === 'blue_color') {
              filterUserReportJson.push(item.value);
            } else {
              filterReportJson.push(item.label);
            }
          }

          return (
            item.label.toLowerCase().indexOf(searchReportTypeResult[0]) !== -1
          );
        } else {
          let isPresentTwo = false;

          let promises = searchReportTypeResult.map(
            async (outer_val, outer_index) => {
              let isPresentOne = true;

              let promises = outer_val.map(async (val, index) => {
                let currentVal = listItem.indexOf(val) !== -1 ? true : false;
                let currentVal2 = currentVal && isPresentOne;
                isPresentOne = currentVal2;
              });
              let finalVal = isPresentOne || isPresentTwo;
              isPresentTwo = finalVal;
            }
          );

          if (isPresentTwo) {
            if (listItemType === 'blue_color') {
              filterUserReportJson.push(item.value);
            } else {
              filterReportJson.push(item.label);
            }
          }
          return isPresentTwo;
        }
      });

      setFilterReportJson((filterReportJson = filterReportJson));
      setFilterUserReportJson((filterUserReportJson = filterUserReportJson));
      setCategoryIndexFilteredList((categoryIndexFilteredList = newArr));
    }
  }

  useEffect(() => {
    if (props.showReportPopup) {
      if (selectedFileIndexItem != null) {
        let subFolderName = selectedFileIndexItem.Key;
        let sortOrder = selectedJsonSortParam;

        getUploadedFileList(subFolderName, sortOrder, 'onRefocus');
      }
    } else {
    }
    
  }, [props.showReportPopup]);

 
 console.log("Show error file",showErrorFile)
  console.log("fileIndexList",fileIndexList)
  console.log("props.reportPopUpData",props.reportPopUpData)
  console.log(fileIndexList.length > 0 ? fileIndexList : props.reportPopUpData);
  console.log(fileIndexList, props.reportPopUpData);

  //show error files in dropdown on toggle event 
  const handlerErrorFile = () => {
    setShowErrorFile(!showErrorFile)
   
    let directory = 'aaa';
      let sortOrder = selectedJsonSortParam;
      console.log("Inside handlerErrorFile ")
      getUploadedFileList(directory, sortOrder, '');
   

  };
// const fileIndexListFiltered = props.reportPopUpData.filter(filenamefilter =>
//       filenamefilter.label="ERROR_LMDERR_001-4114csvoutput-20230208-2315952"
//       );

  return (
    <React.Fragment>
      <Popup open={showReportPopup} onClose={closeReportPopup} nested>
        <DownloadModal
          showDownloadPopup={showDownloadPopup}
          closeDownloadPopup={closeDownloadPopup}
          zipLink={zipLink}
          fileLink={fileLink}
        />
        <ErrorMessageModal
          showErrorMessagedPopup={showErrorMessagedPopup}
          closeErrorMessagePopup={closeErrorMessagePopup}
          selectedErrorFile={selectedErrorFile}
          selectedErrorLabel={selectedErrorLabel}
          fileLink={fileLink}
        />
        <div className='modal is-active custom-model-popup'>
          <div className='modal-card'>
            <header className='modal-card-head'>
              <p className='modal-card-title'>{t('Modal.SelectSourceFile')}</p>
              <button
                className='deleteBtn'
                onClick={props.closeReportPopup}
                aria-label='close'
              >
                <i className='fa fa-times-circle'></i>
              </button>
            </header>
            <section className='modal-card-body'>
              <div className='container'>
                <div className='upl-file-row'>
                <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                {console.log("Dropdown option>>",props.reportPopUpData)}
                {console.log("Dropdown option from reportIndexList>>",reportIndexList)}
                <lable style={{"margin-top":"-2px"}}>Show Error File</lable> &nbsp; &nbsp; 
                <DragSwitch onColor="#cccccc" offColor="#66bb6a" checked={showErrorFile} onChange={(e) => handlerErrorFile()
                   } />
                  
                </div>
                  <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                    <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                      <div className='styled-select arrows'>                       
                        
                        <Select
                          name='FILE'
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={
                            reportIndexList.length > 0
                              ? reportIndexList
                              : props.reportPopUpData
                          }
                          onChange={onFileIndexChange}
                          value={selectedFileIndexItem}
                          isClearable={false}
                          placeholder={t('Select.sourceFile')}
                          isMulti={false}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#CADBFF',
                              primary: '#CADBFF',
                            },
                          })}
                        />
                      </div>
                      <div
                        data-tip
                        data-for='asc'
                        className={
                          selectedSortParam === 'asc'
                            ? 'sort_asse text-allign-r active-filter'
                            : 'sort_asse text-allign-r'
                        }
                        onClick={(e) => onSortParamChange('asc')}
                      >
                        <i className='fa fa-angle-up'></i>
                      </div>
                      <div
                        data-tip
                        data-for='desc'
                        className={
                          selectedSortParam === 'desc'
                            ? 'sort_desc text-allign-r active-filter'
                            : 'sort_desc text-allign-r'
                        }
                        onClick={(e) => onSortParamChange('desc')}
                      >
                        <i className='fa fa-angle-down'></i>
                      </div>
                    </div>
                    <div className='col-md-1 col-lg-1 text-allign-r'>
                      <button
                        type='button'
                        className='btn download-btn'
                        onClick={sourceFile}
                      >
                        <svg
                          id='bold'
                          className='downloadicon'
                          enableBackground='new 0 0 24 24'
                          height='512'
                          viewBox='0 0 24 24'
                          width='512'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z' />
                          <path d='m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z' />
                          <path d='m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z' />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                    <div className='reptsearch' data-tip data-for='suggestion'>
                      <input
                        required
                        className='form-input'
                        type='text'
                        name='location'
                        placeholder={t('Select.Searchthereport')}
                        value={searchReportText}
                        onChange={(event) => handleInputChange(event)}
                        maxLength='40'
                        disabled={categoryIndexList.length > 0 ? false : true}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  {categoryIndexFilteredList.length > 0 && (
                    <div
                      className='filterReportList'
                      style={{ overflow: 'auto', maxHeight: 200 }}
                    >
                      <ul className='filterList'>
                        {categoryIndexFilteredList.map((item, i) => (
                          <li className={item.type} key={i}>
                            {item.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className='chkGraphBtn'>
                    <button
                      type='button'
                      onClick={(e) => handleMatchedChart(e)}
                      disabled={categoryIndexList.length > 0 ? false : true}
                      value='true'
                      className='btn btn-info'
                    >
                      {t('Modal.matchedChart')}
                    </button>
                  </div>
                  <div className='chkGraphBtn'>
                    <button
                      type='button'
                      onClick={(e) => handleAllUserChart(e)}
                      disabled={userSettingList.length === 0 ? true : false}
                      value='true'
                      className='btn btn-info'
                    >
                      {t('Modal.userChart')}
                    </button>
                  </div>
                  
                </div>
              </div>
            </section>
          </div>
        </div>
        <ReactTooltip id='asc' place='top' delayHide={100} effect='float'>
          {t('Select.asc')}
        </ReactTooltip>

        <ReactTooltip id='desc' place='bottom' delayHide={100} effect='float'>
          {t('Select.desc')}
        </ReactTooltip>

        <ReactTooltip
          id='suggestion'
          place='top'
          delayHide={100}
          effect='float'
        >
          "Please use + for AND, * for OR (tesxt1+text2) (text1*text2)"
        </ReactTooltip>

        {Loading && <Loader />}
      </Popup>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const indexObject = reducerObj.indexReducers.indexObject;

  const reportObject = reducerObj.reportReducers.reportObject;

  return { indexObject, reportObject };
};

export default compose(
  connect(mapStateToProps, {
    updateReportObject,
    updateIndexObject,
  })
)(ReportModal);
