import React, { useState , useEffect , useRef }  from "react";

import { connect } from "react-redux";

import { compose } from "recompose";

import { Link } from 'react-router-dom';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Paypal(props) {

    return(
        <React.Fragment>
          <PayPalScriptProvider deferLoading={true} options={{ "client-id": "ATo_lFql0IXQZi3EQrOu_CH71JX-6wx3Gqr7mRNjPCvCk8ECXiz-KxHIl0POZ7TJGi0cJjuo0QgbYErn","debug":"true" ,"currency" :"EUR"}}>
            <PayPalButtons
                style={{ layout: "horizontal" }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "10.00",
                                },
                            },
                        ],
                    });
                }}
            />
          </PayPalScriptProvider>
         </React.Fragment>
       )
};


const mapStateToProps = reducerObj => {

  return {  };
};

export default compose(
  connect(mapStateToProps, {

  }),
)(Paypal);
