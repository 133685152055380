import React, { useState }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import BarColor           from "./Bar/barColor"
import HorizontalBarColor from "./HorizontalBar/horizontalBarColorChart"
import LineColor          from "./Line/lineColor"

function ColorBase(props) {

  function renderComponent()
  {
    let currentGraphType = props.chartObject.chart_type;

    if(currentGraphType === "Bar"){
      return <BarColor/>;
    }else if(currentGraphType === "HorizontalBar"){
       return <HorizontalBarColor/>;
    }else if(currentGraphType === "Line"){
       return <LineColor/>
    }else{

    }
  }

  return (
   <React.Fragment>
        { renderComponent() }
   </React.Fragment>
  );
}


const mapStateToProps = reducerObj => {
  const chartObject = reducerObj.eChartTypeReducers.chartType;
  return { chartObject};
};

export default compose(
  connect(mapStateToProps, {
  }),
)(ColorBase);
