import React, { useState, useEffect } from 'react';

import Popup from 'reactjs-popup';

import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import ReactTooltip from 'react-tooltip';

import axios from 'axios';

import { PropTypes } from 'prop-types';

import { compose } from 'recompose';

import { connect } from 'react-redux';

import { Loader } from '../common/Loader/loader';

import { store } from 'react-notifications-component';

import { useTranslation } from 'react-i18next';

import { API_SERVER } from '../constant';

import { updateIndexObject } from '../../actions/IndexAction';

import { updateReportObject } from '../../actions/ReportAction';

import './modal.css';

const animatedComponents = makeAnimated();

const _ = require('lodash');

function ReportModal(props) {
  const { t, i18n } = useTranslation('common');
  let { showReportPopup, closeReportPopup, reportPopUpData, userEmail } = props;
  let [Loading, setLoading] = useState(false);
  let [isReportSelected, setisReportSelected] = useState(false);

  let [fileIndexList, setFileIndexList] = useState([]);
  let [reportIndexList, setReportIndexList] = useState([]);
  let [categoryIndexList, setCategoryIndexList] = useState([]);

  let [selectedFileIndexItem, setSelectedFileIndexItem] = useState(null);
  let [selectedReportIndexItem, setSelectedReportIndexItem] = useState(null);
  let [selectedCategoryIndexItem, setSelectedCategoryIndexItem] = useState({
    value: 'ALL',
    label: 'ALL',
    key: 'ALL',
  });

  let [selectedSortParam, setSelectedSortParam] = useState('asc');
  let [selectedJsonSortParam, setSelectedJsonSortParam] = useState('asc');
  let [selectedCategorySortParam, setSelectedCategorySortParam] =
    useState('asc');

  let [showDownloadPopup, setShowDownloadPopup] = useState(false);
  let [zipLink, setZipLink] = useState(null);
  let [fileLink, setFileLink] = useState(null);

  const openDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = true));
  };

  const closeDownloadPopup = () => {
    setShowDownloadPopup((showDownloadPopup = false));
  };

  function onCategoryIndexChange(val) {
    setSelectedCategoryIndexItem((selectedCategoryIndexItem = val));
    let subFolderName = selectedFileIndexItem.Key;
    let lastIndex = subFolderName.lastIndexOf('/');
    let totalLength = subFolderName.length;
    let subFolderName_modified = subFolderName.substr(
      lastIndex + 1,
      totalLength
    );
    let directoryName = subFolderName_modified;
    let sortOrder = selectedJsonSortParam;
    getUploadedFileList(subFolderName, sortOrder, 'categoryChange');
  }

  function onFileIndexChange(val) {
    setSelectedFileIndexItem((selectedFileIndexItem = val));
    if (selectedFileIndexItem) {
      setReportIndexList((reportIndexList = []));
      setSelectedReportIndexItem((selectedReportIndexItem = null));
      setisReportSelected((isReportSelected = true));
      setSelectedCategoryIndexItem(
        (selectedCategoryIndexItem = { value: 'ALL', label: 'ALL', key: 'ALL' })
      );

      let subFolderName = val.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;
      //let sortOrder = selectedSortParam.value;

      //props.updateIndexObject({label:val.label,value:val.value,key:val.Key});
      setSelectedJsonSortParam((selectedJsonSortParam = 'asc'));
      getUploadedFileList(subFolderName, 'asc', 'fileIndexChange');
    } else {
      setReportIndexList((reportIndexList = []));
      setSelectedReportIndexItem((selectedReportIndexItem = null));
    }
  }

  function sourceFile() {
    if (selectedFileIndexItem != null) {
      setZipLink((zipLink = selectedFileIndexItem.zip_link));
      setFileLink((fileLink = selectedFileIndexItem.file_link));
      setShowDownloadPopup((showDownloadPopup = true));
    }
  }

  function onReportIndexChange(val) {
    setSelectedReportIndexItem((selectedReportIndexItem = val));
    if (val.type === 'settings') {
      props.updateReportObject({
        label: val.label,
        value: val.value,
        key: val.key,
      });
      getFileSettings(val);
    } else if (val.type === 'delete') {
      closeReportPopup();
    } else {
      props.updateReportObject({
        label: val.label,
        value: val.value,
        key: val.value,
      });
      props.onReportModalCloseAfterApi(
        selectedFileIndexItem,
        selectedReportIndexItem
      );
      closeReportPopup();
    }
  }

  function onSortParamChange(val) {
    setSelectedSortParam((selectedSortParam = val));
    setCategoryIndexList((categoryIndexList = []));
    let directoryName = '';
    let sortOrder = val;

    getUploadedFileList(directoryName, sortOrder, 'indexsorting');
  }

  function onCategorySortParamChange(val) {
    setSelectedCategorySortParam((selectedCategorySortParam = val));
    if (selectedCategorySortParam != null && selectedFileIndexItem != null) {
      let subFolderName = selectedFileIndexItem.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;
      let sortOrder = selectedJsonSortParam;

      getUploadedFileList(subFolderName, sortOrder, 'categorysorting');
    }
  }

  function onJsonSortParamChange(val) {
    setSelectedJsonSortParam((selectedJsonSortParam = val));

    if (selectedFileIndexItem != null && selectedFileIndexItem != null) {
      let subFolderName = selectedFileIndexItem.Key;
      let lastIndex = subFolderName.lastIndexOf('/');
      let totalLength = subFolderName.length;
      let subFolderName_modified = subFolderName.substr(
        lastIndex + 1,
        totalLength
      );
      let directoryName = subFolderName_modified;
      let sortOrder = val;
      getUploadedFileList(subFolderName, sortOrder, 'reportsorting');
    }
  }

  async function getUploadedFileList(directoryName, sortOrder, operation) {
    setLoading(true);

    axios
      .post(API_SERVER + `s3-zip-file-read-with-category-list`, {
        userEmail: userEmail,
        directoryName: directoryName,
        sortOrder: sortOrder,
        categorySortOrder: selectedCategorySortParam,
        filterBy: selectedCategoryIndexItem,
      })
      .then(function (response) {
        if (response.status === 200) {
          if (operation === 'onRefocus') {
            setReportIndexList((reportIndexList = []));
            setReportIndexList(
              (reportIndexList = response.data.data.report_list_data)
            );
            setCategoryIndexList(
              (categoryIndexList = response.data.data.category_list_data)
            );
          } else if (operation === 'categorysorting') {
            setSelectedReportIndexItem((selectedReportIndexItem = null));
            setReportIndexList((reportIndexList = []));
            setReportIndexList(
              (reportIndexList = response.data.data.report_list_data)
            );
          } else {
            if (directoryName === '') {
              setFileIndexList((fileIndexList = []));
              setReportIndexList((reportIndexList = []));
              setisReportSelected((isReportSelected = false));
              setSelectedFileIndexItem((selectedFileIndexItem = null));
              setSelectedReportIndexItem((selectedReportIndexItem = null));
              setFileIndexList((fileIndexList = response.data.data));
            } else {
              setReportIndexList((reportIndexList = []));
              setReportIndexList(
                (reportIndexList = response.data.data.report_list_data)
              );
              setCategoryIndexList(
                (categoryIndexList = response.data.data.category_list_data)
              );
              setSelectedReportIndexItem((selectedReportIndexItem = null));
            }
          }
        } else {
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error',
          message: 'Error',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
  }

  async function getFileSettings(val) {
    setLoading(true);

    axios
      .post(API_SERVER + `s3-get-chart-setting-file`, {
        userEmail: userEmail,
        indexObject: props.indexObject,
        reportObject: val,
      })
      .then(function (response) {
        if (response.status === 200) {
          store.addNotification({
            title: 'Success',
            message: 'Please wait while configure selected settings',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
          let loadedSettings = response.data;
          //props.onReportModalCloseAfterApi(selectedFileIndexItem,val,loadedSettings);
          closeReportPopup();
        } else {
          closeReportPopup();
          store.addNotification({
            title: 'Error',
            message: 'Error',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'zoomIn'],
            animationOut: ['animated', 'zoomOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true,
            },
          });
        }
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error',
          message: 'Error',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'zoomIn'],
          animationOut: ['animated', 'zoomOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(function () {
        setLoading(false);
      });
  }

  function checkBackground(param) {
    switch (param) {
      case 'settings':
        return '#CAE4FF';
      case 'deleted':
        return '#ffcaca';
      default:
        return 'undefined';
    }
  }

  return (
    <React.Fragment>
      <Popup open={showReportPopup} onClose={closeReportPopup} nested>
        <div>
          <div className='modal is-active custom-model-popup'>
            <div className='modal-card'>
              <header className='modal-card-head'>
                <p className='modal-card-title'>
                  {t('Modal.SelectDefaultSourceFileandReport')}
                </p>
                <button
                  className='deleteBtn'
                  onClick={props.closeReportPopup}
                  aria-label='close'
                >
                  <i className='fa fa-times-circle'></i>
                </button>
              </header>
              <section className='modal-card-body'>
                <div className='container'>
                  <div className='upl-file-row'>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        <div className='styled-select arrows'>
                          <Select
                            name='FILE'
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={
                              fileIndexList.length > 0
                                ? fileIndexList
                                : props.reportPopUpData
                            }
                            onChange={onFileIndexChange}
                            value={selectedFileIndexItem}
                            isClearable={false}
                            placeholder={t('Select.sourceFile')}
                            isMulti={false}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                          />
                        </div>
                        <div
                          data-tip
                          data-for='asc'
                          className={
                            selectedSortParam === 'asc'
                              ? 'sort_asse text-allign-r active-filter'
                              : 'sort_asse text-allign-r'
                          }
                          onClick={(e) => onSortParamChange('asc')}
                        >
                          <i className='fa fa-angle-up'></i>
                        </div>
                        <div
                          data-tip
                          data-for='desc'
                          className={
                            selectedSortParam === 'desc'
                              ? 'sort_desc text-allign-r active-filter'
                              : 'sort_desc text-allign-r'
                          }
                          onClick={(e) => onSortParamChange('desc')}
                        >
                          <i className='fa fa-angle-down'></i>
                        </div>
                      </div>
                      <div className='col-md-1 col-lg-1 text-allign-r'>
                        {/*<button type="button" className="btn download-btn" onClick={sourceFile}>
                                  <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                                </button> */}
                      </div>
                    </div>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        <div className='styled-select arrows'>
                          <Select
                            name='FILE'
                            isDisabled={!isReportSelected}
                            closeMenuOnSelect={true}
                            options={categoryIndexList}
                            onChange={onCategoryIndexChange}
                            value={selectedCategoryIndexItem}
                            isClearable={false}
                            placeholder={t('Select.reportCategory')}
                            isMulti={false}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                          />
                        </div>
                        <div
                          data-tip
                          data-for='asc'
                          className={
                            selectedCategorySortParam === 'asc'
                              ? 'sort_asse text-allign-r active-filter'
                              : 'sort_asse text-allign-r'
                          }
                          onClick={(e) => onCategorySortParamChange('asc')}
                        >
                          <i className='fa fa-angle-up'></i>
                        </div>
                        <div
                          data-tip
                          data-for='desc'
                          className={
                            selectedCategorySortParam === 'desc'
                              ? 'sort_desc text-allign-r active-filter'
                              : 'sort_desc text-allign-r'
                          }
                          onClick={(e) => onCategorySortParamChange('desc')}
                        >
                          <i className='fa fa-angle-down'></i>
                        </div>
                      </div>

                      <div className='col-md-1 col-lg-1'></div>
                    </div>
                    <div className='row col-sm-12 col-md-12 col-lg-12 p-0'>
                      <div className='col-md-11 col-lg-11 pl-0 custom-filter'>
                        {selectedReportIndexItem === null ? (
                          <ReactTooltip
                            id='selectReportTip'
                            place='bottom'
                            disable={props.toolTipObject.toolTip}
                            delayHide={100}
                            effect='solid'
                          >
                            {t('Select.selectValueIndropDown')}
                          </ReactTooltip>
                        ) : null}
                        <div
                          className='styled-select arrows'
                          data-tip
                          data-for='selectReportTip'
                        >
                          <Select
                            name='FILE'
                            isDisabled={!isReportSelected}
                            closeMenuOnSelect={true}
                            options={reportIndexList}
                            onChange={onReportIndexChange}
                            value={selectedReportIndexItem}
                            isClearable={false}
                            placeholder={t('Select.report')}
                            isMulti={false}
                            isSearchable={true}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: '#CADBFF',
                                primary: '#CADBFF',
                              },
                            })}
                            styles={{
                              option: (styles, { data }) => {
                                return {
                                  ...styles,
                                  backgroundColor: checkBackground(data.type),
                                };
                              },
                            }}
                          />
                        </div>
                        <div
                          data-tip
                          data-for='asc'
                          className={
                            selectedJsonSortParam === 'asc'
                              ? 'sort_asse text-allign-r active-filter'
                              : 'sort_asse text-allign-r'
                          }
                          onClick={(e) => onJsonSortParamChange('asc')}
                        >
                          <i className='fa fa-angle-up'></i>
                        </div>
                        <div
                          data-tip
                          data-for='desc'
                          className={
                            selectedJsonSortParam === 'desc'
                              ? 'sort_desc text-allign-r active-filter'
                              : 'sort_desc text-allign-r'
                          }
                          onClick={(e) => onJsonSortParamChange('desc')}
                        >
                          <i className='fa fa-angle-down'></i>
                        </div>
                      </div>

                      <div className='col-md-1 col-lg-1'>
                        {/*<button type="button" className="btn download-btn" onClick={downloadFile}>
                                <svg id="bold" className="downloadicon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z"/><path d="m8.75 10.25h2.25v-4.25c0-.552.447-1 1-1s1 .448 1 1v4.25h2.25c.669 0 1.002.81.53 1.28l-3.25 3.25c-.146.147-.338.22-.53.22s-.384-.073-.53-.22l-3.25-3.25c-.472-.47-.139-1.28.53-1.28z"/><path d="m17 19h-10c-.553 0-1-.448-1-1s.447-1 1-1h10c.553 0 1 .448 1 1s-.447 1-1 1z"/></svg>
                              </button>*/}
                      </div>
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-12 p-0 reports-dtl'>
                      <div className='d-block  p-2 user-save'>
                        {t('Select.userSavedreports')}
                      </div>
                      <div className='d-block  p-2 default-report'>
                        {t('Select.defaultReports')}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <ReactTooltip
            id='asc'
            place='top'
            disable={props.toolTipObject.toolTip}
            delayHide={100}
            effect='float'
          >
            {t('Select.asc')}
          </ReactTooltip>

          <ReactTooltip
            id='desc'
            place='bottom'
            disable={props.toolTipObject.toolTip}
            delayHide={100}
            effect='float'
          >
            {t('Select.desc')}
          </ReactTooltip>

          {Loading && <Loader />}
        </div>
      </Popup>
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;

  return { indexObject, reportObject, languageObject, toolTipObject };
};

export default compose(
  connect(mapStateToProps, {
    updateReportObject,
    updateIndexObject,
  })
)(ReportModal);
