import { UPDATE_DASHBOARD_CHART_SETTINGS , RESET_DASHBOARD_CHART_SETTINGS } from "../../actions/DashboardAction/ChartSettingsAction";

export const DASHBOARD_CHART_SETTINGS_INITIAL_STATE = {
  chartSettingsObject: {
    settingConfig_default:'',
    settingConfig_first:'',
    settingConfig_second:'',
    settingConfig_third:'',
    position_first:false,
    position_second:false,
    position_third:false
  }
};

export default (state = DASHBOARD_CHART_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_CHART_SETTINGS:
      return {
        ...state,
        chartSettingsObject: Object.assign({}, state.chartSettingsObject, action.payload)
      };
      case RESET_DASHBOARD_CHART_SETTINGS:
        return DASHBOARD_CHART_SETTINGS_INITIAL_STATE
    default:
      return state;
  }
};
