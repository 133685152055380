import React, { useState }  from "react";
import Popup from "reactjs-popup";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";

import { compose } from "recompose";
import "./modal.css";

import { updateEChartSettingObject,resetEChartSettingObject } from "../../actions/EChartAction/EChartSettingsAction";

function DeleteAlertModal(props) {

     let { showDeleteAlertPopup, closeDeleteAlertPopup , chartHeader } = props;
      async function handleSubmit(e)
      {
         e.preventDefault();
        props.onDeleteAlertPopupCloseAfterApi(e.target.value);
        closeDeleteAlertPopup();
      };


  return (
   <React.Fragment>
    <Popup open={showDeleteAlertPopup} onClose={closeDeleteAlertPopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Alert</p>
              <button className="deleteBtn" onClick={props.closeDeleteAlertPopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body popup-det-alrt">
              <div className="container">
                  <div className="row col-sm-12">
                      <div className="col-md-12 col-lg-12">
                        <h4>Are you sure want to delete {props.chartSettingsObject.fileLabel} ?</h4>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 Alert-btn">
                          <button type="button"  value ="true" className="btn btn-danger" onClick={(e) => handleSubmit(e)} >Yes</button>
                          <button type="button"  value ="false" className="btn btn-secondary" onClick={(e) => handleSubmit(e)} >No</button>
                    </div>
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}



const mapStateToProps = reducerObj => {
  const chartSettingsObject  = reducerObj.eChartSettingsReducers.chartSettingsObject;
  const indexObject = reducerObj.indexReducers.indexObject;
  const reportObject = reducerObj.reportReducers.reportObject;
  return { chartSettingsObject,indexObject,reportObject };
};

export default compose(
  connect(mapStateToProps, {
    updateEChartSettingObject,resetEChartSettingObject
  }),
)(DeleteAlertModal);
