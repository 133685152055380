import React, { useState }  from "react";
import Popup from "reactjs-popup";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";
import {useTranslation} from "react-i18next";
import { compose } from "recompose";
import "./modal.css";
import { wrap } from "lodash";


export default function ErrorMessageModal(props) {
  const {t, i18n} = useTranslation('common');
     let { showErrorMessagedPopup, closeErrorMessagePopup,selectedErrorFile,selectedErrorLabel,fileLink  } = props;
     console.log("selectedErrorFile",selectedErrorFile)
     const [active , setActive] = useState(false)
     
    // const customString = selectedErrorFile.toString().replaceAll('Row', "&nbsp;")
    
     async function handleSubmitOK(e)
      {
         e.preventDefault();

        closeErrorMessagePopup();
      };


      async function handleSubmitWrap(e)
      {
         e.preventDefault();
         const currentState = active
         setActive(!currentState);
       //  setTextWidth("1900px")
      };
      async function handleSubmit(e)
      {
         e.preventDefault();
        //  if(downloadType === "ZIP"){
        //      window.open(zipLink, "_blank")
        //  }else{
        //      window.open(fileLink, "_blank")
        //  }
        window.open(fileLink, "_blank")
      //  closeDownloadPopup();
      };
      // const errorFileContent = selectedErrorFile.map((item) => {
      //   return(
      //   <div className="video-column">{item}</div>
      //   )
      //   })

    //  const errorFileContent =  selectedErrorFile.split('\n').map(str => <p>{str}</p>);

  return (
   <React.Fragment>
    <Popup open={showErrorMessagedPopup} onClose={closeErrorMessagePopup}>
       <div className="modal is-active">
           <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Error: {selectedErrorLabel}</p>
              <button className="deleteBtn" onClick={props.closeErrorMessagePopup} aria-label="close"><i className="fa fa-times-circle"></i></button>
            </header>
            <section className="modal-card-body">
              <div className="container">
                  <div className="row col-sm-12">
                      <div className="col-md-12 col-lg-12">
                        <h4><text id="error-content">{selectedErrorFile.toString().split('\n').map(str => <p style={{width : active ? "1900px": "100%",wordWrap:"break-word"}}>{str}</p>)}</text></h4>
                      </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-12 col-lg-12 Alert-btn">
                          <button type="button"  value ="true" className="btn btn-danger" onClick={(e) => handleSubmit(e)} >OK</button>
                          <button type="button"  value ="true" className="btn" onClick={(e) => handleSubmit(e)} >Wrap/Un-Wrap Text</button>
                          <button type="button"  value ="true" className="btn" onClick={(e) => handleSubmit(e)} >Download</button>
                    </div> */}
                  </div>
              </div>
            </section>
            <footer className="modal-card-foot">
                
                    <button type="button"  value ="true" className="btn yellow-btn" onClick={(e) => handleSubmitOK(e)} >OK</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button"  value ="true" className="btn yellow-btn" onClick={(e) => handleSubmitWrap(e)} >Wrap/Un-Wrap Text</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button"  className="btn yellow-btn" onClick={(e) => handleSubmit(e)}>  {t('Button.download')}</button>
                
            </footer>
          </div>
      </div>
    </Popup>
  </React.Fragment>
  );
}

