import { UPDATE_TAB , RESET_TAB } from "../actions/TabAction";

export const TAB_INITIAL_STATE = {
  tabObject: {
    tabIndex:0,
  }
};

export default (state = TAB_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TAB:
      return {
        ...state,
        tabObject: Object.assign({}, state.tabObject, action.payload)
      };
      case RESET_TAB:
        return TAB_INITIAL_STATE
    default:
      return state;
  }
};
