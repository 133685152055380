import { UPDATE_ECHART_SETTINGS , RESET_ECHART_SETTINGS } from "../../actions/EChartAction/EChartSettingsAction";

export const ECHART_SETTINGS_INITIAL_STATE = {
  chartSettingsObject: {
    settingApplied:false,
    settingConfig:'',
    fileName:'',
    fileLabel:''
  }
};

export default (state = ECHART_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ECHART_SETTINGS:
      return {
        ...state,
        chartSettingsObject: Object.assign({}, state.chartSettingsObject, action.payload)
      };
      case RESET_ECHART_SETTINGS:
        return ECHART_SETTINGS_INITIAL_STATE
    default:
      return state;
  }
};
