import { UPDATE_CHART_SETTINGS , RESET_CHART_SETTINGS } from "../../actions/ChartAction/ChartSettingsAction";

export const CHART_SETTINGS_INITIAL_STATE = {
  chartSettingsObject: {
    settingApplied:false,
    settingConfig:'',
    fileName:'',
    fileLabel:''
  }
};

export default (state = CHART_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CHART_SETTINGS:
      return {
        ...state,
        chartSettingsObject: Object.assign({}, state.chartSettingsObject, action.payload)
      };
      case RESET_CHART_SETTINGS:
        return CHART_SETTINGS_INITIAL_STATE
    default:
      return state;
  }
};
