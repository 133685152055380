export const UPDATE_ECHART_TYPE = "update-echart-type";
export const RESET_ECHART_TYPE = "reset-echart-type";

export const updateEChartTypeObject = (data, callBack) => {
  return dispatch => {
    dispatch(updateObject(data));
    callBack && callBack();
  };
};

export const resetEChartTypeObject = callBack => {
  return dispatch => {
    dispatch(resetObject());
    callBack && callBack();
  };
};

export const updateObject = data => {
  return {
    type: UPDATE_ECHART_TYPE,
    payload: data
  };
};

export const resetObject = () => {
  return {
    type: RESET_ECHART_TYPE
  };
};
